export default {
  YELLOW: '#fced1c',
  ORANGE: '#f59d1d',
  RED: '#be202d',
  LIGHT_GREEN: '#d2db43',
  GREEN: '#b9bc34',
  DARK_GREEN: '#60843d',
  LIGHT_BLUE: '#88c9df',
  BLUE: '#0e71b9',
  DARK_BLUE: '#04597c',
  PINK: '#eb098b',
  PURPLE: '#25AABA',
  BROWN: '#894a37',
  LIGHT_SKIN: '#fec999',
  SKIN: '#c39a6b',
  DARK_SKIN: '#684b26',
  WHITE: '#ffffff',
  GRAY: '#bcbdbf',
  BLACK: '#000000',
};
