<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>

  <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_48" data-name="Layer 48">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <path class="cls-2" d="M146,198.18H104.88a5,5,0,0,1-4.79-5.23V99.52a5.42,5.42,0,0,1,1.79-4.08,53.53,53.53,0,0,0,8.62-9l3.2-4.49h25l.88,1.49a47.72,47.72,0,0,0,9.38,12,5.42,5.42,0,0,1,1.79,4.08V193A5,5,0,0,1,146,198.18Z" />
          <path
              class="cls-3"
              d="M139.36,65.15V61.71c0-8.32-6.07-15.09-13.53-15.09s-13.54,6.77-13.54,15.09v3.44A4.69,4.69,0,0,0,108.44,70a4.64,4.64,0,0,0,4.37,4.87h26A4.64,4.64,0,0,0,143.21,70,4.68,4.68,0,0,0,139.36,65.15Zm-13.53-4.43a4.07,4.07,0,1,1,4.07-4.07A4.07,4.07,0,0,1,125.83,60.72Z"
          />
      </g>
  </svg>

  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="3024" height="4032" transform="translate(9.72 -14.24) scale(0.08)" xlink:href="/garrafa.jpeg" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>

</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped>
  svg {
    height: auto;
  }
  .cls-1,
  .cls-2 {
      fill: #51a4b0;
      stroke: #fff;
      stroke-miterlimit: 10;
  }
  .cls-1 {
      stroke-width: 11.34px;
  }
  .cls-2 {
      stroke-linecap: round;
      stroke-width: 5px;
  }
  .cls-2,
  .cls-3 {
      opacity: 0.5;
  }
  .cls-3 {
      fill: #fff;
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
