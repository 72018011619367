<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="o-Home__welcome">
        <div class="o-Home__text">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <h5>MORADA</h5>

                <p>
                  Infraquinta, E.M.
                  <br>
                  Avenida da Gondra, nº68
                  <br>
                  Apartado 2129
                  <br>
                  Quinta do Lago
                  <br>
                  8135-024 Almancil - Portugal
                </p>
              </div>
              <div class="col-xs-12 col-md-6">
                <h5>CONTACTOS</h5>

                <p>
                  Telefone: <a href="tel:+351289390030">+351 289 390 030</a>
                  <br>
                  Fax: +351 289 390 038
                  <br>
                  Email: <a href="mailto:info@infraquinta.pt">info@infraquinta.pt</a>
                  <br>
                  <br>
                  Horário de funcionamento:
                  <br>
                  Dias úteis:
                  9h30 às 13h e das 14h às 17h30
                </p>
              </div>
              <div class="col-xs-12">
                <div class="map-responsive">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50948.45124122823!2d-8.004113356596886!3d37.05085302840021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd054cce2f8eafcd%3A0x582428c2130111fd!2sInfraQuinta!5e0!3m2!1sen!2spt!4v1631125779345!5m2!1sen!2spt" width="400" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import HomeHeader from '@/components/HomeHeader.vue';
import Links from '@/components/Links.vue';
import HomeBackground from '@/components/svg/HomeBackground.vue';

export default {
  name: 'Contact',
  components: {
    HomeHeader,
    HomeBackground,
    Links,
  },
};
</script>

<style lang="scss" scoped>
.o-Link_wrapper {
  position: absolute;
  bottom: 0;
  left: 1em;

  @media only screen and (min-width: 767px){
    display: none;
  }
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 2em;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.o-Home {
  &__welcome {
    background: #FFF;
    border-radius: 16px;
    overflow: hidden;

    @media only screen and (max-width: 767px){
      margin-top: 2em;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2em;

    @media only screen and (max-width: 767px){
      padding: 2em;
      height: auto;
      width: auto;
    }
  }
  &__image {
    background: url('~@/assets/images/children.jpeg');
    height: 450px;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }
  // Menu
  &__menu {
    padding: 0;
  }
  &__menuEntry {
    list-style-type: none;

    img {
      height: 2em;
    }
  }

  &__menuLink {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 1.2em;
    padding: 0.5em;
    border-radius: 16px;

    &:hover {
      background: #EEE;
    }
  }

  &__menuLabel {
    margin-left: 0.5em;
  }

  // Characters
  &__characters {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 1em;
    margin-left: -3em;
    width: 450px;

    @media only screen and (max-width: 767px){
      bottom: initial;
      width: 100%;
      margin: 0;
      left: 0;
    }
  }

  &__character {
    &.left {
      margin-right: -80px;
    }
  }

  // Background
  &__overlay{
    position: fixed;
    top: 0;
    left: 0;
    background: #66b7b5;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  &__background {
    position: fixed;
    left: 0;
    bottom: -4px;
    width: 100%;
    z-index: -1;
  }
}
</style>
