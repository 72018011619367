<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>
  <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_48" data-name="Layer 48">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <g class="cls-2">
              <rect class="cls-3" x="66.04" y="94.04" width="90.93" height="50.71" rx="12.34" />
              <circle class="cls-3" cx="95.04" cy="72.47" r="13.42" />
              <circle class="cls-3" cx="131.52" cy="72.47" r="13.42" />
              <polyline class="cls-4" points="76.9 187.56 110.44 146.54 116.15 146.43 150.79 189.64" />
              <path class="cls-4" d="M157,113.9l31.69-15.68a3.56,3.56,0,0,1,5,3.4v34.75a3.55,3.55,0,0,1-5.13,3.35L157,122.8Z" />
          </g>
      </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="600" height="600" transform="translate(0.44 0.44) scale(0.42)" xlink:href="/video.gif" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>
</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped>
  svg {
    height: auto;
  }
  .cls-1 {
      fill: #51a4b0;
      stroke-width: 11.34px;
  }
  .cls-1,
  .cls-3,
  .cls-4 {
      stroke: #fff;
      stroke-miterlimit: 10;
  }
  .cls-2 {
      opacity: 0.5;
  }
  .cls-3,
  .cls-4 {
      fill: none;
      stroke-width: 5px;
  }
  .cls-4 {
      stroke-linecap: round;
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
