<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>
  <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_48" data-name="Layer 48">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <path
              class="cls-2"
              d="M194.46,80.38h0a.43.43,0,0,0,0-.05,2.63,2.63,0,0,0,0-.49.75.75,0,0,1-.05-.16c0-.12-.08-.23-.13-.35l-.09-.16a1.53,1.53,0,0,0-.19-.3l-.12-.15a3.83,3.83,0,0,0-.29-.28l-.08-.07h0a2.43,2.43,0,0,0-.47-.28l-.09,0a2.12,2.12,0,0,0-.38-.13L124.1,63.23,56.52,81.76a2.45,2.45,0,0,0-2.61,2.29h0a1.13,1.13,0,0,0,0,.18v84.44a2.5,2.5,0,0,0,1.77,2.39l67.79,20.71h.08a2.76,2.76,0,0,0,.65.09,2.49,2.49,0,0,0,.47,0l.18-.05.2,0,67.79-24.59a2.5,2.5,0,0,0,1.65-2.35V80.39Zm-70.22-12L182,80.78,124.1,98.29,66.6,84.18ZM58.88,87.43l62.79,15.42V186L58.88,166.83Zm130.58,75.62-62.79,22.77V102.74l62.79-19Z"
          />
          <ellipse class="cls-2" cx="145.52" cy="153.67" rx="5.48" ry="8.35" />
          <ellipse class="cls-2" cx="159.44" cy="133.71" rx="5.48" ry="8.35" />
          <ellipse class="cls-2" cx="171.72" cy="113.47" rx="5.48" ry="8.35" />
          <ellipse class="cls-2" cx="79.95" cy="122.54" rx="5.48" ry="8.35" />
          <ellipse class="cls-2" cx="125.8" cy="81.63" rx="5.48" ry="8.35" transform="translate(39.74 204.45) rotate(-87.96)" />
          <ellipse class="cls-2" cx="98.04" cy="151.15" rx="5.48" ry="8.35" />
      </g>
  </svg>

  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="5472" height="3748" transform="translate(-190 -100.23) scale(0.12)" xlink:href="/jogo_xl.jpeg" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>
</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped>
  svg {
    height: auto;
  }
  .cls-1 {
      fill: #51a4b0;
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
  .cls-2 {
      fill: #fff;
      opacity: 0.5;
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
