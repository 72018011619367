<template>
  <div class="o-About__welcome">
    <div class="row">
      <div class="col-xs-12">
        <div class="o-About__whiteBg">
          <div class="row">
            <div class="col-xs-12">
              <h4 class="highlight">
                MATERIAIS DIDÁTICOS
              </h4>
              <p>
                Com o objetivo de incentivar e envolver o público juvenil de forma mais dinâmica e interativa nesta missão da sustentabilidade da Terra, foram concebidas várias ferramentas didáticas para uso nas nossas ações de divulgação deste projeto:
                <br>
                <br>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item">
                <Mascots />
                <h4 class="highlight">Mascotes</h4>
                <p>
                  <span class="highlight">EDU</span> e <span class="highlight">ÁREA</span>, criam uma relação mais próxima ao público e personificam o papel de agentes <span class="highlight">edu</span>cadores dedicados aos temas da <span class="highlight">á</span>gua, <span class="highlight">r</span>esíduos, <span class="highlight">e</span>nergia e <span class="highlight">a</span>mbiente.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item">
                <Jogo />
                <h4 class="highlight">Jogo de tabuleiro formato XL</h4>
                <p>
                  De carácter itinerante, um desafio em forma de Quiz que coloca à prova os conhecimentos dos alunos sobre os temas da água, resíduos, energia e ambiente. Para além de testar conhecimentos serve para lançar temas para discussão e debate entre os participantes.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item">
                <JogoMini />
                <h4 class="highlight">Jogo de tabuleiro formato mini</h4>
                <p>
                  É uma réplica do Jogo de tabuleiro formato XL pensado para ser transportado individualmente por cada aluno para fora do contexto escolar, para o lar ou outros ambientes que possam proporcionar momentos de partilha de conhecimento de uma forma lúdica.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item">
                <Garrafa />
                <h4 class="highlight">Garrafa de água reutilizável</h4>
                <p>
                  Alterar hábitos de consumo são opções que globalmente terão resultados na redução do uso do plástico.
                  <br>
                  <br>
                  Incentivamos a troca do uso da garrafa de plástico por uma garrafa reutilizável e promovemos o consumo de água da torneira.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <br>
              <div class="o-About__item">
                <Atividades />
                <h4 class="highlight">Caderno de atividades</h4>
                <p>
                  Impresso em papel reciclado, contém exercícios divertidos e interessantes que estimulam a atenção e a assimilação de conhecimentos de temática ambiental e adoção de comportamentos importantes no dia-a-dia.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <br>
              <div class="o-About__item">
                <Toalhete />
                <h4 class="highlight">Toalhete para refeições</h4>
                <p>
                  Meio de comunicação para a divulgação do projeto Infraqidz para distribuição nos locais de restauração local, pretende cativar e envolver o público infantojuvenil na temática da sustentabilidade da Terra, com algumas atividades práticas.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <br>
              <div class="o-About__item">
                <Video />
                <h4 class="highlight">Vídeos didáticos</h4>
                <p>
                  4 animações temáticas com dicas para uma missão conjunta <b>Cuidar do nosso ambiente</b>, disponíveis para visualização nas redes sociais e na página de internet.
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <br>
              <div class="o-About__item">
                <Site />
                <h4 class="highlight">Página de internet</h4>
                <p>
                  Para que a nossa mensagem alcance um maior número de aderentes a esta missão, estamos presentes na maior rede humana mundial ligada digitalmente. Informação e atividades <span class="highlight">Infraqidz</span> acessíveis num clique!
                </p>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-xs-12">
              <br><br>
              <h4 class="highlight">
                AÇÕES DE DIVULGAÇÃO NA COMUNIDADE ESCOLAR:
              </h4>

              <p>
                <span class="highlight">Infraquinta Open Day</span>
                <br>
                Esta ação consiste em receber grupos escolares do município de Loulé, acompanhados pelos professores. Proporcionamos experiências com base no contacto com a realidade, para uma melhor compreensão de como a água chega às torneiras, do que acontece aos resíduos depositados nos ecopontos, do uso da energia verde e da importância de cuidar do nosso ambiente.
                Brindamos os nossos visitantes com um momento de brincadeira com o jogo de tabuleiro XL, colocando à prova os seus conhecimentos na área das boas práticas da gestão da água, resíduos, energia e ambiente.
              </p>
              <p>
                <span class="highlight">Infraqidz vai à escola</span>
                <br>
                Em ambiente escolar, temos uma atividade com o jogo de tabuleiro XL, que coloca à prova os conhecimentos dos alunos sobre os temas da água, resíduos, energia e ambiente.
                <br>
                <br>
                Se é professor e está interessado em participar no <span class="highlight">Infraquinta Open Day</span> ou requisitar uma atividade <span class="highlight">Infraqidz vai à escola</span>, entre em contacto através do email <a href="mailto:info@infraquinta.pt" class="highlight">info@infraquinta.pt</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Mascots from '@/components/svg/materiais/Mascots.vue';
import Jogo from '@/components/svg/materiais/Jogo.vue';
import JogoMini from '@/components/svg/materiais/JogoMini.vue';
import Garrafa from '@/components/svg/materiais/Garrafa.vue';
import Atividades from '@/components/svg/materiais/Atividades.vue';
import Toalhete from '@/components/svg/materiais/Toalhete.vue';
import Video from '@/components/svg/materiais/Video.vue';
import Site from '@/components/svg/materiais/Site.vue';

export default {
  name: 'Materiais',
  components: {
    Mascots,
    Jogo,
    JogoMini,
    Garrafa,
    Atividades,
    Toalhete,
    Video,
    Site,
  },
};
</script>

<style lang="scss" scoped>
.o-About {
  &__welcome {
    margin-top: 1em;

    @media only screen and (max-width: 767px){
      margin-top: 2em;
    }
  }
  &__whiteBg {
    background: #FFF;
    padding: 1em 1em 0;
    border-radius: 16px;
    margin-bottom: 2em;
    height: 100%;
  }

  &__item {
    height: 100%;
    padding: 0 1em ;
    background-color: #E1F0F4;

    svg {
      margin-top: 1em;
    }

  }

}
</style>
