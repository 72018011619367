<template>
  <div class="o-About__welcome">
    <div class="row">
      <div class="col-xs-12">
        <div class="o-About__whiteBg">
          <p>
            <span class="highlight">Infraqidz</span>, pretende envolver grupos etários mais jovens em atividades lúdico-pedagógicas, no âmbito do programa de educação ambiental da empresa Infraquinta, E.M.. Este projeto visa promover a valorização da adoção de comportamentos no dia-a-dia, focados em 4 temas assentes numa maior sustentabilidade do planeta, que poderão fazer a diferença no futuro de todos.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="o-About__whiteBg">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item aqua">
                <router-link
                  :to="{ name: 'Area', params: { section: 'aqua' }}"
                >
                  <Aqua />
                </router-link>
                <h4>Poupa água</h4>
                <p><br> A água é um recurso natural, precioso e requer uma gestão eficiente. 70% da terra é água, mas apenas 1% desta pode ser consumida pelo ser humano. Para evitarmos um futuro sem água, está nas nossas mãos garantir a sua sustentabilidade.</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item recicle">
                <router-link
                  :to="{ name: 'Area', params: { section: 'recicle' }}"
                >
                  <Recicle />
                </router-link>
                <h4>Recicla, fazendo bem a separação</h4>
                <p>Se individualmente adotarmos bons comportamentos de consumo, globalmente faremos uma grande diferença na redução da produção de resíduos. No tratamento dos resíduos é mandatário fazer uma separação seletiva e depositar os objetos em fim de vida nos ecopontos corretos.</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item energy">
                <router-link
                  :to="{ name: 'Area', params: { section: 'energy' }}"
                >
                  <Energy />
                </router-link>
                <h4>Usa energia verde sem limites</h4>
                <p>Energia verde ou energia renovável é gerada por recursos inesgotáveis e sustentáveis como o sol, vento, marés ou pelo calor da Terra, que se renovam de forma natural e regular. É uma opção que só tem benefícios, não se esgota e não liberta poluentes para a atmosfera de forma a combater e contrariar as mudanças climáticas para garantir o nosso futuro.</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="o-About__item nature">
                <router-link
                  :to="{ name: 'Area', params: { section: 'nature' }}"
                >
                  <Nature />
                </router-link>
                <h4>Preserva e cuida bem da natureza</h4>
                <p>Para evitar desequilíbrios ambientais irreversíveis, é essencial combater a poluição, a degradação das florestas, a extinção de animais e o aquecimento global. Cuidar do nosso meio ambiente está nas mãos de todos, por isso, protegê-lo é crucial para a sua preservação e conservação.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Aqua from '@/components/svg/Aqua.vue';
import Recicle from '@/components/svg/Recicle.vue';
import Energy from '@/components/svg/Energy.vue';
import Nature from '@/components/svg/Nature.vue';

export default {
  name: 'About',
  components: {
    Aqua,
    Recicle,
    Energy,
    Nature,
  },
};
</script>

<style lang="scss" scoped>
.o-About {
  &__welcome {
    margin-top: 1em;

    @media only screen and (max-width: 767px){
      margin-top: 2em;
    }
  }
  &__whiteBg {
    background: #FFF;
    padding: 1em;
    border-radius: 16px;
    margin-bottom: 2em;
  }

  &__item {
    height: 100%;
    padding: 0 1em ;

    svg {
      margin-top: 1em;
    }

    &.aqua {
      background-color: #E1F0F4;

      h4 {
        color: #88c9df;
      }
    }
    &.recicle {
      background-color: #F1F1D8;

      h4 {
        color: #d1db44;
      }
    }
    &.energy {
      background-color: #E2EDD9;

      h4 {
        color: #53a96d;
      }
    }
    &.nature {
      background-color: #EEF6E2;

      h4 {
        color: #a9d16b;
      }
    }
  }

}
</style>
