<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>

    <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_48" data-name="Layer 48">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <rect class="cls-2" x="78.44" y="59.05" width="98.24" height="130.58" rx="9.72" />
          <path class="cls-2" d="M97.74,111.83l11.06-5.26a3.43,3.43,0,0,1,4.55,1.59l22.41,45.74a3.38,3.38,0,0,1,.33,1.88L134.78,168a3.43,3.43,0,0,1-5.23,2.55l-11.31-7.06a3.43,3.43,0,0,1-1.31-1.49L96.09,116.36A3.44,3.44,0,0,1,97.74,111.83Z" />
          <line class="cls-3" x1="102.37" y1="122.18" x2="113.89" y2="116.69" />
          <polyline class="cls-4" points="90.47 194.65 90.47 208.98 100.33 205.14 109.64 208.98 109.64 194.65" />
      </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="640" height="856" transform="translate(-1.83 -141.32) scale(0.48)" xlink:href="/atividades.jpeg" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>
</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped>
  svg {
    height: auto;
  }
  .cls-1 {
      fill: #51a4b0;
      stroke-width: 11.34px;
  }
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4 {
      stroke: #fff;
      stroke-miterlimit: 10;
  }
  .cls-2,
  .cls-3 {
      fill: none;
  }
  .cls-2 {
      stroke-width: 5px;
  }
  .cls-2,
  .cls-3,
  .cls-4 {
      opacity: 0.5;
  }
  .cls-3,
  .cls-4 {
      stroke-width: 2.83px;
  }
  .cls-4 {
      fill: #fff;
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
