<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="row">
          <div class="col-xs-12 col-sm-3">
            <ul class="o-Section__menu">
              <li class="o-Section__menuEntry">
                <router-link
                  :to="{ name: 'Area', params: { section: 'aqua' }}"
                  v-tooltip="{
                    content: 'Água',
                    placement: 'left',
                  }"
                  class="o-Section__menuLink"
                >
                  <img :src="waterIcon" />
                </router-link>
              </li>
              <li class="o-Section__menuEntry">
                <router-link
                  :to="{ name: 'Area', params: { section: 'recicle' }}"
                  v-tooltip="{
                    content: 'Reciclagem',
                    placement: 'left',
                  }"
                  class="o-Section__menuLink"
                >
                  <img :src="recicleIcon" />
                </router-link>
              </li>
              <li class="o-Section__menuEntry">
                <router-link
                  :to="{ name: 'Area', params: { section: 'energy' }}"
                  v-tooltip="{
                    content: 'Energia',
                    placement: 'left',
                  }"
                  class="o-Section__menuLink"
                >
                  <img :src="energyIcon" />
                </router-link>
              </li>
              <li class="o-Section__menuEntry">
                <router-link
                  :to="{ name: 'Area', params: { section: 'nature' }}"
                  v-tooltip="{
                    content: 'Natureza',
                    placement: 'left',
                  }"
                  class="o-Section__menuLink"
                >
                  <img :src="natureIcon" />
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-9">
            <Aqua v-if="section === SECTIONS.AQUA"/>
            <Recicle v-if="section === SECTIONS.RECICLE"/>
            <Energy v-if="section === SECTIONS.ENERGY"/>
            <Nature v-if="section === SECTIONS.NATURE"/>
          </div>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-sm-6">
        <div class="o-Section__characters display-md">
          <div
            v-if="section === SECTIONS.AQUA || section === SECTIONS.ENERGY"
            class="o-Section__character"
          >
            <Boy />
          </div>
          <div
            v-if="section === SECTIONS.RECICLE || section === SECTIONS.NATURE"
            class="o-Section__character"
          >
            <Girl />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Links from '@/components/Links.vue';
import Aqua from '@/components/svg/menus/Aqua.vue';
import Recicle from '@/components/svg/menus/Recicle.vue';
import Energy from '@/components/svg/menus/Energy.vue';
import Nature from '@/components/svg/menus/Nature.vue';
import Boy from '@/components/svg/Boy.vue';
import Girl from '@/components/svg/Girl.vue';

const SECTIONS = {
  AQUA: 'aqua',
  RECICLE: 'recicle',
  ENERGY: 'energy',
  NATURE: 'nature',
};

export default {
  name: 'Home',
  components: {
    Aqua,
    Recicle,
    Energy,
    Nature,
    Boy,
    Girl,
    Links,
  },
  data: () => ({
    SECTIONS,
    // Menus
    aqua: require('../assets/svg/menus/aqua.svg'),
    energy: require('../assets/svg/menus/energy.svg'),
    recicle: require('../assets/svg/menus/recicle.svg'),
    nature: require('../assets/svg/menus/nature.svg'),
    // Icons
    waterIcon: require('../assets/svg/icons/aqua.svg'),
    energyIcon: require('../assets/svg/icons/energy.svg'),
    natureIcon: require('../assets/svg/icons/nature.svg'),
    recicleIcon: require('../assets/svg/icons/recicle.svg'),
  }),
  computed: {
    section() {
      return this.$route.params.section;
    }
  },
};
</script>

<style lang="scss" scoped>
.o-Link_wrapper {
  position: absolute;
  bottom: 0;
}

.o-Page {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &:after {
    position: absolute;
    display: block;
    top: 0;
    content: '';
    background-image: url('/home_pattern.png');
    background-repeat: repeat;
    background-size: 105px 105px;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  &__wrapper {
    margin: 0 auto;
    max-width: var(--max-width);

    @media only screen and (max-width: 767px){
      margin: 2em 0 0 0;
      padding: 0;
      max-width: initial;
    }
  }

  @media only screen and (max-width: 767px){
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow: auto;
  }
}

.o-Section {
  // Menu
  &__menu {
    padding: 0;

    @media only screen and (max-width: 767px){
      display: flex;
      justify-content: space-between;
    }
  }

  &__menuEntry {
    list-style-type: none;

    img {
      height: 4em;
    }
  }

  &__menuLink {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    border-radius: 50%;
    margin-bottom: 1em;
    border: 4px solid transparent;

    &:hover {
      border-color: #FFF;
    }
  }

  &__menuLabel {
    margin-left: 0.5em;
  }

  // Characters
  &__characters {
    margin-left: -3em;
    width: 450px;

    @media only screen and (max-width: 767px){
      bottom: initial;
      width: 100%;
      margin: 0;
      left: 0;
    }
  }
  &__character {
    position: fixed;
    bottom: -150px;
    width: 350px;

    @media only screen and (max-width: 767px){
      position: relative;
      bottom: initial;
      width: 100%;
    }
  }

  // Background
  &__overlay{
    position: fixed;
    top: 0;
    left: 0;
    background: #66b7b5;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  &__background {
    position: fixed;
    left: 0;
    bottom: -4px;
    width: 100%;
    z-index: -1;
  }
}
</style>
