<template>
  <div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <br>
    <MascotSVG2 v-if="hovering" />
    <MascotSVG1 v-else />
  </div>
</template>

<script>
import MascotSVG1 from '@/components/svg/materiais/MascotSVG1.vue';
import MascotSVG2 from '@/components/svg/materiais/MascotSVG2.vue';

export default {
  data: () => ({
    hovering: false,
  }),
  components: {
    MascotSVG1,
    MascotSVG2,
  },
};
</script>
