<template>
<modal
  :adaptive="true"
  :click-to-close="false"
  name="quiz-modal"
  :height="dynamicModalHeight"
  width="970px"
  @closed="resetQuiz"
>
  <div
    class="o-Modal"
    :class="this.quiz"
  >
    <div class="o-Modal__header">
      <div class="o-Modal__title">
        <img :src="icon" />
        <h2 v-text="title" />
      </div>
      <div class="o-Modal__headerActions">
        <a
          href="#"
          title="Fechar"
          @click.prevent="$modal.hide('quiz-modal')"
        >
          <Close />
        </a>
      </div>
    </div>
    <div
      v-if="!finishedQuiz"
      class="o-Modal__body"
    >
      <h3>{{ currentQuestion.question }}</h3>
      <ul class="o-Modal__answers">
        <li
          v-for="(answer, index) in currentQuestion.answers"
          :key="index"
          class="o-Modal__answer"
        >
          <button
            :disabled="moveNext"
            @click.prevent="validateAnswer(answer)"
          >
            <img
              v-if="moveNext && answer.right"
              :src="greenCheck"
            />
            <img
              v-else-if="moveNext && !answer.right"
              :src="redCross"
            />
            {{ answer.answer }}
          </button>
        </li>
      </ul>
      <div class="o-Modal__actions">
        <button
          class="o-Modal__action"
          :disabled="!moveNext"
          @click.prevent="nextQuestion"
        >
          Continuar
        </button>
      </div>
    </div>

    <div
      v-else
      class="o-Modal__body"
    >
      <div v-if="successCount === questions.length">
        <div class="o-Modal__success">
            <img
              :src="cup"
              class="o-Modal__cup"
            />
            <div>
              <h1>Parabéns!</h1>
              <p>
                Provaste que estás a fazer as escolhas certas.
              </p>
            </div>
        </div>
      </div>
      <template v-else>
        <h1>Estas quase lá!</h1>
        <button
            class="o-Modal__action"
            @click.prevent="resetQuiz"
          >
            Tenta de novo
        </button>
        <br><br><br>
      </template>
    </div>

    <div class="o-Modal__footerWrapper">
      <div class="o-Modal__footer">
        <div>
          <img :src="infraLogo" />
        </div>
        <div>
          <b>Pontuação: {{ successCount }} / {{ questions.length }}</b>
        </div>
      </div>
    </div>
  </div>
</modal>
</template>

<script>
/* eslint-disable */
import Close from '@/components/svg/Close.vue';

export default {
  name: 'QuizModal',
  components: {
    Close,
  },
  props: {
    quiz: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    questionIndex: 0,
    successCount: 0,
    moveNext: false,

    infraLogo: require('../assets/svg/infra_white.svg'),
    greenCheck: require('../assets/svg/green_check.svg'),
    redCross: require('../assets/svg/red_cross.svg'),
    cup: require('../assets/svg/cup.svg'),
  }),
  computed: {
    dynamicModalHeight() {
      return window.innerWidth < 767 ? '100%' : 'auto';
    },
    currentQuestion() {
      return this.questions[this.questionIndex];
    },
    finishedQuiz() {
      return this.questionIndex === this.questions.length;
    },
    icon() {
      let icon = '';
      switch (this.quiz) {
        case 'aqua':
          icon = require('../assets/svg/icons/aqua.svg');
          break;
        case 'recicle':
          icon = require('../assets/svg/icons/recicle.svg');
          break;
        case 'energy':
          icon = require('../assets/svg/icons/energy.svg');
          break;
        case 'nature':
          icon = require('../assets/svg/icons/nature.svg');
          break;
      }

      return icon;
    },
    title() {
      let title = '';
      switch (this.quiz) {
        case 'aqua':
          title = 'Poupa água';
          break;
        case 'recicle':
          title = 'Recicla, fazendo bem a separação';
          break;
        case 'energy':
          title = 'Usa energia verde';
          break;
        case 'nature':
          title = 'Preserva e cuida bem da natureza';
          break;
      }

      return title;
    },
  },
  methods: {
    validateAnswer(answer) {
      if (answer.right === true) {
        this.successCount += 1;
      }

      this.moveNext = true;
    },
    nextQuestion() {
      this.questionIndex += 1;
      this.moveNext = false;
    },
    resetQuiz() {
      this.questionIndex = 0;
      this.successCount = 0;
      this.moveNext = false;
    },
  },
};
</script>
