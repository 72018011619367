<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>

  <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_48" data-name="Layer 48">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <path
              class="cls-2"
              d="M167.28,94v0a.43.43,0,0,1,0,0,2.61,2.61,0,0,0-.05-.48c0-.06,0-.11-.05-.16A2.09,2.09,0,0,0,167,93a.7.7,0,0,0-.09-.17,1.81,1.81,0,0,0-.19-.29,1.58,1.58,0,0,0-.12-.15,2.57,2.57,0,0,0-.29-.28l-.08-.07s0,0,0,0a2.82,2.82,0,0,0-.48-.27l-.09,0a2.65,2.65,0,0,0-.37-.12L125.36,83a2.52,2.52,0,0,0-1.19,0L84.82,93.79A2.46,2.46,0,0,0,82.42,96s0,.1,0,.15a.78.78,0,0,0,0,.16V146a2.5,2.5,0,0,0,1.77,2.39l39.94,12.21h.08a2.84,2.84,0,0,0,.65.1,2.42,2.42,0,0,0,.47-.05l.18-.05.21,0,39.94-14.49a2.5,2.5,0,0,0,1.65-2.35V94Zm-42.37-6,29.88,6.41-30,9.09L95,96.18ZM87.39,99.47l34.94,8.58v46.81L87.39,144.18ZM162.28,142l-34.95,12.67V107.94l35-10.58Z"
          />
          <ellipse class="cls-2" cx="137.42" cy="137.19" rx="3.23" ry="4.92" />
          <ellipse class="cls-2" cx="145.62" cy="125.43" rx="3.23" ry="4.92" />
          <ellipse class="cls-2" cx="152.86" cy="113.5" rx="3.23" ry="4.92" />
          <ellipse class="cls-2" cx="98.78" cy="118.84" rx="3.23" ry="4.92" />
          <ellipse class="cls-2" cx="125.79" cy="94.74" rx="3.23" ry="4.92" transform="translate(26.12 216.66) rotate(-87.72)" />
          <ellipse class="cls-2" cx="109.44" cy="135.71" rx="3.23" ry="4.92" />
      </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="1080" height="1080" transform="translate(8.86 8.86) scale(0.22)" xlink:href="/jogo_mini.gif" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>
</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped lang="scss">
  svg {
    height: auto;
  }
  .cls-1 {
      fill: #51a4b0;
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
  .cls-2 {
      fill: #fff;
      opacity: 0.5;
  }
  .clip-svg {
    overflow: hidden;
    border-radius: 50%;
    border: 10px solid #FFF;

    img {
      height: auto;
      width: 100%;
    }
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
