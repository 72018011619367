<template>
<modal
  :adaptive="true"
  :click-to-close="false"
  name="drawing-modal"
  height="auto"
  width="90%"
  @closed="resetDrawing"
>
  <div
    class="o-Modal"
    :class="this.quiz"
  >
    <div class="o-Modal__header">
      <div class="o-Modal__title">
        <img :src="icon" />
        <h2 v-text="title" />
      </div>
      <div class="o-Modal__headerActions">
        <a
          href="#"
          title="Fechar"
          @click.prevent="$modal.hide('drawing-modal')"
        >
          <Close />
        </a>
      </div>
    </div>
    <div class="o-Modal__body">
      <Aqua v-if="quiz === 'aqua'"/>
      <Recicle v-if="quiz === 'recicle'"/>
      <Energy v-if="quiz === 'energy'"/>
      <Nature v-if="quiz === 'nature'"/>
    </div>
  </div>
</modal>
</template>

<script>
/* eslint-disable */
import Aqua from '@/components/svg/drawing/Aqua.vue';
import Recicle from '@/components/svg/drawing/Recicle.vue';
import Energy from '@/components/svg/drawing/Energy.vue';
import Nature from '@/components/svg/drawing/Nature.vue';
import Close from '@/components/svg/Close.vue';

export default {
  name: 'DrawingModal',
  components: {
    Aqua,
    Recicle,
    Energy,
    Nature,
    Close,
  },
  props: {
    quiz: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    icon() {
      let icon = '';
      switch (this.quiz) {
        case 'aqua':
          icon = require('../assets/svg/icons/aqua.svg');
          break;
        case 'recicle':
          icon = require('../assets/svg/icons/recicle.svg');
          break;
        case 'energy':
          icon = require('../assets/svg/icons/energy.svg');
          break;
        case 'nature':
          icon = require('../assets/svg/icons/nature.svg');
          break;
      }

      return icon;
    },
    title() {
      let title = '';
      switch (this.quiz) {
        case 'aqua':
          title = 'Poupa água';
          break;
        case 'recicle':
          title = 'Recicla, fazendo bem a separação';
          break;
        case 'energy':
          title = 'Usa energia verde';
          break;
        case 'nature':
          title = 'Preserva e cuida bem da natureza';
          break;
      }

      return title;
    },
  },
  methods: {
    resetDrawing() {

    },
  },
};
</script>

<style lang="scss" scoped>
.o-Modal {
  $parent: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.aqua {
    #{$parent}__title {
      color: #88c9df;
    }
  }
  &.recicle {
    #{$parent}__title {
      color: #d1db44;
    }
  }
  &.energy {
    #{$parent}__title {
      color: #53a96d;
    }
  }
  &.nature {
    #{$parent}__title {
      color: #a9d16b;
    }
  }

  &__headerWrapper,
  &__footerWrapper {
    width: 100%;
  }

  &__header,
  &__footer {
    display: flex;
    align-items: center;
    width: 95%;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      margin-left: 1em;
    }
  }
  &__headerActions {
    margin-left: auto;
    width: 32px;
  }

  &__header {
    margin: 1em;
    justify-content: flex-start;

    img {
      width: 48px;
    }
  }

  &__title {
    margin-left: 1em;
  }
}
</style>
