<template>
<div class="o-Drawing">
  <div class="o-Drawing__svg">
    <svg id="drawing" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
      <g id="background">
        <rect x="0" class="st0" width="1920" height="1080"
          data-ref="background"
          :style="`fill: ${drawingColors.background}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mar">
        <path
          class="st1" d="M1094.38,625.04c0,0-20.52-72.63-24.15-111.9s-14.53-46.11-71.13-59.81c-56.59-13.7-54.01-54.06-54.01-54.06
          l43.05-111.83c-5.34-0.27-10.72-0.41-16.13-0.41c-87.1,0-165.85,35.75-222.4,93.36c12.21-0.3,25.03,2.63,34.34,13.09
          c23.94,26.92,30.3,38.24,28.71,70.78c-1.59,32.54,46.15,67.56,46.15,67.56l47.74,57.24c0,0,39.79,39.84-7.96,66.89
          c-47.74,27.06-97.83,19.1-97.83,19.1s-50.36,1.11-55.84,36.37c-5.48,35.25-24.75,70.46-24.75,70.46l-0.23,0.09
          c8.85,12.16,18.56,23.64,29.05,34.38c5.36-3.52,10.81-6.59,16.3-9.03c51.14-22.77,111-10.09,111-10.09s88.79,40.71,122.46,43.32
          c65.64,5.08,131.11-19.89,138.68-21.51c24.35-5.24,44.58-6.29,60.37-5.57c48.22-50.68,79.55-117.58,84.99-191.68
          c-27.16,14.77-55.92,29.62-81.65,41.27C1121.34,699.2,1094.38,625.04,1094.38,625.04z"
          data-ref="mar"
          :style="`fill: ${drawingColors.mar}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mar_x5F_01">
        <path class="st1" d="M1137.45,819.02c-7.57,1.63-73.04,26.6-138.68,21.51c-33.67-2.61-122.46-43.32-122.46-43.32
          s-59.86-12.68-111,10.09c-5.49,2.44-10.94,5.51-16.3,9.03c8.92,9.13,18.38,17.72,28.36,25.71c42.58-9.38,82.65-0.9,82.65-0.9
          s66.37,41.82,122.46,43.32c65.81,1.77,131.11-19.89,138.68-21.51c7.75-1.67,15.08-2.91,21.99-3.81
          c19.94-13.12,38.29-28.46,54.68-45.69C1182.03,812.73,1161.8,813.78,1137.45,819.02z"
          data-ref="mar_x5F_01"
          :style="`fill: ${drawingColors.mar_x5F_01}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="mar_x5F_02">
        <path class="st1" d="M1143.15,859.14c-6.91,0.9-14.24,2.14-21.99,3.81c-7.57,1.63-72.87,23.28-138.68,21.51
          c-56.09-1.5-122.46-43.32-122.46-43.32s-40.07-8.48-82.65,0.9c53.34,42.71,121,68.28,194.65,68.28
          C1035.23,910.31,1094.03,891.48,1143.15,859.14z"
          data-ref="mar_x5F_02"
          :style="`fill: ${drawingColors.mar_x5F_02}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="mar_x5F_03">
        <path class="st1" d="M1242.92,622.56c-71.33,32.31-95.42-33.97-95.42-33.97s-18.33-64.9-21.58-100s-12.99-41.21-63.56-53.45
          c-50.57-12.24-48.26-48.3-48.26-48.3l34.75-90.26c-19.56-4.96-39.86-8.08-60.7-9.14L945.1,399.27c0,0-2.59,40.36,54.01,54.06
          c56.59,13.7,67.49,20.54,71.13,59.81s24.15,111.9,24.15,111.9s26.96,74.16,106.77,38.01c25.73-11.66,54.49-26.5,81.65-41.27
          c0.46-6.29,0.72-12.63,0.8-19.01C1269.69,609.92,1255.87,616.69,1242.92,622.56z"
          data-ref="mar_x5F_03"
          :style="`fill: ${drawingColors.mar_x5F_03}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mar_x5F_04">
        <path class="st1" d="M1188.22,531.2c0,0-16.06-56.86-18.91-87.61c-2.84-30.75-11.38-36.1-55.69-46.83
          c-44.31-10.72-42.28-42.32-42.28-42.32l17.27-44.86c-12.85-5.19-26.13-9.55-39.76-13.01l-34.75,90.26c0,0-2.31,36.06,48.26,48.3
          c50.57,12.24,60.31,18.35,63.56,53.45s21.58,100,21.58,100s24.09,66.27,95.42,33.97c12.95-5.87,26.76-12.64,40.69-19.79
          c0.02-1.37,0.05-2.73,0.05-4.1c0-14.21-0.97-28.19-2.81-41.89c-3.06,1.44-6.07,2.84-9.04,4.18
          C1209.33,589.27,1188.22,531.2,1188.22,531.2z"
          data-ref="mar_x5F_04"
          :style="`fill: ${drawingColors.mar_x5F_04}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="mar_x5F_05">
        <path class="st1" d="M1088.62,309.59l-17.27,44.86c0,0-2.03,31.6,42.28,42.32c44.31,10.72,52.84,16.08,55.69,46.83
          c2.84,30.75,18.91,87.61,18.91,87.61s21.11,58.06,83.59,29.76c2.96-1.34,5.98-2.74,9.04-4.18
          C1265.73,444.24,1190.56,350.74,1088.62,309.59z"
          data-ref="mar_x5F_05"
          :style="`fill: ${drawingColors.mar_x5F_05}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="mar_x5F_06">
        <path class="st1" d="M744.94,711.4c5.48-35.25,55.84-36.37,55.84-36.37s50.08,7.96,97.83-19.1c47.74-27.06,7.96-66.89,7.96-66.89
          l-47.74-57.24c0,0-47.74-35.02-46.15-67.56c1.59-32.54-4.77-43.85-28.71-70.78c-9.3-10.46-22.13-13.4-34.34-13.09
          c-4.04,4.12-7.97,8.35-11.79,12.69c9.91,0.3,19.97,3.26,27.47,11.7c21.39,24.06,27.08,34.17,25.66,63.25
          c-1.42,29.08,41.25,60.38,41.25,60.38l42.67,51.16c0,0,35.56,35.6-7.11,59.78c-42.67,24.18-87.43,17.07-87.43,17.07
          s-45.01,1-49.91,32.5c-4.9,31.5-22.12,62.97-22.12,62.97l-6.36,2.43c5.52,9.55,11.54,18.77,18,27.65l0.23-0.09
          C720.2,781.86,739.46,746.65,744.94,711.4z"
          data-ref="mar_x5F_06"
          :style="`fill: ${drawingColors.mar_x5F_06}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="mar_x5F_07">
        <path class="st1" d="M715.24,443.52c15.63,17.59,19.79,24.98,18.75,46.23c-1.04,21.25,30.15,44.13,30.15,44.13l31.19,37.39
          c0,0,25.99,26.02-5.2,43.69c-31.19,17.67-63.9,12.47-63.9,12.47s-32.89,0.73-36.47,23.75c-2.78,17.86-10.97,35.69-14.5,42.8
          c6.8,21.18,15.79,41.38,26.72,60.32l6.36-2.43c0,0,17.22-31.47,22.12-62.97c4.9-31.5,49.91-32.5,49.91-32.5s44.76,7.11,87.43-17.07
          c42.67-24.18,7.11-59.78,7.11-59.78l-42.67-51.16c0,0-42.67-31.29-41.25-60.38c1.42-29.08-4.27-39.19-25.66-63.25
          c-7.5-8.44-17.56-11.4-27.47-11.7c-11.97,13.62-22.78,28.29-32.25,43.86C709.12,438.26,712.42,440.36,715.24,443.52z"
          data-ref="mar_x5F_07"
          :style="`fill: ${drawingColors.mar_x5F_07}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="mar_x5F_08">
        <path class="st1" d="M726.23,627.43c0,0,32.71,5.2,63.9-12.47c31.19-17.67,5.2-43.69,5.2-43.69l-31.19-37.39
          c0,0-31.19-22.87-30.15-44.13c1.04-21.25-3.12-28.64-18.75-46.23c-2.81-3.16-6.12-5.26-9.65-6.6
          c-28.69,47.15-45.21,102.52-45.21,161.75c0,33.25,5.23,65.27,14.87,95.32c3.54-7.11,11.73-24.94,14.5-42.8
          C693.33,628.16,726.23,627.43,726.23,627.43z"
          data-ref="mar_x5F_08"
          :style="`fill: ${drawingColors.mar_x5F_08}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="monte_x5F_03">
        <path class="st2" d="M1187.59,373.61c-13.6-40.55-51.88-69.78-97.02-69.78c-4.82,0-9.69,0.34-14.56,0.99
          C1118.08,319.71,1156.01,343.36,1187.59,373.61z"
          data-ref="monte_x5F_03"
          :style="`fill: ${drawingColors.monte_x5F_03}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="monte_x5F_04">
        <path class="st2" d="M1297.02,585.89c0-24.55-8.65-47.07-23.06-64.71c6.34,24.77,9.71,50.73,9.71,77.48c0,14.43-1,28.62-2.9,42.53
          C1291.04,625.25,1297.02,606.27,1297.02,585.89z"
          data-ref="monte_x5F_04"
          :style="`fill: ${drawingColors.monte_x5F_04}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="monte_x5F_01">
        <path class="st2" d="M660.38,598.67c0-27.92,3.69-54.97,10.58-80.72c-53.63,4.97-109.02,48.67-109.02,101.86
          c0,56.52,62.54,102.34,119.06,102.34c1.6,0,3.18-0.05,4.76-0.12C669.44,684.19,660.38,642.49,660.38,598.67z"
          data-ref="monte_x5F_01"
          :style="`fill: ${drawingColors.monte_x5F_01}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="monte_x5F_02">
        <path class="st2" d="M745.95,384.17c-41.15,0.06-74.48,33.44-74.48,74.6c0,11.87,2.78,23.08,7.72,33.04
          C693.95,451.35,716.87,414.81,745.95,384.17z"
          data-ref="monte_x5F_02"
          :style="`fill: ${drawingColors.monte_x5F_02}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="nuvem_x5F_dta_x5F_02">
        <path class="st1" d="M1366.49,681.3c-0.61-14.08-9.08-26.15-21.17-31.86c0.18-1.76,0.32-3.54,0.32-5.39
          c0-27.52-22.35-49.89-49.9-49.89c-18.02,0-33.76,9.59-42.52,23.93c-1.11-0.52-2.22-0.95-3.37-1.34
          c-8.22-19.19-27.26-32.64-49.43-32.64c-19.45,0-36.41,10.34-45.85,25.78c-4.21-1.99-8.92-3.13-13.88-3.13
          c-17.95,0-32.54,14.58-32.54,32.5c0,1.17,0.07,2.3,0.18,3.42c-14.47,5.74-24.75,19.73-25.13,36.16c0,0.29-0.07,0.59-0.07,0.89
          c0,0.53,0.11,1.03,0.13,1.55H1366.49z"
          data-ref="nuvem_x5F_dta_x5F_02"
          :style="`fill: ${drawingColors.nuvem_x5F_dta_x5F_02}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="casco_x5F_barco">
        <polygon class="st1" points="902.43,767.49 998.78,767.49 993.96,773.15 906.11,773.15"
          data-ref="casco_x5F_barco"
          :style="`fill: ${drawingColors.casco_x5F_barco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="vela">
        <polygon class="st1" points="960.23,755.92 960.89,685.74 918.24,755.92"
          data-ref="vela"
          :style="`fill: ${drawingColors.vela}!important;`"
          @click="changeColor($event)"
        />
        <polygon class="st1" points="986.54,767.49 962.78,685.74 962.78,755.9"
          data-ref="vela"
          :style="`fill: ${drawingColors.vela}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="bandeira_x5F_barco">
        <g>
          <path class="st1" d="M960.89,678.14c-6.62,2.55-13.25-5.97-19.87-3.42c6.62-4.83,13.25,1.41,19.87-3.42
            C960.89,673.58,960.89,675.87,960.89,678.14z"
            data-ref="bandeira_x5F_barco"
            :style="`fill: ${drawingColors.bandeira_x5F_barco}!important;`"
            @click="changeColor($event)"
            />
        </g>
      </g>
      <g id="mastro">
        <rect x="960.23" y="670.73" class="st1" width="2.55" height="96.77"
            data-ref="mastro"
            :style="`fill: ${drawingColors.mastro}!important;`"
            @click="changeColor($event)"
        />
      </g>
      <g id="topo_x5F_mastro">
        <path class="st1" d="M957.94,680.11c0,0.78,0.72,1.42,1.6,1.42h3.95c0.87,0,1.59-0.64,1.59-1.42v-0.07c0-0.78-0.72-1.42-1.59-1.42
          h-3.95c-0.88,0-1.6,0.63-1.6,1.42V680.11z"
            data-ref="mastro"
            :style="`fill: ${drawingColors.mastro}!important;`"
            @click="changeColor($event)"
          />
      </g>
      <g id="gaivota">
        <path class="st2" d="M1079.55,201.28c-2.15-1.92-1.95-0.46-1.61-0.01c0.32,0.53,0.91,0.76,0.95,1.56c0.94,0.52,1.02,1.89,2.04,2.33
          c0.02,0.24-0.15,0.42,0.06,0.54c1.06,0.71,1.51,2.04,2.63,2.69c0.22,0.9,1.05,1.24,1.57,1.8c0.28,0.32,0.37,0.76,0.66,1.01
          c0.29,0.28,0.77,0.4,1.07,0.67c0.39,0.35,0.55,0.93,1.14,0.96c0.09,0.18-0.3,0.23-0.13,0.54c0.52,0.24,0.85,0.56,0.9,1.14
          c0.49,0.02,0.61,0.52,0.9,0.78c0.25,0.22,0.66,0.24,0.77,0.59c0.18,0.1,0.39,0.17,0.67,0.18c0.37,0.8,1.14,1.29,1.67,1.96
          c0.83,1.07,1.67,2.11,2.52,3.06c0.89,1,1.7,2,2.57,2.99c0.04,0.35-0.27,0.37-0.24,0.72c0.22,0.35,0.43,0.39,0.36,0.78
          c-1.46,0.35-2.99,1.16-4.67,1.44c-1.82,0.31-3.79,0.04-5.81,0.3c0.47,0.31,1.04,0.6,1.67,0.77c0.33,0.1,0.64,0.05,0.95,0.12
          c0.31,0.08,0.58,0.36,0.9,0.42c0.51,0.1,1.03-0.04,1.5,0.18c-0.01,0.27-0.4,0.4,0.06,0.65c0.45,0.48,1.28,0.57,2.02,0.66
          c0.77,0.11,1.75,0.33,2.28,0.3c0.54-0.02,1.01-0.38,1.5-0.36c1.22,0.04,2.56,1.17,3.77,1.55c1.39,0.44,2.96,0.59,4.42,0.78
          c1.63,0.19,3.13,0.03,4.74-0.06c1.52-0.09,2.83-0.58,4.3-0.83c0.75-0.14,1.44-0.26,2.17-0.48c0.66-0.2,1.36-0.53,2.04-0.6
          c1.69-0.18,2.13,0.85,3.51,0.84c0.22-1.62-1.74-1.51-2.46-2.39c-0.31-0.38-0.28-1.02-0.54-1.44c-0.24-0.41-0.76-0.76-1.25-1.07
          c-0.96-0.59-1.78-1.09-2.87-1.44c2.66-2.49,7.42-4.05,11.91-4.78c0.23-0.03,0.49,0.01,0.72-0.06c0.53-0.13,0.93-0.58,1.43-0.84
          c0.51-0.24,1.15-0.51,1.73-0.76c0.58-0.25,1.19-0.61,1.73-0.78c1.7-0.53,3.39-0.88,4.67-1.73c0.42-0.28,0.71-0.75,1.14-1.01
          c0.55-0.36,1.16-0.55,1.68-0.85c2.59-1.51,4.61-4.07,5.74-6.88c0.44-1.1,1.25-2.46,0.84-3.95c-0.35-0.13-0.49,0.35-0.9,0.07
          c-0.54,0.46-1.11,0.88-1.44,1.55c-1.19,0.27-1.79,1.12-2.5,1.86c-1.16,0.39-2.03,1.08-2.81,1.86c-1.25,0.31-2.08,1.03-3.05,1.61
          c-1.56,0.35-2.92,1.3-4.14,2.02c-0.45,0.27-0.96,0.49-1.37,0.78c-0.39,0.27-0.69,0.66-1.09,0.97c-0.68,0.51-1.33,1.02-1.97,1.49
          c-0.33,0.24-0.65,0.45-1.01,0.66c-0.74,0.43-1.42,0.99-2.1,1.25c-0.62,0.24-1.33,0.44-1.98,0.67c-0.69,0.22-1.38,0.4-2.08,0.59
          c-3.56,0.97-6.82,2.07-9.82,3.59c-0.99,0.51-1.62,1.02-2.88,0.95c-2.42-2.12-4.04-4.63-6.22-7c-0.69-0.75-1.91-1.32-2.88-1.92
          c-2.96-1.84-6.29-3.27-9.22-4.96C1086.31,206.9,1082.49,204.69,1079.55,201.28z"
          data-ref="gaivota"
          :style="`fill: ${drawingColors.gaivota}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="sol">
        <g>
          <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 282.4338 1007.5367)" class="st1" cx="1357.42" cy="162.84" rx="65.1" ry="65.1"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
          />
          <path class="st1" d="M1421.69,125.73L1421.69,125.73c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7c2.54-1.46,5.78-0.6,7.24,1.94l0,0
            c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7C1426.4,129.14,1423.16,128.27,1421.69,125.73z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1421.69,199.95L1421.69,199.95c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7c2.54,1.46,3.4,4.71,1.94,7.24l0,0
            c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7C1421.1,205.73,1420.23,202.48,1421.69,199.95z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1357.42,237.06L1357.42,237.06c2.93,0,5.3,2.37,5.3,5.3v23.4c0,2.93-2.37,5.3-5.3,5.3l0,0
            c-2.93,0-5.3-2.37-5.3-5.3v-23.4C1352.12,239.43,1354.49,237.06,1357.42,237.06z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1293.15,199.95L1293.15,199.95c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7c-2.54,1.46-5.78,0.6-7.24-1.94l0,0
            c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7C1288.44,196.54,1291.69,197.41,1293.15,199.95z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1293.15,125.73L1293.15,125.73c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7c-2.54-1.46-3.4-4.71-1.94-7.24
            l0,0c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7C1293.74,119.96,1294.61,123.2,1293.15,125.73z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1357.42,88.63L1357.42,88.63c-2.93,0-5.3-2.37-5.3-5.3v-23.4c0-2.93,2.37-5.3,5.3-5.3l0,0
            c2.93,0,5.3,2.37,5.3,5.3v23.4C1362.72,86.25,1360.35,88.63,1357.42,88.63z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1320.31,98.57L1320.31,98.57c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05c-1.46-2.54-0.6-5.78,1.94-7.24l0,0
            c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05C1323.72,93.86,1322.85,97.1,1320.31,98.57z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1283.21,162.84L1283.21,162.84c0,2.93-2.37,5.3-5.3,5.3h-15.07c-2.93,0-5.3-2.37-5.3-5.3l0,0
            c0-2.93,2.37-5.3,5.3-5.3h15.07C1280.83,157.54,1283.21,159.91,1283.21,162.84z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1320.31,227.11L1320.31,227.11c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05c-1.46,2.54-4.71,3.4-7.24,1.94l0,0
            c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05C1314.54,226.52,1317.78,225.65,1320.31,227.11z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1394.53,227.11L1394.53,227.11c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05c1.46,2.54,0.6,5.78-1.94,7.24l0,0
            c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05C1391.12,231.82,1391.99,228.58,1394.53,227.11z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1431.64,162.84L1431.64,162.84c0-2.93,2.37-5.3,5.3-5.3H1452c2.93,0,5.3,2.37,5.3,5.3l0,0
            c0,2.93-2.37,5.3-5.3,5.3h-15.07C1434.01,168.14,1431.64,165.77,1431.64,162.84z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1394.53,98.57L1394.53,98.57c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05c1.46-2.54,4.71-3.4,7.24-1.94l0,0
            c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05C1400.31,99.16,1397.06,100.03,1394.53,98.57z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
            />
        </g>
      </g>
      <g id="rosto_x5F_sol">
        <g class="st3">
          <path class="st4" d="M1345.52,209.22c-7.34,0-14.48-3.45-18.97-9.49c-0.84-1.13-0.6-2.73,0.53-3.57c1.13-0.84,2.73-0.6,3.57,0.53
            c4.5,6.06,12.41,8.78,19.69,6.78c4-1.1,7.54-3.56,9.98-6.9c0.83-1.14,2.43-1.39,3.57-0.56c1.14,0.83,1.39,2.43,0.56,3.57
            c-3.12,4.28-7.65,7.41-12.75,8.82C1349.66,208.95,1347.58,209.22,1345.52,209.22z"/>
        </g>
        <g class="st3">
          <path class="st5" d="M1326.36,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
            C1319,166.87,1322.29,163.58,1326.36,163.58"/>
        </g>
        <g class="st3">
          <path class="st5" d="M1364.62,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
            C1357.27,166.87,1360.56,163.58,1364.62,163.58"/>
        </g>
      </g>
      <g id="golfinho">
        <g>
          <g>
            <path class="st2" d="M1164.71,725.65c-0.13-0.27-0.34-0.53-0.53-0.73c-0.46-0.49-0.82-0.9-1-1.31c-0.38-0.82-0.29-1.81-0.53-2.53
              c-0.22-0.64-0.54-1.35-1.04-1.95c-0.18-0.22-1.93-1.92-2.88-2.54c-0.96-0.63-2-1.04-2.8-1.83c-0.75-0.72-1.44-1.49-2.09-2.23
              c-0.7-0.78-1.38-1.95-2.44-2.34c-0.66-0.25-0.33,1.48-0.54,1.96c-0.55,1.25-2.41,1.38-3.66,1.49c-0.86,0.06-4.53,1.07-5.58,1.57
              c-2.65,1.25-5.16,3.07-7.36,4.99c-0.45,0.38-1.73,1.67-1.93,1.88c-0.23,0.22-0.54,0.66-0.79,0.91c-0.29,0.31-0.95,1.18-1.28,1.33
              c-0.53,0.24-1.53-0.26-2.19-0.37c-1.58-0.26-2.62,0.57-3.47,1.68c1.36,0.06,1.93-0.13,3.19,0.27c2.01,0.64,1.44,2.8,1.35,4.69
              c1.17-0.85,1.86-1.69,1.74-3.32c-0.03-0.52-0.39-1.16-0.05-1.51c5.68-5.93,12.76-4.51,13.16-4.44c2.98,0.52,6.24,2.21,6.76,2.93
              c-0.52,0.69-1.18,1.16-1.58,2.07c1.55,0.31,2.47-0.28,3.54-0.81c0.35-0.17,0.78-0.47,1.07-0.47c0.69,0.01,8.06,2.38,9.75,2.39
              C1165.33,727.45,1165.2,726.51,1164.71,725.65z"
              data-ref="golfinho"
              :style="`fill: ${drawingColors.golfinho}!important;`"
              @click="changeColor($event)"
              />
          </g>
          <g>
            <path class="st2" d="M1221.01,719.98c-0.11-0.27-0.33-0.53-0.52-0.73c-0.47-0.49-0.81-0.89-1.01-1.31
              c-0.38-0.81-0.29-1.81-0.52-2.53c-0.22-0.64-0.55-1.35-1.04-1.95c-0.19-0.22-1.93-1.93-2.88-2.54c-0.96-0.63-1.99-1.05-2.8-1.83
              c-0.74-0.72-1.43-1.49-2.09-2.23c-0.7-0.79-1.38-1.96-2.44-2.34c-0.67-0.25-0.33,1.49-0.54,1.96c-0.55,1.24-2.41,1.38-3.66,1.48
              c-0.86,0.07-4.53,1.07-5.58,1.57c-2.66,1.25-5.16,3.08-7.37,4.99c-0.44,0.39-1.73,1.67-1.93,1.88c-0.22,0.22-0.54,0.66-0.79,0.91
              c-0.28,0.31-0.96,1.18-1.28,1.33c-0.53,0.24-1.53-0.26-2.19-0.37c-1.58-0.26-2.62,0.57-3.47,1.68c1.36,0.06,1.93-0.13,3.19,0.27
              c2,0.64,1.43,2.8,1.35,4.68c1.17-0.85,1.86-1.69,1.74-3.31c-0.03-0.53-0.39-1.16-0.05-1.51c5.68-5.94,12.76-4.51,13.16-4.44
              c2.98,0.52,6.24,2.21,6.76,2.93c-0.52,0.69-1.18,1.16-1.58,2.08c1.55,0.3,2.47-0.29,3.55-0.82c0.35-0.17,0.77-0.48,1.06-0.47
              c0.69,0.01,8.06,2.38,9.75,2.39C1221.65,721.78,1221.52,720.84,1221.01,719.98z"
              data-ref="golfinho"
              :style="`fill: ${drawingColors.golfinho}!important;`"
              @click="changeColor($event)"
              />
          </g>
          <g>
            <path class="st2" d="M1182.61,765.56c-0.11-0.27-0.34-0.53-0.52-0.73c-0.47-0.49-0.82-0.9-1-1.31c-0.38-0.82-0.29-1.81-0.53-2.53
              c-0.21-0.64-0.54-1.35-1.03-1.95c-0.18-0.22-1.93-1.93-2.88-2.54c-0.96-0.63-1.99-1.05-2.8-1.83c-0.75-0.72-1.44-1.5-2.09-2.23
              c-0.7-0.78-1.38-1.96-2.45-2.34c-0.65-0.26-0.32,1.48-0.54,1.96c-0.55,1.25-2.41,1.38-3.66,1.48c-0.86,0.06-4.53,1.07-5.58,1.58
              c-2.65,1.25-5.16,3.07-7.37,4.99c-0.45,0.38-1.73,1.67-1.93,1.89c-0.22,0.22-0.54,0.66-0.79,0.91c-0.28,0.31-0.96,1.18-1.29,1.33
              c-0.52,0.24-1.53-0.27-2.18-0.37c-1.58-0.26-2.62,0.57-3.47,1.68c1.36,0.06,1.92-0.13,3.19,0.27c2.01,0.64,1.44,2.8,1.35,4.69
              c1.17-0.85,1.86-1.69,1.73-3.32c-0.03-0.52-0.38-1.16-0.05-1.51c5.69-5.93,12.77-4.51,13.17-4.44c2.98,0.52,6.24,2.21,6.76,2.92
              c-0.53,0.69-1.18,1.16-1.58,2.07c1.55,0.3,2.47-0.28,3.54-0.81c0.35-0.17,0.78-0.48,1.07-0.47c0.69,0.01,8.06,2.38,9.75,2.39
              C1183.24,767.36,1183.11,766.42,1182.61,765.56z"
              data-ref="golfinho"
              :style="`fill: ${drawingColors.golfinho}!important;`"
              @click="changeColor($event)"
              />
          </g>
        </g>
      </g>
      <g id="onda_x5F_1">
        <g>
          <path class="st1" d="M1507.36,1080c-32.91-35.53-82.03-35.53-114.93,0H1507.36z"
          data-ref="onda_x5F_1"
          :style="`fill: ${drawingColors.onda_x5F_1}!important;`"
          @click="changeColor($event)"
          />
          <path class="st1" d="M1816.78,1080c-32.9-35.53-82.02-35.53-114.93,0H1816.78z"
          data-ref="onda_x5F_1"
          :style="`fill: ${drawingColors.onda_x5F_1}!important;`"
          @click="changeColor($event)"
          />
          <path class="st1" d="M579.08,1080c-32.91-35.53-82.03-35.52-114.93,0H579.08z"
          data-ref="onda_x5F_1"
          :style="`fill: ${drawingColors.onda_x5F_1}!important;`"
          @click="changeColor($event)"
          />
          <path class="st1" d="M269.65,1080c-32.9-35.52-82.02-35.52-114.93,0H269.65z"
          data-ref="onda_x5F_1"
          :style="`fill: ${drawingColors.onda_x5F_1}!important;`"
          @click="changeColor($event)"
          />
          <path class="st1" d="M1106.76,1061.7l2.51,18.3h88.66C1172.27,1052.3,1136.76,1046.2,1106.76,1061.7z"
          data-ref="onda_x5F_1"
          :style="`fill: ${drawingColors.onda_x5F_1}!important;`"
          @click="changeColor($event)"
          />
          <path class="st1" d="M773.57,1080h56.12l3.64-26.58C812.07,1052.73,790.64,1061.57,773.57,1080z"
          data-ref="onda_x5F_1"
          :style="`fill: ${drawingColors.onda_x5F_1}!important;`"
          @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="onda_x5F_2">
        <g>
          <path class="st1" d="M1359.36,1080c2.63-3.51,5.13-7.27,7.47-11.31c41.92-72.48,124.21-72.48,166.13,0
            c2.33,4.04,4.83,7.8,7.47,11.31h44.92c-24.25-5.97-47.19-23.29-63.79-52c-35.49-61.37-107.82-61.37-143.31,0
            c-16.6,28.7-39.53,46.03-63.78,52H1359.36z"
            data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M1668.79,1080c2.63-3.51,5.13-7.27,7.47-11.31c41.92-72.47,124.2-72.47,166.12,0
            c2.33,4.04,4.83,7.8,7.46,11.31h44.91c-24.25-5.97-47.18-23.3-63.78-52c-35.49-61.36-107.81-61.36-143.3,0
            c-16.6,28.71-39.54,46.03-63.79,52H1668.79z"
            data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M38.2,1080c-13.42-3.3-26.43-10.09-38.2-20.35V1080H38.2z"
          data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
          />
          <path class="st1" d="M121.66,1080c2.63-3.51,5.13-7.27,7.46-11.31c41.92-72.47,124.21-72.47,166.13,0
            c2.33,4.04,4.83,7.8,7.46,11.31h44.92c-24.25-5.97-47.19-23.29-63.79-52c-35.49-61.36-107.81-61.36-143.31,0
            c-16.6,28.7-39.53,46.03-63.78,52H121.66z"
            data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
            />
          <path class="st1" d="M431.08,1080c2.63-3.51,5.13-7.27,7.47-11.31c41.92-72.47,124.2-72.47,166.13,0
            c2.33,4.04,4.83,7.8,7.47,11.31h44.92c-24.25-5.97-47.19-23.29-63.79-51.99c-35.49-61.36-107.82-61.36-143.31,0
            c-16.6,28.71-39.54,46.03-63.79,52H431.08z"
            data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
            />
          <g>
            <path class="st1" d="M838.63,1014.71l4.34-31.71c-30.71-5.21-63.22,9.78-83.59,45.01c-16.6,28.71-39.54,46.03-63.79,52h44.91
              c2.63-3.51,5.13-7.27,7.47-11.31C770.65,1029.5,805.12,1011.51,838.63,1014.71z"
              data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
              />
            <path class="st1" d="M937.17,1066.58l0.25,13.42h29.07C956.36,1077.51,946.47,1073.03,937.17,1066.58z"
            data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
            />
          </g>
          <path class="st1" d="M1212.12,1028c-27.45-47.47-76.94-58.21-114.38-32.23l3.89,28.41c41.65-21.97,92.03-7.14,121.9,44.51
            c2.33,4.04,4.83,7.8,7.47,11.31h44.91C1251.65,1074.03,1228.72,1056.71,1212.12,1028z"
            data-ref="onda_x5F_2"
            :style="`fill: ${drawingColors.onda_x5F_2}!important;`"
            @click="changeColor($event)"
            />
        </g>
      </g>
      <g id="onda_x5F_3">
        <g>
          <g>
            <path class="st1" d="M1842.38,954.46c-41.92-72.47-124.2-72.47-166.12,0c-35.49,61.36-107.81,61.36-143.3,0
              c-41.92-72.48-124.21-72.48-166.13,0c-35.49,61.36-107.81,61.36-143.3,0c-33.38-57.71-92.34-69.47-136.15-35.28
              c0.12,3.39,0.66,5.72,0.66,5.72l3.62,26.41c43.3-30.23,99.57-17.43,131.87,38.42c35.49,61.37,107.81,61.36,143.3,0
              c41.92-72.48,124.21-72.48,166.13,0c35.49,61.36,107.81,61.37,143.3,0c41.92-72.47,124.2-72.47,166.12,0
              c19.06,32.95,48.73,48.2,77.62,45.76v-35.28C1891.11,1002.66,1861.43,987.41,1842.38,954.46z"
              data-ref="onda_x5F_3"
              :style="`fill: ${drawingColors.onda_x5F_3}!important;`"
              @click="changeColor($event)"
            />
            <path class="st1" d="M849.2,937.48l1.72-12.58c0,0,3.24-13.93-4.34-23.26c-35.92-7.08-74.14,10.52-98.6,52.82
              c-35.49,61.36-107.81,61.36-143.3,0c-41.92-72.47-124.21-72.47-166.13,0c-35.49,61.37-107.81,61.37-143.3,0
              c-41.92-72.47-124.2-72.47-166.13,0C97.87,1008.49,38.07,1014.94,0,973.83v35.28c38.07,41.12,97.87,34.67,129.12-19.36
              c41.92-72.47,124.21-72.47,166.13,0c35.49,61.37,107.81,61.37,143.3,0c41.92-72.47,124.2-72.47,166.13,0
              c35.49,61.36,107.81,61.37,143.3,0C773.04,946.41,812.54,929,849.2,937.48z"
              data-ref="onda_x5F_3"
              :style="`fill: ${drawingColors.onda_x5F_3}!important;`"
              @click="changeColor($event)"
            />
          </g>
          <path class="st1" d="M1003.06,998.35c-23.01,5.73-47.5-0.09-67.48-17.43l0.66,35.84c19.67,16.74,43.6,22.42,66.16,17.03
            L1003.06,998.35z"
            data-ref="onda_x5F_3"
            :style="`fill: ${drawingColors.onda_x5F_3}!important;`"
            @click="changeColor($event)"
            />
        </g>
      </g>
      <g id="mao_x5F_esq">
        <path
          class="st1" d="M708.57,831.31c8.11,14.82,21.29,26.22,37.13,32.12c28.77,10.71,74.32,27.38,89,31.06
          c22.03,5.52,16.23,30.42,16.23,30.42L829.7,1080h107.72l-3.02-163.21l-7-84.28c-0.7-8.48-6.72-14.48-12.63-20.62
          c-19.61-20.37-68.63-42.5-68.63-42.5c-30.99-15.92-37.87,19.81-20.32,31.73l35.7,22.67c2.18,1.24,1.54,4.54-0.95,4.86l-43.46,5.67
          c-12.41,1.62-25.02-0.42-36.29-5.87l-28.25-11.32c-19.16-9.27-21.55-15.94-32.08-34.44c-8.14-14.3-15.2-22.52-21.26-35.26
          c-8.73-18.37-41.58-19.81-30.8,11.39L708.57,831.31z"
          data-ref="mao"
          :style="`fill: ${drawingColors.mao}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mao_x5F_dta">
        <path
          data-ref="mao"
          class="st1" d="M1109.27,1080l-21.23-155.1c0,0-5.8-24.9,16.23-30.42c14.68-3.68,60.23-20.34,89-31.06
          c15.83-5.9,29.02-17.3,37.13-32.12l40.13-72.48c10.78-31.21-22.07-29.76-30.8-11.39c-6.05,12.74-13.12,20.95-21.26,35.26
          c-10.53,18.5-12.92,25.17-32.08,34.44l-28.25,11.32c-11.27,5.45-23.88,7.49-36.29,5.87l-43.46-5.67c-2.49-0.32-3.13-3.62-0.95-4.86
          l35.7-22.67c17.55-11.92,10.67-47.65-20.32-31.73c0,0-49.02,22.13-68.63,42.5c-5.9,6.13-11.92,12.13-12.63,20.62l-7,84.28
          l-3.02,163.21H1109.27z"
          :style="`fill: ${drawingColors.mao}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="nuvem_x5F_esq">
        <path class="st1" d="M638.71,812.82c-0.54-12.49-8.05-23.2-18.78-28.26c0.16-1.56,0.28-3.14,0.28-4.78
          c0-24.41-19.82-44.26-44.26-44.26c-15.99,0-29.95,8.51-37.72,21.23c-0.99-0.46-1.97-0.84-2.99-1.19
          c-7.29-17.02-24.18-28.95-43.84-28.95c-17.25,0-32.3,9.17-40.67,22.87c-3.73-1.77-7.91-2.77-12.31-2.77
          c-15.93,0-28.86,12.93-28.86,28.83c0,1.03,0.06,2.04,0.16,3.03c-12.83,5.09-21.95,17.5-22.29,32.07c0,0.26-0.06,0.52-0.06,0.79
          c0,0.47,0.1,0.92,0.12,1.38H638.71z"
          data-ref="nuvem_x5F_esq"
          :style="`fill: ${drawingColors.nuvem_x5F_esq}!important;`"
          @click="changeColor($event)"
          />
      </g>
      <g id="parede">
        <path class="st1" d="M1236.12,461.61l59.75-31.67l36.59,109.34l-65.52,8.73C1266.95,548.02,1256.1,511.83,1236.12,461.61z"
          data-ref="parede"
          :style="`fill: ${drawingColors.parede}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="porta">
        <polygon class="st1" points="1249.17,496.05 1292.56,480.21 1298.97,499.91 1255.42,514.39"
          data-ref="porta"
          :style="`fill: ${drawingColors.porta}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="porta_x5F_caix">
        <polygon class="st1" points="1273.95,491.12 1290.15,485.49 1293.92,497.22 1277.72,502.6 1275.77,496.64"
          data-ref="porta_x5F_caix"
          :style="`fill: ${drawingColors.porta_x5F_caix}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="Layer_78">
        <line class="st1" x1="1275.84" y1="496.86" x2="1291.91" y2="491.36"/>
        <line class="st1" x1="1281.92" y1="488.35" x2="1285.67" y2="499.96"/>
      </g>
      <g id="janela_x5F_01">
        <polygon class="st1" points="1255.42,459.77 1280.86,447.55 1290.15,472.68 1263.79,482.11"
          data-ref="janela_x5F_01"
          :style="`fill: ${drawingColors.janela_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="janela_x5F_01_x5F_caix">
        <line class="st1" x1="1259.49" y1="470.64" x2="1285.49" y2="460.08"/>
        <line class="st1" x1="1267.77" y1="453.84" x2="1276.4" y2="477.6"/>
      </g>
      <g id="janela_x5F_02">
        <polygon class="st1" points="1275.27,516.97 1301.54,508.94 1309.68,533.9 1282,538.46"
          data-ref="janela_x5F_02"
          :style="`fill: ${drawingColors.janela_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="janela_x5F_02_x5F_caix">
        <line class="st1" x1="1288.46" y1="512.94" x2="1295.93" y2="536.17"/>
        <line class="st1" x1="1278.64" y1="527.72" x2="1305.54" y2="521.19"/>
      </g>
      <g id="copa_x5F_01">
        <polygon class="st1" points="596.58,692.92 663.7,700.48 649.14,651.19"
          data-ref="copa_triangle"
          :style="`fill: ${drawingColors.copa_triangle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_01">
        <polygon class="st1" points="636.7,667.34 635.97,668.33 642.4,673.13 635.95,675.04 628.92,671.21 628.33,672.29 634.29,675.53
          628.93,677.11 629.28,678.29 643.63,674.05 647.79,677.15 630.7,682.2 624.33,678.73 623.75,679.81 629.04,682.69 624.06,684.16
          624.76,686.52 633.58,683.91 629.22,691.93 630.3,692.51 635.25,683.42 643.2,681.07 636.13,694.06 637.21,694.65 642.41,685.09
          647.61,691.26 648.55,690.47 643.04,683.93 644.86,680.58 695.69,665.57 695,663.21 649.26,676.72"
          data-ref="tronco_triangle"
          :style="`fill: ${drawingColors.tronco_triangle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_02">
        <polygon class="st1" points="546.19,652.9 628.28,675.84 620.07,611.49"
          data-ref="copa_triangle"
          :style="`fill: ${drawingColors.copa_triangle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_02">
        <polygon class="st1" points="601.32,629.11 600.21,630.19 607.26,637.47 598.84,638.54 590.85,632.36 589.9,633.58 596.67,638.82
          589.67,639.71 589.87,641.25 608.6,638.86 613.16,643.57 590.86,646.41 583.61,640.81 582.67,642.04 588.68,646.69 582.19,647.52
          582.58,650.59 594.09,649.12 587.05,658.23 588.27,659.18 596.27,648.84 606.66,647.52 595.23,662.28 596.45,663.24 604.86,652.37
          610.1,661.1 611.43,660.31 605.88,651.05 608.83,647.24 675.18,638.78 674.79,635.7 615.09,643.32"
          data-ref="tronco_triangle"
          :style="`fill: ${drawingColors.tronco_triangle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_03">
        <polygon class="st1" points="578.42,614.08 641.86,637.23 639.32,585.89"
          data-ref="copa_triangle"
          :style="`fill: ${drawingColors.copa_triangle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_03">
        <polygon class="st1" points="623.43,598.67 622.48,599.45 627.61,605.63 620.89,605.97 614.96,600.59 614.13,601.5 619.16,606.05
          613.58,606.33 613.64,607.55 628.58,606.81 631.9,610.81 614.1,611.69 608.72,606.82 607.9,607.73 612.37,611.78 607.19,612.04
          607.31,614.49 616.49,614.03 610.37,620.79 611.28,621.62 618.23,613.95 626.51,613.54 616.59,624.5 617.49,625.33 624.8,617.25
          628.4,624.48 629.5,623.93 625.69,616.28 628.25,613.45 681.18,610.83 681.06,608.38 633.43,610.74"
          data-ref="tronco_triangle"
          :style="`fill: ${drawingColors.tronco_triangle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_04">
        <path class="st1" d="M621.74,520.91c21.07,2.59,36.08,21.77,33.49,42.86c-2.59,21.08-21.79,36.06-42.86,33.47
          c-21.07-2.59-36.08-21.77-33.49-42.85C581.47,533.31,600.67,518.32,621.74,520.91z"
          data-ref="copa_circle"
          :style="`fill: ${drawingColors.copa_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_04">
        <polygon class="st1" points="635.18,537.39 632.49,537.06 631.05,548.67 621.59,536.55 619.46,538.2 624.28,544.38 614.28,543.15
          613.95,545.84 626.57,547.39 626.58,547.32 636.86,560.48 614.74,557.77 605.64,546.12 603.51,547.79 610.95,557.3 600.96,556.08
          600.46,560.11 616.23,562.04 601.4,573.64 603.06,575.77 612.26,568.57 611.11,577.96 613.8,578.29 615.28,566.23 615.27,566.22
          620.02,562.51 647.9,565.93 640.65,571.6 640.66,571.52 623.56,569.42 623.23,572.11 637.73,573.89 626.42,582.73 628.09,584.86
          651.69,566.4 683.2,570.27 683.69,566.24 640.65,560.95 633.41,551.68"
          data-ref="tronco_circle"
          :style="`fill: ${drawingColors.tronco_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_05">
        <path class="st1" d="M552.15,541.63c24.16,6.02,48.61-8.68,54.63-32.83c6.02-24.16-8.68-48.61-32.84-54.63
          c-24.15-6.02-48.61,8.68-54.63,32.84C513.28,511.16,528,535.61,552.15,541.63z"
          data-ref="copa_circle"
          :style="`fill: ${drawingColors.copa_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_05">
        <polygon class="st1" points="580.38,471.08 577.9,472.13 584.73,488.35 570.42,484.78 561.39,469.74 559.08,471.12 566.74,483.87
          554.85,480.91 554.2,483.52 586.02,491.44 590.44,501.93 552.55,492.49 544.36,478.87 542.06,480.26 548.86,491.58 537.83,488.83
          536.53,494.05 556.08,498.92 538.95,509.22 540.34,511.53 559.78,499.84 577.42,504.23 549.64,520.94 551.02,523.24 571.47,510.95
          574.45,528.38 577.1,527.94 573.95,509.46 581.11,505.15 693.82,533.23 695.12,528.01 593.71,502.75"
          data-ref="tronco_circle"
          :style="`fill: ${drawingColors.tronco_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_06">
        <path class="st1" d="M629.37,438.84c19.6,8.15,28.9,30.66,20.75,50.27c-8.15,19.61-30.67,28.89-50.27,20.73
          c-19.61-8.15-28.9-30.66-20.75-50.27C587.25,439.96,609.76,430.69,629.37,438.84z"
          data-ref="copa_circle"
          :style="`fill: ${drawingColors.copa_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_06">
        <polygon class="st1" points="637.89,458.32 635.39,457.28 630.89,468.07 625.03,453.86 622.53,454.88 625.52,462.13 616.21,458.26
          615.18,460.76 626.91,465.64 626.94,465.57 633.31,481.01 612.73,472.46 607.1,458.79 604.59,459.83 609.2,470.99 599.91,467.13
          598.35,470.88 613.02,476.98 595.62,484.16 596.65,486.66 607.44,482.2 603.81,490.94 606.31,491.97 610.98,480.75 610.97,480.75
          616.54,478.44 642.48,489.23 633.98,492.74 634.01,492.67 618.1,486.05 617.06,488.56 630.54,494.16 617.28,499.64 618.32,502.14
          646.01,490.7 675.32,502.88 676.88,499.13 636.84,482.48 632.35,471.61"
          data-ref="tronco_circle"
          :style="`fill: ${drawingColors.tronco_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_07">
        <path class="st1" d="M1143.42,232.8c-9.29,15.82-3.99,36.16,11.82,45.45c15.82,9.29,36.16,3.99,45.45-11.83
          c9.28-15.81,3.99-36.17-11.83-45.45C1173.05,211.69,1152.7,216.99,1143.42,232.8z"
          data-ref="copa_circle"
          :style="`fill: ${drawingColors.copa_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_07">
        <polygon class="st1" points="1187.4,267.46 1187.17,265.48 1174.29,266.93 1179.79,257.56 1192.3,254.31 1191.81,252.39
          1181.2,255.15 1185.77,247.37 1184.06,246.36 1171.83,267.2 1163.5,268.14 1178.06,243.33 1189.4,240.38 1188.9,238.46
          1179.48,240.91 1183.72,233.69 1180.3,231.68 1172.79,244.49 1169.08,230.23 1167.16,230.73 1171.36,246.91 1164.58,258.46
          1158.57,235.34 1156.65,235.84 1161.08,252.85 1148.14,251.32 1147.9,253.29 1161.61,254.91 1163.17,260.87 1119.84,334.68
          1123.26,336.69 1162.24,270.28"
          data-ref="tronco_circle"
          :style="`fill: ${drawingColors.tronco_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_08">
        <path class="st1" d="M1218.17,326.16c-10.65,14.13-30.75,16.96-44.88,6.3c-14.13-10.65-16.94-30.75-6.29-44.88
          c10.65-14.13,30.75-16.96,44.88-6.3C1226.01,291.94,1228.82,312.03,1218.17,326.16z"
          data-ref="copa_circle"
          :style="`fill: ${drawingColors.copa_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tonco_x5F_08">
        <polygon class="st1" points="1200.67,328.99 1202.03,327.19 1194.25,321.31 1206.95,319.54 1206.64,317.31 1200.17,318.21
          1205.22,311.51 1203.43,310.15 1197.05,318.61 1197.09,318.64 1183.31,320.58 1194.49,305.75 1206.69,304.04 1206.37,301.8
          1196.41,303.21 1201.45,296.51 1198.75,294.47 1190.78,305.04 1188.6,289.51 1186.37,289.82 1187.72,299.46 1181.43,294.71
          1180.07,296.51 1188.16,302.61 1188.17,302.6 1188.87,307.58 1174.77,326.27 1173.71,318.68 1173.76,318.72 1182.4,307.26
          1180.6,305.9 1173.27,315.62 1171.61,303.78 1169.38,304.09 1172.86,328.82 1156.93,349.94 1159.64,351.97 1181.39,323.12
          1191.1,321.76"
          data-ref="tronco_circle"
          :style="`fill: ${drawingColors.tronco_circle}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="eolico">
        <path class="st6" d="M657.82,409.71c-2.53,0.42-4.74,1.12-6.24,1.67c-0.16-0.37-0.43-0.7-0.8-0.94c-0.58-0.37-1.28-0.42-1.87-0.19
          c-0.56-1.49-1.49-3.66-2.84-5.88c-2.45-4.03-5.37-6.73-5.37-6.73s1.05,3.84,3.5,7.86c1.36,2.24,2.87,4.07,3.94,5.25
          c-0.1,0.1-0.18,0.21-0.26,0.33c-0.51,0.79-0.42,1.8,0.16,2.48c-1.15,1.1-2.79,2.81-4.32,4.95c-2.74,3.84-4.08,7.59-4.08,7.59
          s3.11-2.48,5.85-6.31c1.52-2.12,2.6-4.22,3.27-5.67c0.76,0.35,1.65,0.22,2.27-0.33l49.69,32.27l0.92-1.44l-49.99-31.81
          c0.05-0.16,0.08-0.33,0.08-0.49c1.59,0.03,3.91-0.03,6.43-0.45c4.65-0.78,8.24-2.49,8.24-2.49S662.47,408.94,657.82,409.71z"/>
        <path class="st6" d="M676.42,381.97c-2.56,0.21-4.82,0.73-6.36,1.15c-0.13-0.38-0.37-0.74-0.72-1c-0.55-0.42-1.24-0.52-1.85-0.35
          c-0.43-1.54-1.18-3.77-2.35-6.1c-2.11-4.22-4.8-7.15-4.8-7.15s0.73,3.91,2.84,8.13c1.17,2.35,2.52,4.29,3.49,5.56
          c-0.1,0.09-0.2,0.19-0.29,0.3c-0.57,0.75-0.56,1.76-0.05,2.49c-1.24,1-3.02,2.57-4.71,4.57c-3.05,3.6-4.69,7.22-4.69,7.22
          s3.31-2.21,6.36-5.81c1.69-1.99,2.94-3.99,3.72-5.38c0.73,0.42,1.63,0.35,2.29-0.14l46.86,36.26l1.04-1.36l-47.19-35.83
          c0.06-0.16,0.1-0.32,0.12-0.48c1.59,0.16,3.9,0.29,6.44,0.08c4.7-0.39,8.42-1.8,8.42-1.8S681.12,381.58,676.42,381.97z"/>
        <path class="st6" d="M692.92,357.28c-2.56,0.06-4.85,0.44-6.41,0.77c-0.11-0.39-0.33-0.76-0.65-1.04c-0.52-0.45-1.2-0.6-1.83-0.46
          c-0.34-1.56-0.95-3.83-1.98-6.23c-1.85-4.34-4.36-7.42-4.36-7.42s0.5,3.95,2.35,8.28c1.03,2.41,2.26,4.43,3.16,5.75
          c-0.11,0.08-0.21,0.18-0.3,0.29c-0.61,0.71-0.67,1.72-0.2,2.48c-1.3,0.92-3.16,2.39-4.97,4.28c-3.26,3.41-5.11,6.93-5.11,6.93
          s3.43-2.01,6.69-5.42c1.8-1.89,3.17-3.8,4.04-5.15c0.7,0.46,1.6,0.45,2.29,0l44.62,38.99l1.12-1.3l-44.98-38.57
          c0.07-0.16,0.12-0.31,0.15-0.47c1.57,0.26,3.87,0.52,6.43,0.46c4.72-0.11,8.51-1.29,8.51-1.29S697.63,357.17,692.92,357.28z"/>
      </g>
      <g id="arbusto_x5F_01">
        <path class="st1" d="M698.48,450.32l10.93-17.09c0,0-3.61-4.25-6.87-0.34c0,0-1.03,1.57-0.84,3.43c0,0-2.94-2.2-4.78-0.29
          c-2.08,2.14-1.89,4.85,2.01,6.93c0,0-1.4,0.67-2.07,2.82C696.18,447.94,698.48,450.32,698.48,450.32z"
          data-ref="arbusto_x5F_01"
          :style="`fill: ${drawingColors.arbusto_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="arbusto_x5F_02">
        <path class="st1" d="M711.08,428.81l12.3-16.13c0,0-3.24-4.53-6.82-0.9c0,0-1.16,1.48-1.12,3.35c0,0-2.74-2.43-4.74-0.69
          c-2.25,1.96-2.29,4.67,1.43,7.07c0,0-1.45,0.55-2.3,2.65C708.99,426.24,711.08,428.81,711.08,428.81z"
          data-ref="arbusto_x5F_02"
          :style="`fill: ${drawingColors.arbusto_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="arbusto_x5F_03">
        <path class="st1" d="M726.38,407.9l13.24-15.37c0,0-2.97-4.71-6.75-1.3c0,0-1.25,1.41-1.32,3.28c0,0-2.59-2.59-4.69-0.97
          c-2.36,1.83-2.56,4.53,1.01,7.14c0,0-1.48,0.47-2.45,2.5C724.44,405.22,726.38,407.9,726.38,407.9z"
          data-ref="arbusto_x5F_03"
          :style="`fill: ${drawingColors.arbusto_x5F_03}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="montanha_x5F_03">
        <path class="st2" d="M854.22,310.46l-107.02-64.67l7.11,128.64C783.02,347.22,816.86,325.37,854.22,310.46z"
          data-ref="montanha_x5F_03"
          :style="`fill: ${drawingColors.montanha_x5F_03}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="montanha_x5F_01">
        <path class="st2" d="M869.71,304.74l-55.18-56.82l-4.43,84.32C828.8,321.11,848.75,311.84,869.71,304.74z"
          data-ref="montanha_x5F_01"
          :style="`fill: ${drawingColors.montanha_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="montanha_x5F_02">
        <path class="st2" d="M860.38,308.09l-29.39-11.66l-10.32,8.24l-47.42-17.8v47.36l-27.18,4.69v45.06
          C783.55,347.27,816.89,324.38,860.38,308.09z"
          data-ref="montanha_x5F_02"
          :style="`fill: ${drawingColors.montanha_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="gota">
        <g>
          <path class="st2" d="M1072.89,235.98v0.22c53.64,58.7,53.37,153.09-1.18,211.3c-55.34,58.71-144.33,58.71-199.55,0
            c-54.56-58.21-54.81-152.6-1.18-211.3v-0.22l100.7-107.99L1072.89,235.98z"
            data-ref="gota"
            :style="`fill: ${drawingColors.gota}!important;`"
            @click="changeColor($event)"
            />
        </g>
      </g>
      <g id="nuvem_x5F_topo">
        <path class="st1" d="M893.27,232.63c-0.31-7.22-4.65-13.4-10.85-16.32c0.09-0.9,0.16-1.82,0.16-2.76
          c0-14.1-11.45-25.57-25.57-25.57c-9.24,0-17.3,4.91-21.79,12.26c-0.57-0.27-1.14-0.49-1.73-0.69
          c-4.21-9.83-13.97-16.72-25.33-16.72c-9.97,0-18.66,5.3-23.49,13.21c-2.16-1.02-4.57-1.6-7.11-1.6c-9.2,0-16.67,7.47-16.67,16.65
          c0,0.6,0.03,1.18,0.09,1.75c-7.41,2.94-12.68,10.11-12.88,18.53c0,0.15-0.03,0.3-0.03,0.46c0,0.27,0.06,0.53,0.07,0.79H893.27z"
            data-ref="nuvem_x5F_topo"
            :style="`fill: ${drawingColors.nuvem_x5F_topo}!important;`"
            @click="changeColor($event)"
        />
      </g>
      <g id="neve">
        <polyline class="st1" points="748.64,271.7 755.83,288.16 755.85,271.7 767.69,280.78 763.72,262.92 782.26,278.14 776.84,263.7
          747.21,245.79 748.64,271.7"/>
        <polyline class="st1" points="773.25,303.24 777.24,309.76 778.92,303.12 792.18,311.3 786.46,297.69 797.63,303.83 796.19,295.48
          773.25,286.87 773.25,303.24"/>
        <polyline class="st1" points="813.17,273.57 820.67,294.5 820.67,276.02 825.6,280.27 823.38,270.41 824.02,262.15 827.83,268.51
          828.58,262.4 814.52,247.92 813.17,273.57"/>
      </g>
      <g id="bicicleta">
        <g>
          <path class="st6" d="M1243.62,421.06c-0.29,0.19-0.37,0.56-0.18,0.85l2.01,3.09c0.19,0.28,0.56,0.36,0.85,0.18
            c0.28-0.18,0.37-0.56,0.18-0.85l-2.01-3.09C1244.29,420.96,1243.91,420.88,1243.62,421.06z"/>
          <path class="st6" d="M1248.88,432.49c-0.7,0.46-0.9,1.4-0.44,2.1c0.45,0.7,1.39,0.89,2.09,0.44c0.7-0.46,0.9-1.39,0.45-2.09
            C1250.51,432.23,1249.57,432.03,1248.88,432.49z"/>
          <path class="st6" d="M1243.84,424.73l0.36,0.56c-2.33,1.52-3.83,3.84-4.38,6.37c-0.53,2.52-0.1,5.26,1.42,7.59
            c1.52,2.34,3.84,3.85,6.37,4.38c2.53,0.54,5.26,0.12,7.6-1.41c2.34-1.52,3.84-3.84,4.38-6.37c0.54-2.53,0.11-5.25-1.41-7.59
            c-1.52-2.34-3.85-3.84-6.37-4.38c-2.53-0.54-5.25-0.1-7.6,1.41L1243.84,424.73l-0.37-0.57c5.3-3.44,12.39-1.93,15.83,3.37
            c3.44,5.3,1.93,12.38-3.36,15.83c-5.3,3.44-12.39,1.93-15.83-3.36c-3.44-5.3-1.94-12.38,3.36-15.83L1243.84,424.73z"/>
          <path class="st6" d="M1249.93,433.9l0.57-0.36l-4.34-6.72l-3.73-5.77l6.58-0.45l6.58-0.46l-2.89,6.22l-3.38,7.25L1249.93,433.9
            l0.57-0.36L1249.93,433.9l0.61,0.29l3.37-7.26l3.38-7.25c0.1-0.22,0.08-0.47-0.06-0.66c-0.14-0.2-0.37-0.31-0.6-0.29l-7.71,0.53
            l-7.71,0.54c-0.24,0.01-0.45,0.15-0.55,0.36c-0.11,0.22-0.09,0.47,0.04,0.67l4.34,6.71l4.34,6.72c0.13,0.21,0.37,0.32,0.61,0.3
            c0.25-0.02,0.47-0.16,0.57-0.38L1249.93,433.9z"/>
          <path class="st6" d="M1247.39,405.68l-3.38,7.26l-3.38,7.25c-0.1,0.22-0.08,0.47,0.06,0.66c0.14,0.2,0.36,0.31,0.6,0.29l7.72-0.52
            l0.86-0.06l0.04-0.01l0.12-0.01l6.7-0.47c0.17-0.01,0.33-0.1,0.44-0.21l4.8-0.46c0.37-0.03,0.64-0.36,0.6-0.73
            c-0.03-0.36-0.36-0.64-0.73-0.61l-4.93,0.47l-4.01-6.22l-4.34-6.72c-0.13-0.21-0.37-0.33-0.62-0.31
            C1247.71,405.31,1247.49,405.47,1247.39,405.68z M1248.1,407.35l3.68,5.7l3.64,5.63l-5.5,0.52l-0.99,0.07l-6.58,0.45l2.89-6.22
            L1248.1,407.35z"/>
          <path class="st6" d="M1233.33,407.71l23.02-3.69c0.2-0.04,0.4,0.02,0.55,0.15l2.68,2.37c0.27,0.25,0.31,0.67,0.06,0.94
            c-0.25,0.28-0.67,0.31-0.95,0.07l-2.44-2.16l-22.7,3.66c-0.37,0.05-0.71-0.19-0.77-0.55
            C1232.72,408.12,1232.96,407.78,1233.33,407.71z"/>
          <path class="st6" d="M1232.39,407.11c-0.7,0.46-0.9,1.39-0.45,2.09c0.45,0.7,1.39,0.9,2.09,0.44c0.7-0.46,0.9-1.39,0.44-2.09
            C1234.02,406.86,1233.09,406.66,1232.39,407.11z"/>
          <path class="st6" d="M1227.35,399.35l0.37,0.56c-2.34,1.53-3.84,3.84-4.38,6.37c-0.53,2.54-0.1,5.26,1.41,7.6
            c1.52,2.34,3.84,3.84,6.37,4.38c2.53,0.54,5.26,0.12,7.6-1.4c2.34-1.52,3.84-3.83,4.37-6.37c0.54-2.53,0.11-5.26-1.41-7.59
            c-1.52-2.34-3.84-3.85-6.38-4.38c-2.53-0.54-5.25-0.11-7.59,1.41L1227.35,399.35l-0.37-0.57c5.3-3.43,12.38-1.93,15.83,3.37
            c3.44,5.3,1.94,12.38-3.36,15.82c-5.29,3.44-12.38,1.93-15.82-3.36c-3.44-5.3-1.94-12.39,3.36-15.83L1227.35,399.35z"/>
          <path class="st6" d="M1259.82,414.8c-0.33,0.22-0.43,0.67-0.21,1.01l2.85,4.39c0.21,0.33,0.66,0.42,1,0.21
            c0.33-0.22,0.43-0.66,0.22-1l-2.85-4.39C1260.6,414.68,1260.16,414.59,1259.82,414.8z"/>
          <path class="st6" d="M1240.15,417.8c-1.36,0.87-1.75,2.69-0.86,4.05c0.88,1.35,2.7,1.75,4.05,0.86c1.36-0.88,1.74-2.7,0.86-4.06
            C1243.32,417.3,1241.51,416.92,1240.15,417.8z"/>
          <path class="st6" d="M1237.13,415.92c-0.28,0.18-0.36,0.57-0.18,0.85l2.01,3.09c0.19,0.29,0.57,0.36,0.85,0.18
            c0.28-0.18,0.37-0.56,0.18-0.85l-2-3.09C1237.79,415.82,1237.41,415.74,1237.13,415.92z"/>
          <path class="st6" d="M1250.07,404.42c-0.06-0.35-0.37-0.57-0.71-0.52l-3.64,0.58c-0.33,0.06-0.56,0.37-0.5,0.7
            c0.05,0.34,0.37,0.56,0.71,0.52l3.63-0.59C1249.9,405.07,1250.12,404.75,1250.07,404.42z"/>
        </g>
      </g>
      <g id="chamine">
        <g>
          <path class="st7" d="M1373.13,516.78c-2.7-0.25-3.13-2.46-6.06-5.42c-2.53-2.55-6.86-2.16-8.94-1.41
            c-0.9,0.33-1.76,1.37-2.37,1.91c0,0-1.41-0.05-4.78,1.08c-0.16,0.05-0.3,0.1-0.44,0.15l0.3,0.89c0.14-0.05,0.28-0.09,0.44-0.15
            c3.58-1.2,5.62-0.98,6.46-0.81c5.1,1.03,6.98,3.17,10.9,4.22c-4.25,0.2-6.04-2.97-13.93-3.22
            C1357.14,527.28,1371.28,519.72,1373.13,516.78z"
            data-ref="chamine"
            :style="`fill: ${drawingColors.chamine}!important;`"
            @click="changeColor($event)"
          />
          <path class="st7" d="M1366.55,500.09c-1.37,1.25-0.69,2.63-1.07,5.45c-0.59,4.32-6.23,5.3-7.75,4.51c4.21-3.57,4.2-5.83,6.19-8.37
            c-2.4,1.64-2.59,4.27-6.78,7.65C1353,501.11,1364.33,499.25,1366.55,500.09z"
            data-ref="chamine"
            :style="`fill: ${drawingColors.chamine}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="chamine_x5F_01">
        <polygon class="st8" points="1339.75,524.77 1352.97,520.34 1348.4,506.69 1343.7,508.26"
          data-ref="chamine_x5F_01"
          :style="`fill: ${drawingColors.chamine_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="telhado">
        <polygon class="st1" points="1293.69,423.41 1352.39,471.89 1334.7,545.94"
          data-ref="telhado"
          :style="`fill: ${drawingColors.telhado}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="painel">
        <path class="st7" d="M1313.03,449.61l10.36-3.47l-0.95-2.85l-10.78,3.61C1312.11,447.8,1312.57,448.7,1313.03,449.61z"
          data-ref="painel"
          :style="`fill: ${drawingColors.painel}!important;`"
          @click="changeColor($event)"
        />

          <rect
            x="1320.43" y="425.01" transform="matrix(0.9483 -0.3174 0.3174 0.9483 -72.9381 442.4895)" class="st7" width="2.54" height="40.22"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
      </g>
      <g id="painel_x5F_01">
        <path class="st9" d="M1329.29,463.79c-4.25-12.71-8.51-25.43-12.76-38.14c5.94,1.18,11.89,2.37,17.83,3.56c2.34,7,4.69,14,7.03,21
          C1337.35,454.74,1333.32,459.26,1329.29,463.79z"
          data-ref="painel"
          :style="`fill: ${drawingColors.painel}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="celulas">
        <path class="st7" d="M1333.83,430.41c-1.1-0.15-2.2-0.31-3.3-0.46c0.72,2.16,1.44,4.32,2.17,6.47c1.01-0.12,2.02-0.25,3.03-0.36
          C1335.09,434.17,1334.46,432.29,1333.83,430.41z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1329.75,429.83c-1.1-0.15-2.2-0.31-3.3-0.47c0.84,2.5,1.68,5.01,2.51,7.51c1.01-0.12,2.01-0.24,3.03-0.36
          C1331.24,434.28,1330.5,432.05,1329.75,429.83z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1325.67,429.25c-1.1-0.15-2.2-0.31-3.3-0.47c0.95,2.85,1.91,5.69,2.86,8.54c1-0.12,2.01-0.24,3.02-0.37
          C1327.39,434.39,1326.53,431.82,1325.67,429.25z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1321.59,428.67c-1.1-0.16-2.2-0.31-3.3-0.47c1.07,3.19,2.13,6.38,3.2,9.57c1.01-0.12,2.02-0.25,3.03-0.37
          C1323.54,434.5,1322.56,431.59,1321.59,428.67z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1336.1,437.17c-0.99,0.18-1.98,0.35-2.97,0.53c0.72,2.16,1.45,4.32,2.17,6.48c0.9-0.46,1.8-0.91,2.69-1.37
          C1337.36,440.94,1336.72,439.05,1336.1,437.17z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1332.43,437.83c-0.99,0.17-1.98,0.36-2.97,0.53c0.84,2.51,1.68,5.01,2.51,7.51c0.9-0.45,1.79-0.91,2.69-1.37
          C1333.92,442.28,1333.17,440.05,1332.43,437.83z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1328.76,438.48c-0.99,0.18-1.98,0.35-2.97,0.53c0.95,2.84,1.9,5.69,2.86,8.54c0.89-0.45,1.79-0.91,2.69-1.36
          C1330.48,443.62,1329.62,441.06,1328.76,438.48z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1325.09,439.14c-0.99,0.17-1.98,0.35-2.97,0.53c1.07,3.19,2.13,6.38,3.2,9.57c0.9-0.45,1.8-0.91,2.69-1.36
          C1327.04,444.96,1326.07,442.05,1325.09,439.14z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1338.36,443.93c-0.87,0.51-1.75,1.02-2.64,1.53c0.72,2.16,1.44,4.32,2.17,6.48c0.79-0.79,1.57-1.58,2.36-2.36
          C1339.62,447.69,1338.99,445.81,1338.36,443.93z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1335.11,445.83c-0.88,0.51-1.76,1.02-2.64,1.53c0.84,2.5,1.67,5,2.51,7.5c0.79-0.79,1.57-1.57,2.36-2.36
          C1336.59,450.27,1335.85,448.05,1335.11,445.83z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1331.85,447.72c-0.88,0.51-1.76,1.02-2.63,1.53c0.95,2.85,1.91,5.69,2.86,8.54c0.78-0.79,1.57-1.58,2.35-2.37
          C1333.57,452.85,1332.71,450.28,1331.85,447.72z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1328.59,449.61c-0.88,0.51-1.76,1.02-2.64,1.53c1.07,3.19,2.13,6.38,3.2,9.57c0.79-0.79,1.57-1.58,2.36-2.36
          C1330.54,455.43,1329.57,452.52,1328.59,449.61z"
          data-ref="celulas"
          :style="`fill: ${drawingColors.celulas}!important;`"
          @click="changeColor($event)"
          />
      </g>
      </svg>
  </div>
  <div class="o-Drawing__colors">
    <div class="o-Colors">
      <a
        v-for="(color, index) in COLORS"
        :key="index"
        href="#"
        class="o-Color"
        :style="`background-color:${color};`"
        @click.prevent="selectColor(color)"
      />
    </div>
    <button
      class="o-Drawing__action"
      @click.prevent="saveDrawing"
    >
      <span>
        Guardar
      </span>
    </button>
    <button
      class="o-Drawing__action"
      @click.prevent="clearDrawing"
    >
      <span>
        Limpar
      </span>
    </button>
  </div>
</div>
</template>

<script>
import COLORS from '@/enums';

const { saveSvgAsPng } = require('save-svg-as-png');

export default {
  name: 'Aqua',
  data: () => ({
    COLORS,
    selectedColor: COLORS.WHITE,
    drawingColors: {},
  }),
  mounted() {
    this.drawingColors = JSON.parse(this.$store.state.aquaDrawing);
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
    },
    changeColor(e) {
      this.$set(this.drawingColors, e.target.getAttribute('data-ref'), this.selectedColor);
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify(this.drawingColors),
      });
    },
    saveDrawing() {
      try {
        saveSvgAsPng(document.getElementById('drawing'), 'drawing.png');
      } catch (err) {
        console.log('Error trying to download image');
      }
    },
    clearDrawing() {
      Object.keys(this.drawingColors).forEach((key) => {
        this.$set(this.drawingColors, key, COLORS.WHITE);
      });
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify({}),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#FFFFFF;stroke:#221F1F;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st3{opacity:0.3;}
.st4{fill:#221F1F;}
.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#221F1F;}
.st6{fill:#A7A8AB;}
.st7{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st8{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

  .st9{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#221F1F;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
