export const AQUA_QUESTIONS = [
  {
    question: 'Enquanto lavamos os dentes, devemos...',
    answers: [
      {
        answer: 'Fechar a torneira.',
        right: true,
      },
      {
        answer: 'Deixar a torneira aberta.',
        right: false,
      },
      {
        answer: 'Regar as plantas.',
        right: false,
      },
    ],
  },
  {
    question: 'Quando lavamos a roupa na máquina, devemos...',
    answers: [
      {
        answer: 'Lavar apenas a nossa t-shirt preferida que usámos durante o dia.',
        right: false,
      },
      {
        answer: 'Lavar apenas a nossa t-shirt e as calças preferidas que usámos durante o dia.',
        right: false,
      },
      {
        answer: 'Colocar o máximo de peças até encher a máquina de roupa.',
        right: true,
      },
    ],
  },
  {
    question: 'Para reduzir o consumo de água devemos tomar banho...',
    answers: [
      {
        answer: 'De imersão, na banheira.',
        right: false,
      },
      {
        answer: 'De chuveiro, no mínimo tempo possível.',
        right: true,
      },
    ],
  },
  {
    question: 'Na cozinha, ao lavarmos os legumes, devemos...',
    answers: [
      {
        answer: 'Lavar debaixo de uma torneira a correr água.',
        right: false,
      },
      {
        answer: 'Utilizar um recipiente debaixo dos legumes para reaproveitar a água.',
        right: true,
      },
    ],
  },
  {
    question: 'Ao lavar o carro, devemos...',
    answers: [
      {
        answer: 'Usar uma mangueira com água a correr.',
        right: false,
      },
      {
        answer: 'Usar um balde com água.',
        right: true,
      },
      {
        answer: 'Recorrer à lavagem automática.',
        right: false,
      },
    ],
  },
  {
    question: 'Quando tomamos banho de chuveiro, devemos...',
    answers: [
      {
        answer: 'Fechar a água enquanto colocamos o sabão.',
        right: true,
      },
      {
        answer: 'Brincar debaixo da água a correr o tempo que quisermos.',
        right: false,
      },
      {
        answer: 'Só fechar a água quando terminarmos o nosso banho.',
        right: false,
      },
    ],
  },
  {
    question: 'O que fazer quando temos uma torneira a pingar?',
    answers: [
      {
        answer: 'Confirmar que a torneira está bem fechada.',
        right: false,
      },
      {
        answer: 'Colocar um recipiente para aproveitar a água.',
        right: false,
      },
      {
        answer: 'Ambas as opções estão corretas.',
        right: true,
      },
    ],
  },
  {
    question: 'Na casa de banho, podemos poupar água nas descargas do autoclismo fazendo o seguinte:',
    answers: [
      {
        answer: 'Colocar um autoclismo que permita meia descarga.',
        right: false,
      },
      {
        answer: 'Colocar uma garrafa de água cheia dentro do depósito do autoclismo.',
        right: false,
      },
      {
        answer: 'Todas as opções estão corretas.',
        right: true,
      },
    ],
  },
  {
    question: 'Para poupar água, podemos dar banho ao nosso cão...',
    answers: [
      {
        answer: 'No exterior, em zona onde se possa aproveitar a água para regar algumas plantas.',
        right: true,
      },
      {
        answer: 'Na banheira da nossa casa.',
        right: false,
      },
    ],
  },
  {
    question: 'Verdadeiro ou falso: a máquina de lavar loiça é mais eficiente na poupança de água do que lavar à mão?',
    answers: [
      {
        answer: 'Verdadeiro',
        right: true,
      },
      {
        answer: 'Falso',
        right: false,
      },
    ],
  },
];

export const RECICLE_QUESTIONS = [
  {
    question: 'Que materiais devo colocar no ecoponto azul?',
    answers: [
      {
        answer: 'Papel e cartão',
        right: true,
      },
      {
        answer: 'Sacos e embalagens de plástico Garrafas de vidro',
        right: false,
      },
    ],
  },
  {
    question: 'A embalagem de plástico do gel de banho é reciclável?',
    answers: [
      {
        answer: 'Sim',
        right: true,
      },
      {
        answer: 'Não',
        right: false,
      },
    ],
  },
  {
    question: 'Quais são os benefícios da reciclagem?',
    answers: [
      {
        answer: 'Ambientais',
        right: false,
      },
      {
        answer: 'Financeiros',
        right: false,
      },
      {
        answer: 'Ambos',
        right: true,
      },
    ],
  },
  {
    question: 'As pilhas usadas são depositadas no...',
    answers: [
      {
        answer: 'Ecoponto amarelo',
        right: false,
      },
      {
        answer: 'Ecoponto verde',
        right: false,
      },
      {
        answer: 'Pilhão',
        right: true,
      },
    ],
  },
  {
    question: 'Quanto tempo leva uma garrafa de água PET a decompor-se?',
    answers: [
      {
        answer: '1 ano',
        right: false,
      },
      {
        answer: '20 anos',
        right: false,
      },
      {
        answer: 'Mais de 100 anos',
        right: true,
      },
    ],
  },
  {
    question: 'Quais dos seguintes materiais são recicláveis?',
    answers: [
      {
        answer: 'Uma casca de banana.',
        right: false,
      },
      {
        answer: 'Uma folha de papel.',
        right: true,
      },
      {
        answer: 'Uma lata de alumínio.',
        right: true,
      },
    ],
  },
  {
    question: 'Verdadeiro ou Falso? Para reduzirmos o consumo de garrafas de plástico PET, podemos utilizar uma garrafa reutilizável e beber água da torneira.',
    answers: [
      {
        answer: 'Verdadeiro',
        right: true,
      },
      {
        answer: 'Falso',
        right: false,
      },
    ],
  },
  {
    question: 'Como se chama o prémio dado às praias que apresentam todas as condições de qualidade, conforto e segurança?',
    answers: [
      {
        answer: 'Bandeira Azul',
        right: true,
      },
      {
        answer: 'Bandeira Amarela',
        right: false,
      },
      {
        answer: 'Bandeira Verde',
        right: false,
      },
    ],
  },
  {
    question: 'Quantas toneladas se consegue reciclar a partir de 1 tonelada de vidro?',
    answers: [
      {
        answer: '500kg',
        right: false,
      },
      {
        answer: '1 tonelada',
        right: true,
      },
      {
        answer: '2 toneladas',
        right: false,
      },
    ],
  },
  {
    question: 'Qual é o destino do lixo indiferenciado?',
    answers: [
      {
        answer: 'Mar',
        right: false,
      },
      {
        answer: 'Lua',
        right: false,
      },
      {
        answer: 'Aterro',
        right: true,
      },
    ],
  },
];

export const ENERGY_QUESTIONS = [
  {
    question: 'Destas opções qual é que não é uma fonte de energia renovável?',
    answers: [
      {
        answer: 'Energia das marés',
        right: false,
      },
      {
        answer: 'Gás natural',
        right: true,
      },
      {
        answer: 'Energia solar',
        right: false,
      },
    ],
  },
  {
    question: 'Para uma maior poupança energética, qual devemos utilizar?',
    answers: [
      {
        answer: 'Lâmpadas fluorescentes',
        right: false,
      },
      {
        answer: 'Lâmpadas de halogénio',
        right: false,
      },
      {
        answer: 'Lâmpadas LED',
        right: true,
      },
    ],
  },
  {
    question: 'O aquecimento global está relacionado principalmente com o aumento de que gás na atmosfera?',
    answers: [
      {
        answer: 'Dióxido de carbono',
        right: true,
      },
      {
        answer: 'Oxigénio',
        right: false,
      },
      {
        answer: 'Nitrogénio',
        right: false,
      },
    ],
  },
  {
    question: 'A energia em combustíveis fósseis é armazenada como:',
    answers: [
      {
        answer: 'Energia nuclear',
        right: false,
      },
      {
        answer: 'Energia elétrica',
        right: false,
      },
      {
        answer: 'Energia química',
        right: true,
      },
    ],
  },
  {
    question: 'A energia solar é transformada em eletrecidade através de:',
    answers: [
      {
        answer: 'Células fotovoltaicas',
        right: true,
      },
      {
        answer: 'Caixas',
        right: false,
      },
      {
        answer: 'No congelador',
        right: false,
      },
    ],
  },
  {
    question: 'Para uma redução das emissões de CO2 (dióxido de carbono) para a atmosfera, podemos:',
    answers: [
      {
        answer: 'Andar de bicicleta e a pé.',
        right: false,
      },
      {
        answer: 'Andar de transportes coletivos.',
        right: false,
      },
      {
        answer: 'Todas as opções acima podem ser adotadas.',
        right: true,
      },
    ],
  },
];

export const NATURE_QUESTIONS = [
  {
    question: 'Deveremos regar o nosso jardim...',
    answers: [
      {
        answer: 'À hora do almoço.',
        right: false,
      },
      {
        answer: 'A meio da tarde.',
        right: false,
      },
      {
        answer: 'Ao final do dia, depois das 20h.',
        right: true,
      },
    ],
  },
  {
    question: 'Para uma rega eficiente podemos...',
    answers: [
      {
        answer: 'Colocar um recipiente para recolher a água da chuva e utilizá-la para a rega de plantas.',
        right: true,
      },
      {
        answer: 'Regar todos os dias.',
        right: false,
      },
      {
        answer: 'Regar várias vezes ao dia em pouca quantidade.',
        right: false,
      },
    ],
  },
  {
    question: 'Ao escolher plantas locais (espécies endémicas) e que cresçam naturalmente no nosso jardim...',
    answers: [
      {
        answer: 'Gastamos mais água.',
        right: false,
      },
      {
        answer: 'Reduzimos o gasto de água.',
        right: true,
      },
    ],
  },
  {
    question: 'Verdadeiro ou Falso? Regar com um regador é mais eficiente do que usar a mangueira.',
    answers: [
      {
        answer: 'Verdadeiro',
        right: true,
      },
      {
        answer: 'Falso',
        right: false,
      },
    ],
  },
  {
    question: 'Qual destes insetos não é considerado benéfico para um jardim?',
    answers: [
      {
        answer: 'Joaninha',
        right: false,
      },
      {
        answer: 'Abelha',
        right: false,
      },
      {
        answer: 'Gafanhoto',
        right: true,
      },
    ],
  },
  {
    question: 'Qual destas opções é uma boa fonte de nitrogénio (fertilizante) para a terra?',
    answers: [
      {
        answer: 'Borra do café',
        right: true,
      },
      {
        answer: 'Açúcar',
        right: false,
      },
      {
        answer: 'Detergente',
        right: false,
      },
    ],
  },
  {
    question: 'O que é a compostagem?',
    answers: [
      {
        answer: 'Uma técnica de rega.',
        right: false,
      },
      {
        answer: 'Processo natural que consiste na decomposição biológica de resíduos orgânicos, do qual resulta um produto (composto) usado como adubo.',
        right: true,
      },
      {
        answer: 'Uma árvore.',
        right: false,
      },
    ],
  },
  {
    question: 'Qual das seguintes afirmações é incorreta?',
    answers: [
      {
        answer: 'As árvores impedem a erosão do solo.',
        right: false,
      },
      {
        answer: 'As árvores produzem boa parte do oxigénio da atmosfera e reciclam o dióxido de carbono.',
        right: false,
      },
      {
        answer: 'As árvores são agentes poluidores do ambiente.',
        right: true,
      },
    ],
  },
  {
    question: 'Qual das seguintes afirmações é a correta?',
    answers: [
      {
        answer: 'O aquecimento global não existe, porque fez frio no inverno passado.',
        right: false,
      },
      {
        answer: 'O aquecimento global não existe, porque ainda há muito gelo no planeta Terra.',
        right: false,
      },
      {
        answer: 'O aquecimento global causará mais inundações e fortes tempestades.',
        right: true,
      },
    ],
  },
  {
    question: 'A biodiversidade é...',
    answers: [
      {
        answer: 'A diversidade de fauna e flora existente no nosso planeta.',
        right: true,
      },
      {
        answer: 'A variedade de produtos fabricados pelo homem.',
        right: false,
      },
      {
        answer: 'O número de planetas que os seres humanos podem habitar.',
        right: false,
      },
    ],
  },
];
