var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-3"},[_c('ul',{staticClass:"o-Section__menu"},[_c('li',{staticClass:"o-Section__menuEntry"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: 'Água',
                  placement: 'left',
                }),expression:"{\n                  content: 'Água',\n                  placement: 'left',\n                }"}],staticClass:"o-Section__menuLink",attrs:{"to":{ name: 'Area', params: { section: 'aqua' }}}},[_c('img',{attrs:{"src":_vm.waterIcon}})])],1),_c('li',{staticClass:"o-Section__menuEntry"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: 'Reciclagem',
                  placement: 'left',
                }),expression:"{\n                  content: 'Reciclagem',\n                  placement: 'left',\n                }"}],staticClass:"o-Section__menuLink",attrs:{"to":{ name: 'Area', params: { section: 'recicle' }}}},[_c('img',{attrs:{"src":_vm.recicleIcon}})])],1),_c('li',{staticClass:"o-Section__menuEntry"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: 'Energia',
                  placement: 'left',
                }),expression:"{\n                  content: 'Energia',\n                  placement: 'left',\n                }"}],staticClass:"o-Section__menuLink",attrs:{"to":{ name: 'Area', params: { section: 'energy' }}}},[_c('img',{attrs:{"src":_vm.energyIcon}})])],1),_c('li',{staticClass:"o-Section__menuEntry"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: 'Natureza',
                  placement: 'left',
                }),expression:"{\n                  content: 'Natureza',\n                  placement: 'left',\n                }"}],staticClass:"o-Section__menuLink",attrs:{"to":{ name: 'Area', params: { section: 'nature' }}}},[_c('img',{attrs:{"src":_vm.natureIcon}})])],1)])]),_c('div',{staticClass:"col-xs-12 col-sm-9"},[(_vm.section === _vm.SECTIONS.AQUA)?_c('Aqua'):_vm._e(),(_vm.section === _vm.SECTIONS.RECICLE)?_c('Recicle'):_vm._e(),(_vm.section === _vm.SECTIONS.ENERGY)?_c('Energy'):_vm._e(),(_vm.section === _vm.SECTIONS.NATURE)?_c('Nature'):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }