<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>
  <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_49" data-name="Layer 49">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <rect class="cls-2" x="64.17" y="71.21" width="125.72" height="83.45" rx="9.82" />
          <line class="cls-3" x1="90.14" y1="182.77" x2="163.92" y2="182.77" />
          <line class="cls-4" x1="109.44" y1="159.71" x2="109.44" y2="177.71" />
          <line class="cls-4" x1="144.61" y1="159.71" x2="144.61" y2="177.71" />
      </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="2362" height="1810" transform="translate(-33.54 -0.13) scale(0.14)" xlink:href="/site.jpeg" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>
</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped>
  svg {
    height: auto;
  }
  .cls-1 {
      fill: #51a4b0;
      stroke-width: 11.34px;
  }
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4 {
      stroke: #fff;
  }
  .cls-1,
  .cls-2,
  .cls-4 {
      stroke-miterlimit: 10;
  }
  .cls-2,
  .cls-3,
  .cls-4 {
      fill: none;
      stroke-width: 5px;
      opacity: 0.5;
  }
  .cls-3 {
      stroke-linecap: round;
      stroke-linejoin: round;
  }
  .cls-4 {
      stroke-linecap: square;
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
