<template>
<div class="o-Drawing">
  <div class="o-Drawing__svg">
    <svg id="drawing" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
      <g id="background">
        <rect
          y="0" class="st0" width="1920" height="880.86"
          data-ref="background"
          :style="`fill: ${drawingColors.background}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="traco_x5F_bx">
        <rect
          y="1046.72" class="st1" width="1920" height="33.33"
          data-ref="traco_x5F_bx"
          :style="`fill: ${drawingColors.traco_x5F_bx}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="traco_x5F_topo">
        <rect
          x="-0.12" y="880.86" class="st1" width="1920.12" height="28.53"
          data-ref="traco_x5F_topo"
          :style="`fill: ${drawingColors.traco_x5F_topo}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="estrada">
        <rect
          x="-0.12" y="909.39" class="st1" width="1920.12" height="137.33"
          data-ref="estrada"
          :style="`fill: ${drawingColors.estrada}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tracos_x5F_estrada">
        <rect
          x="359.16" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="85.16" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="687.36" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1015.56" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1343.76" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1649.76" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="monte">
        <path
          class="st1" d="M-0.12,525.8l520.58-124.84c0,0,185.38-92.8,325.73-78.88c140.34,13.91,313.24-16.25,313.24-16.25
          s196.76-47.88,315.36,8.71c172.17,82.16,372.29,193.54,445.22,238.26v328.05H0L-0.12,525.8z"
          data-ref="monte"
          :style="`fill: ${drawingColors.monte}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sol">
        <g>
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 282.4338 1007.5367)" class="st1" cx="1357.42" cy="162.84" rx="65.1" ry="65.1"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1421.69,125.73L1421.69,125.73c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7c2.54-1.46,5.78-0.6,7.24,1.94l0,0
            c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7C1426.4,129.14,1423.16,128.27,1421.69,125.73z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1421.69,199.95L1421.69,199.95c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7c2.54,1.46,3.4,4.71,1.94,7.24l0,0
            c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7C1421.1,205.73,1420.23,202.48,1421.69,199.95z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1357.42,237.06L1357.42,237.06c2.93,0,5.3,2.37,5.3,5.3v23.4c0,2.93-2.37,5.3-5.3,5.3l0,0
            c-2.93,0-5.3-2.37-5.3-5.3v-23.4C1352.12,239.43,1354.49,237.06,1357.42,237.06z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1293.15,199.95L1293.15,199.95c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7c-2.54,1.46-5.78,0.6-7.24-1.94l0,0
            c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7C1288.44,196.54,1291.69,197.41,1293.15,199.95z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1293.15,125.73L1293.15,125.73c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7c-2.54-1.46-3.4-4.71-1.94-7.24
            l0,0c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7C1293.74,119.96,1294.61,123.2,1293.15,125.73z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1357.42,88.63L1357.42,88.63c-2.93,0-5.3-2.37-5.3-5.3v-23.4c0-2.93,2.37-5.3,5.3-5.3l0,0
            c2.93,0,5.3,2.37,5.3,5.3v23.4C1362.72,86.25,1360.35,88.63,1357.42,88.63z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1320.31,98.57L1320.31,98.57c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05c-1.46-2.54-0.6-5.78,1.94-7.24l0,0
            c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05C1323.72,93.86,1322.85,97.1,1320.31,98.57z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1283.21,162.84L1283.21,162.84c0,2.93-2.37,5.3-5.3,5.3h-15.07c-2.93,0-5.3-2.37-5.3-5.3l0,0
            c0-2.93,2.37-5.3,5.3-5.3h15.07C1280.83,157.54,1283.21,159.91,1283.21,162.84z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1320.31,227.11L1320.31,227.11c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05c-1.46,2.54-4.71,3.4-7.24,1.94l0,0
            c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05C1314.54,226.52,1317.78,225.65,1320.31,227.11z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1394.53,227.11L1394.53,227.11c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05c1.46,2.54,0.6,5.78-1.94,7.24l0,0
            c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05C1391.12,231.82,1391.99,228.58,1394.53,227.11z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1431.64,162.84L1431.64,162.84c0-2.93,2.37-5.3,5.3-5.3H1452c2.93,0,5.3,2.37,5.3,5.3l0,0
            c0,2.93-2.37,5.3-5.3,5.3h-15.07C1434.01,168.14,1431.64,165.77,1431.64,162.84z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1394.53,98.57L1394.53,98.57c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05c1.46-2.54,4.71-3.4,7.24-1.94l0,0
            c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05C1400.31,99.16,1397.06,100.03,1394.53,98.57z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="rosto_x5F_sol">
        <g class="st2">
          <path class="st3" d="M1345.52,209.22c-7.34,0-14.48-3.45-18.97-9.49c-0.84-1.13-0.6-2.73,0.53-3.57c1.13-0.84,2.73-0.6,3.57,0.53
            c4.5,6.06,12.41,8.78,19.69,6.78c4-1.1,7.54-3.56,9.98-6.9c0.83-1.14,2.43-1.39,3.57-0.56c1.14,0.83,1.39,2.43,0.56,3.57
            c-3.12,4.28-7.65,7.41-12.75,8.82C1349.66,208.95,1347.58,209.22,1345.52,209.22z"/>
        </g>
        <g class="st2">
          <path class="st4" d="M1326.36,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
            C1319,166.87,1322.29,163.58,1326.36,163.58"/>
        </g>
        <g class="st2">
          <path class="st4" d="M1364.62,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
            C1357.27,166.87,1360.56,163.58,1364.62,163.58"/>
        </g>
      </g>
      <g id="rabo_de_cavalo">
        <g>
          <path
            class="st5" d="M467.25,590.44c0.16,0.06,0.32,0.11,0.47,0.17C467.56,590.56,467.4,590.5,467.25,590.44z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M469.6,591.21c0.2,0.06,0.39,0.11,0.59,0.16C469.99,591.32,469.8,591.27,469.6,591.21z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M470.81,591.53c0.2,0.05,0.41,0.1,0.61,0.15C471.22,591.64,471.01,591.58,470.81,591.53z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M463.49,588.69c0.14,0.08,0.29,0.16,0.44,0.24C463.78,588.86,463.64,588.78,463.49,588.69z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M462.44,588.06c0.12,0.07,0.23,0.14,0.35,0.22C462.68,588.2,462.56,588.13,462.44,588.06z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M464.59,589.28c0.15,0.08,0.3,0.15,0.45,0.23C464.89,589.43,464.74,589.35,464.59,589.28z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M465.75,589.83c0.14,0.06,0.27,0.12,0.41,0.18C466.02,589.95,465.89,589.89,465.75,589.83z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M456.38,672.64c-10.61,0-19.29-8.68-19.29-19.29l-1.42-34.64c4.13-6.17,9.6-11.15,13.5-18.59
            c-7.24,13.8-19.95,19.12-19.27,39.41c-14.08-41.13-8.91-67.35,10.58-75.62c7.78-13.65,26.14-20.81,45.34-21.01
            c-0.31-0.58-0.67-1.03-1.09-1.32c-23.83-16.35-76.48-4.43-73.33,45.97c3.15,50.4,22.05,91.61,3.25,96.26
            c-13.55,3.35-14.79-6.53-14.79-6.53s-6.22,28.78,34.13,27.75c27.76-0.71,41.89-15.02,48.63-32.4H456.38z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M476.24,592.5c1.43,0.17,2.89,0.29,4.38,0.37C479.13,592.79,477.67,592.67,476.24,592.5z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M485.55,593.03C485.55,593.03,485.55,593.03,485.55,593.03c-1.63-0.02-3.24-0.07-4.83-0.15
            C482.31,592.97,483.92,593.01,485.55,593.03z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M473.78,592.15c0.25,0.04,0.51,0.08,0.76,0.12C474.29,592.23,474.03,592.19,473.78,592.15z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M475.02,592.34c0.27,0.04,0.54,0.08,0.81,0.11C475.56,592.42,475.29,592.38,475.02,592.34z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M472.08,591.82c0.39,0.08,0.79,0.16,1.18,0.23C472.86,591.98,472.47,591.91,472.08,591.82z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M468.41,590.85c0.19,0.06,0.37,0.12,0.56,0.18C468.78,590.97,468.6,590.91,468.41,590.85z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M453.76,584.3c0.08-0.81,0.14-1.66,0.18-2.52C453.9,582.64,453.84,583.49,453.76,584.3z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M453.97,581.12c0.04-0.89,0.06-1.79,0.06-2.72C454.04,579.33,454.01,580.23,453.97,581.12z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M453.45,586.73c0.12-0.78,0.22-1.58,0.3-2.41C453.67,585.15,453.57,585.95,453.45,586.73z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M449.26,599.95c0.59-1.13,1.14-2.33,1.64-3.59C450.4,597.63,449.85,598.82,449.26,599.95z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M454.73,579.71c0.07,0.13,0.15,0.26,0.23,0.39C454.88,579.97,454.8,579.84,454.73,579.71z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M451.03,596.05c0.48-1.24,0.92-2.55,1.3-3.94C451.95,593.5,451.51,594.81,451.03,596.05z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M452.45,591.68c0.37-1.38,0.67-2.84,0.92-4.4C453.12,588.84,452.81,590.3,452.45,591.68z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M461.19,587.19c0.09,0.06,0.17,0.13,0.26,0.19C461.36,587.32,461.28,587.25,461.19,587.19z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M459.14,585.47c0.11,0.11,0.23,0.21,0.35,0.32C459.37,585.68,459.26,585.58,459.14,585.47z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M458.52,584.84c-0.09-0.1-0.19-0.19-0.28-0.29C458.33,584.65,458.42,584.74,458.52,584.84z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M460.11,586.33c0.12,0.1,0.23,0.2,0.35,0.29C460.34,586.53,460.23,586.43,460.11,586.33z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M455.47,580.95c0.09,0.14,0.18,0.28,0.27,0.42C455.65,581.24,455.56,581.1,455.47,580.95z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M457.25,583.43c0.04,0.05,0.08,0.1,0.12,0.15C457.33,583.53,457.29,583.48,457.25,583.43z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M456.28,582.15c0.09,0.12,0.18,0.25,0.27,0.37C456.45,582.4,456.37,582.28,456.28,582.15z"
            data-ref="rabo_de_cavalo"
            :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="cabelo_x5F_frente">
        <path
          class="st5" d="M429.9,639.53c-0.95-28.29,24.13-27.47,24.13-61.14c18.07,36.73,84.44-17.99,85.22,61.76
          c10.33-28.69,12.36-63.39-8.11-81.96c-24.96-22.64-76.17-19.71-90.67,5.72C421,572.18,415.82,598.4,429.9,639.53z"
          data-ref="rabo_de_cavalo"
          :style="`fill: ${drawingColors.rabo_de_cavalo}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="farol_x5F_tras">
        <path
          class="st1" d="M656.77,789.04c-2.91-1.91-6.4-3.02-10.16-2.98H644l0.2,54.78c0,0.88,0,1.68-0.1,2.61h2.79
          c3.65-0.05,7.03-1.16,9.88-3.03V789.04z"
          data-ref="farol_x5F_tras"
          :style="`fill: ${drawingColors.farol_x5F_tras}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="luz_x5F_farol_tras">
        <path
          class="st1" d="M642.64,786.1c-4.59,0-8.38,3.86-8.38,8.48v40.43c0,4.59,3.82,8.43,8.49,8.43h1.36l-0.03-57.35H642.64z"
          data-ref="luz_x5F_farol_tras"
          :style="`fill: ${drawingColors.luz_x5F_farol_tras}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="caixa_x5F_camiao">
        <polygon
          class="st1" points="746.56,552.37 1110.61,552.37 1110.61,879.13 656.77,879.13 656.77,707.21"
          data-ref="caixa_x5F_camiao"
          :style="`fill: ${drawingColors.caixa_x5F_camiao}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="cabine_camiao">
        <path
          class="st1" d="M1436.52,788.49c-2.84-15.83-10.69-27.43-27.2-30.89c-16.44-3.54-51.87-12.2-51.87-12.2l-29.23-129.84
          c-0.33-0.35-0.48-0.76-0.78-1.16h-173.86c-5.92,0-10.69,4.77-10.69,10.72v280.79h255.54c27.28,0,49.49-22.16,49.49-49.38
          C1447.91,856.53,1439.33,804.32,1436.52,788.49z"
          data-ref="cabine_camiao"
          :style="`fill: ${drawingColors.cabine_camiao}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="porta_camiao">
        <path
          class="st1" d="M1275.63,634.89h-93.65c-8.94,0-16.11,7.23-16.11,16.06v195.89c0,8.83,7.17,16.09,16.11,16.09h13.32h3.26
          l1.53-2.84c0.05-0.25,0.25-0.48,0.38-0.7c0.28-0.38,0.53-0.8,0.73-1.18c14.01-26.43,41.16-42.79,70.92-42.79
          c3.69,0,7.58,0.3,12.17,1.01l4.57,0.65l1.35-4.37l18.1-57.11l0.38-1.25l-0.17-1.25l-16.71-102.56
          C1291.54,641.86,1284.39,634.89,1275.63,634.89L1275.63,634.89z"
          data-ref="porta_camiao"
          :style="`fill: ${drawingColors.porta_camiao}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="para_choques">
        <path
          class="st1" d="M1455.39,854.17h-69.64c-6.48,0-11.72,5.27-11.72,11.72v33.15c0,6.4,5.24,11.65,11.72,11.65h69.64
          c6.42,0,11.65-5.25,11.65-11.65v-33.15C1467.03,859.43,1461.81,854.17,1455.39,854.17z"
          data-ref="para_choques"
          :style="`fill: ${drawingColors.para_choques}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="espelho_x5F_01">
        <path
          class="st1" d="M1361.61,630.69c-3.16,0.68-5.2,3.89-4.44,7.08l10.11,42.89c0.7,3.26,3.86,5.17,7.08,4.41l0.96-0.23
          l-12.77-54.43L1361.61,630.69z"
          data-ref="espelho_x5F_01"
          :style="`fill: ${drawingColors.espelho_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="espelho_x5F_02">
        <path
          class="st1" d="M1386.8,668.84l-7-29.19c-1.63-7.03-8.56-11.34-15.51-9.68l-1.75,0.4l12.42,52.75
          c0.15,0.53,0.22,1.18,0.35,1.73l1.86-0.38C1384.17,682.82,1388.44,675.81,1386.8,668.84z"
          data-ref="espelho_x5F_02"
          :style="`fill: ${drawingColors.espelho_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="braco_x5F_espelho">
        <path
          class="st1" d="M1357.04,743.75l10.42-2.31c15.58-4.84,25.27-20.9,21.73-37.19l-5.24-23.89c-1.71,1.93-3.94,3.36-6.65,4.04
          l4.74,21.46c2.63,12.3-4.52,24.39-16.21,28.36l-10.41,2.31L1357.04,743.75z"
          data-ref="braco_x5F_espelho"
          :style="`fill: ${drawingColors.braco_x5F_espelho}!important;`"
          @click="changeColor($event)"
        />
      </g>

      <g id="farol_x5F_frente_x5F_luz">
        <path
          class="st1" d="M1465.6,824.9l-0.07-20.53c-0.08-10.16-8.26-18.42-18.47-18.32h-2.69l0.18,54.78c0,0.88,0,1.68,0,2.61h2.76
          C1457.42,843.32,1465.6,835.02,1465.6,824.9z"
          data-ref="farol_x5F_frente_x5F_luz"
          :style="`fill: ${drawingColors.farol_x5F_frente_x5F_luz}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="farol_x5F_frente">
        <path
          class="st1" d="M1443.07,786.1c-4.65,0-8.38,3.86-8.38,8.48v40.43c0,4.59,3.81,8.43,8.48,8.43h1.38l-0.05-57.35H1443.07z"
          data-ref="farol_x5F_frente"
          :style="`fill: ${drawingColors.farol_x5F_frente}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="vidro_x5F_porta">
        <path
          class="st1" d="M1275.63,661.68c-0.85-5.89-6.4-10.66-12.29-10.66h-69.76c-5.93,0-10.74,4.77-10.74,10.66v78.4
          c0,5.97,4.82,10.74,10.74,10.74h84.37c5.92,0,10.08-4.77,9.18-10.74L1275.63,661.68z"
          data-ref="vidro_x5F_porta"
          :style="`fill: ${drawingColors.vidro_x5F_porta}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="vidro_frente">
        <path
          class="st1" d="M1321.31,709.24c1.41,5.93,7.33,10.72,13.22,10.72h17.19l-22.49-99.8h-17.59c-5.97,0-9.64,4.8-8.31,10.67
          L1321.31,709.24z"
          data-ref="vidro_frente"
          :style="`fill: ${drawingColors.vidro_frente}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="reflexo_vidro">
        <path
          class="st6" d="M1275.63,661.68c-0.85-5.89-6.4-10.66-12.29-10.66h-69.76c-5.93,0-10.74,4.77-10.74,10.66v36.54h98.19
          L1275.63,661.68z"
          data-ref="reflexo_vidro"
          :style="`fill: ${drawingColors.reflexo_vidro}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st6" d="M1311.72,667.31h28.15l-10.64-47.15h-17.59c-5.97,0-9.64,4.8-8.31,10.67L1311.72,667.31z"
          data-ref="reflexo_vidro"
          :style="`fill: ${drawingColors.reflexo_vidro}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="para_choques_x5F_02">
        <g>
          <path
            class="st1" d="M1338.03,879.13c1.3,5.25,2,10.73,2,16.39c0,3.57-0.28,7.03-0.81,10.39h37.09c-1.43-1.93-2.29-4.31-2.29-6.88
            v-19.9H1338.03z"
            data-ref="para_choques_x5F_02"
            :style="`fill: ${drawingColors.para_choques_x5F_02}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1204.19,895.52c0-5.65,0.7-11.14,2-16.39H656.77v26.78h548.27C1204.51,902.55,1204.19,899.09,1204.19,895.52 z"
            data-ref="para_choques_x5F_02"
            :style="`fill: ${drawingColors.para_choques_x5F_02}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="guarda_x5F_lamas_x5F_tras_x5F_01">
        <path
          class="st1" d="M856,895.52c0-37.54-30.42-67.9-67.88-67.9c-37.52,0-67.96,30.36-67.96,67.9c0,3.57,0.28,7.03,0.83,10.39 h134.24C855.69,902.55,856,899.09,856,895.52z"
          data-ref="guarda_x5F_lamas_x5F_tras_x5F_01"
          :style="`fill: ${drawingColors.guarda_x5F_lamas_x5F_tras_x5F_01}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1340.03,895.52c0-37.54-30.42-67.9-67.91-67.9s-67.93,30.36-67.93,67.9c0,3.57,0.32,7.03,0.85,10.39h134.18 C1339.75,902.55,1340.03,899.09,1340.03,895.52z"
          data-ref="guarda_x5F_lamas_x5F_tras_x5F_01"
          :style="`fill: ${drawingColors.guarda_x5F_lamas_x5F_tras_x5F_01}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1012.39,895.52c0-37.54-30.39-67.9-67.88-67.9c-37.52,0-67.96,30.36-67.96,67.9c0,3.57,0.3,7.03,0.83,10.39 h134.23C1012.11,902.55,1012.39,899.09,1012.39,895.52z"
          data-ref="guarda_x5F_lamas_x5F_tras_x5F_01"
          :style="`fill: ${drawingColors.guarda_x5F_lamas_x5F_tras_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="logo_x5F_camiao">
        <g>
          <path
            class="st7" d="M764.45,745.21L764.45,745.21c0,2.09-1.45,3.53-3.6,3.53c-1.86,0-3.54-1.45-3.54-3.53
            c0-1.85,1.68-3.53,3.54-3.53C763.01,741.68,764.45,743.36,764.45,745.21 M763.76,780.18L763.76,780.18h-5.8v-28.95h5.8V780.18z
            M781.51,750.77L781.51,750.77c-2.9,0-5.68,1.1-7.77,2.95v-2.49h-4.82v28.95h5.8v-17.14c0-3.59,2.9-6.49,6.55-6.49
            c3.54,0,6.5,2.9,6.5,6.49v17.14h5.8v-17.14C793.57,756.27,788.24,750.77,781.51,750.77z M808.59,745.09L808.59,745.09h4.64v-5.44
            h-4.64c-5.74,0-10.32,3.76-10.32,10.13v30.4h5.74v-23.86h9.22v-5.09h-9.22v-1.62C804.01,747.12,806.04,745.09,808.59,745.09z
            M832.61,751.23L832.61,751.23h-3.94c-2.78,0-5.28,0.87-6.84,2.43v-2.43h-4.75v28.95h5.8v-17.43c0-3.65,2.84-5.96,6.5-5.96h3.25
            V751.23z M863.48,751.23L863.48,751.23h-4.99v3.65c-2.55-2.55-6.03-4.11-9.92-4.11c-8.29,0-14.97,6.72-14.97,14.88
            c0,8.28,6.67,15,14.97,15c3.88,0,7.42-1.62,9.97-4.11v3.65h4.93V751.23z M848.64,774.8L848.64,774.8c-5.05,0-9.1-4.05-9.1-9.15
            c0-4.98,4.06-9.09,9.1-9.09c5.1,0,9.16,4.11,9.16,9.09C857.8,770.74,853.74,774.8,848.64,774.8z M882.6,780.65L882.6,780.65
            c3.48,0,6.73-1.33,9.11-3.48v14.59h5.8v-40.53h-4.93v3.65c-2.55-2.49-6.15-4.11-9.98-4.11c-8.29,0-14.97,6.72-14.97,14.94
            C867.63,773.99,874.31,780.65,882.6,780.65z M882.66,756.68L882.66,756.68c4.93,0,9.11,4.05,9.11,9.03c0,5.04-4.18,9.09-9.11,9.09
            c-5.05,0-9.11-4.05-9.11-9.09C873.55,760.73,877.61,756.68,882.66,756.68z M915.04,780.65L915.04,780.65
            c-6.79,0-12.3-5.5-12.3-12.28v-17.14h5.74v17.14c0,3.53,2.96,6.49,6.55,6.49c3.59,0,6.5-2.95,6.5-6.49v-17.14h5.8v17.14
            C927.34,775.15,921.83,780.65,915.04,780.65z M938.77,745.21L938.77,745.21c0,2.09-1.45,3.53-3.6,3.53
            c-1.86,0-3.54-1.45-3.54-3.53c0-1.85,1.68-3.53,3.54-3.53C937.32,741.68,938.77,743.36,938.77,745.21z M938.07,780.18
            L938.07,780.18h-5.8v-28.95h5.8V780.18z M955.82,750.77L955.82,750.77c-2.9,0-5.68,1.1-7.77,2.95v-2.49h-4.81v28.95h5.8v-17.14
            c0-3.59,2.9-6.49,6.55-6.49c3.54,0,6.5,2.9,6.5,6.49v17.14h5.8v-17.14C967.89,756.27,962.55,750.77,955.82,750.77z M987.49,774.34
            L987.49,774.34h-4.87c-2.55,0-4.53-1.97-4.53-4.46v-13.55h9.51v-5.09h-9.51v-9.21h-5.8v27.8c0,6.43,4.12,10.36,10.21,10.36h4.99
            V774.34z M1019.8,751.23L1019.8,751.23h-4.99v3.65c-2.55-2.55-6.03-4.11-9.92-4.11c-8.29,0-14.96,6.72-14.96,14.88
            c0,8.28,6.67,15,14.96,15c3.89,0,7.42-1.62,9.98-4.11v3.65h4.93V751.23z M1004.95,774.8c-5.05,0-9.11-4.05-9.11-9.15
            c0-4.98,4.06-9.09,9.11-9.09c5.1,0,9.16,4.11,9.16,9.09C1014.12,770.74,1010.06,774.8,1004.95,774.8z"
            data-ref="logo_x5F_camiao"
            :style="`fill: ${drawingColors.logo_x5F_camiao}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st8" d="M838.82,591.53c8.8,0,15.94,7.12,15.94,15.91c0,8.79-7.14,15.91-15.94,15.91c-8.8,0-15.94-7.13-15.94-15.91
            C822.88,598.66,830.02,591.53,838.82,591.53"
            data-ref="logo_x5F_camiao"
            :style="`fill: ${drawingColors.logo_x5F_camiao}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st8" d="M888.78,591.53c36.38,0,65.87,29.45,65.87,65.77c0,15.47-5.35,29.69-14.3,40.92h14.26v24.85h-65.35
            c-0.17,0-0.33,0.01-0.49,0.01c-36.39,0-65.88-29.45-65.88-65.77v-0.03v-14.23h24.89v14.23v0.03c0,22.59,18.34,40.91,40.97,40.92
            h0.04c22.63-0.01,40.97-18.33,40.97-40.92c0-22.6-18.34-40.91-40.97-40.92h-14.27v-24.85H888.78z"
            data-ref="logo_x5F_camiao"
            :style="`fill: ${drawingColors.logo_x5F_camiao}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="roda_x5F_a_x5F_04">
        <path
          class="st1" d="M846.19,895.52c0,32.12-26.03,58.12-58.07,58.12c-32.15,0-58.14-26-58.14-58.12c0-32.12,26-58.12,58.14-58.12 C820.16,837.4,846.19,863.4,846.19,895.52z"
          data-ref="roda_x5F_a_x5F_04"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_04}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1002.6,895.52c0,32.12-26.05,58.12-58.09,58.12c-32.12,0-58.15-26-58.15-58.12 c0-32.12,26.03-58.12,58.15-58.12C976.55,837.4,1002.6,863.4,1002.6,895.52z"
          data-ref="roda_x5F_a_x5F_04"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_04}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1330.24,895.52c0,32.12-26,58.12-58.12,58.12c-32.15,0-58.1-26-58.1-58.12c0-32.12,25.95-58.12,58.1-58.12 C1304.24,837.4,1330.24,863.4,1330.24,895.52z"
          data-ref="roda_x5F_a_x5F_04"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_04}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_a_x5F_03">
        <path
          class="st1" d="M821.82,895.52c0,18.62-15.11,33.68-33.7,33.68c-18.62,0-33.7-15.06-33.7-33.68s15.08-33.75,33.7-33.75 C806.71,861.77,821.82,876.9,821.82,895.52z"
          data-ref="roda_x5F_a_x5F_03"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_03}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M978.21,895.52c0,18.62-15.08,33.68-33.7,33.68s-33.7-15.06-33.7-33.68s15.08-33.75,33.7-33.75 S978.21,876.9,978.21,895.52z"
          data-ref="roda_x5F_a_x5F_03"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_03}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1305.8,895.52c0,18.62-15.08,33.68-33.68,33.68c-18.62,0-33.73-15.06-33.73-33.68s15.11-33.75,33.73-33.75 C1290.72,861.77,1305.8,876.9,1305.8,895.52z"
          data-ref="roda_x5F_a_x5F_03"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_03}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_a_x5F_02">
        <path
          class="st1" d="M807.47,895.52c0,10.7-8.71,19.32-19.35,19.32c-10.77,0-19.4-8.63-19.4-19.32c0-10.74,8.63-19.34,19.4-19.34 C798.75,876.17,807.47,884.78,807.47,895.52z"
          data-ref="roda_x5F_a_x5F_02"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M963.85,895.52c0,10.7-8.71,19.32-19.35,19.32c-10.74,0-19.4-8.63-19.4-19.32c0-10.74,8.66-19.34,19.4-19.34 C955.15,876.17,963.85,884.78,963.85,895.52z"
          data-ref="roda_x5F_a_x5F_02"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1291.45,895.52c0,10.7-8.63,19.32-19.32,19.32c-10.74,0-19.38-8.63-19.38-19.32 c0-10.74,8.63-19.34,19.38-19.34C1282.81,876.17,1291.45,884.78,1291.45,895.52z"
          data-ref="roda_x5F_a_x5F_02"
          :style="`fill: ${drawingColors.roda_x5F_a_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_a_x5F_01">
        <path
          class="st1" d="M795.09,895.52c0,3.82-3.14,6.98-6.97,6.98s-7.08-3.16-7.08-6.98c0-3.89,3.24-6.92,7.08-6.92 S795.09,891.63,795.09,895.52z"
          data-ref="guarda_x5F_lamas_x5F_tras_x5F_01"
          :style="`fill: ${drawingColors.guarda_x5F_lamas_x5F_tras_x5F_01}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M951.48,895.52c0,3.82-3.14,6.98-6.97,6.98c-3.84,0-7.08-3.16-7.08-6.98c0-3.89,3.24-6.92,7.08-6.92 C948.35,888.6,951.48,891.63,951.48,895.52z"
          data-ref="guarda_x5F_lamas_x5F_tras_x5F_01"
          :style="`fill: ${drawingColors.guarda_x5F_lamas_x5F_tras_x5F_01}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1279.12,895.52c0,3.82-3.14,6.98-7,6.98s-6.95-3.16-6.95-6.98c0-3.89,3.09-6.92,6.95-6.92 S1279.12,891.63,1279.12,895.52z"
          data-ref="guarda_x5F_lamas_x5F_tras_x5F_01"
          :style="`fill: ${drawingColors.guarda_x5F_lamas_x5F_tras_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="solas">
        <path
          class="st5" d="M543.46,929.21c-4.72,2.14-12.36,0.01-15.67-1.72c-3.51-2.33-18.11-2.41-21.42,0
          c-3.12,1.38-8.96,4.33-14.85,1.8c0,0-1.49,1.53-0.89,3.17c-0.04,0.15-0.09,0.29-0.09,0.45c0,1.13,0.93,3.7,2.04,3.88
          c10.53,1.73,48.45,1.22,49.81,0c1.13,0,2.04-2.75,2.04-3.88C545.39,930.94,543.46,929.21,543.46,929.21z"
          data-ref="solas"
          :style="`fill: ${drawingColors.solas}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M480.83,929.43c-4.72,2.14-12.36,0.01-15.67-1.72c-3.51-2.33-18.11-2.41-21.42,0
          c-3.12,1.38-8.96,4.33-14.85,1.8c0,0-1.49,1.53-0.89,3.17c-0.04,0.15-0.09,0.29-0.09,0.45c0,1.13,0.93,3.7,2.04,3.88
          c10.53,1.73,48.45,1.22,49.81,0c1.13,0,2.04-2.75,2.04-3.88l0,0l0,0C482.77,931.16,480.83,929.43,480.83,929.43z"
          data-ref="solas"
          :style="`fill: ${drawingColors.solas}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="bota_x5F_esq">
        <path
          class="st5" d="M443.74,927.71c3.32-2.41,17.91-2.33,21.42,0c3.3,1.72,10.87,3.84,15.59,1.76c0.07-0.31,0.1-0.64,0.06-0.98
          c-0.52-4.05-1.96-7.47-4.12-10.26c0,0,0,0,0,0c-1.27-5.79-2.78-13.48-3.51-16.24c-0.25-0.93-1.15-1.51-2.09-1.37
          c-2.49,0.37-7.21-1.75-10.15-1.75c-2.54,0-1.88,6.6-0.99,10.57c-1.65-0.22-3.35-0.34-5.09-0.34c-2.12,0-4.18,0.18-6.15,0.51
          c0.91-3.95,1.62-10.75-0.95-10.75c-2.94,0-7.66,2.12-10.15,1.75c-0.95-0.14-1.85,0.44-2.09,1.37c-0.73,2.76-2.25,10.45-3.51,16.24
          c-0.13,0.59-0.07,1.01,0.15,1.28c-1.64,2.51-2.76,5.47-3.21,8.89c-0.05,0.4-0.02,0.79,0.07,1.15
          C434.85,932,440.64,929.08,443.74,927.71z"
          data-ref="bota"
          :style="`fill: ${drawingColors.bota}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="bota_x5F_dta">
        <path
          class="st5" d="M506.36,927.49c3.32-2.41,17.91-2.33,21.42,0c3.3,1.72,10.87,3.84,15.59,1.76c0.07-0.31,0.1-0.64,0.06-0.98
          c-0.52-4.05-1.96-7.47-4.12-10.26c0,0,0,0,0,0c-1.27-5.78-2.78-13.48-3.51-16.24c-0.25-0.93-1.15-1.51-2.09-1.37
          c-2.49,0.37-7.21-1.75-10.15-1.75c-2.54,0-1.88,6.6-0.99,10.57c-1.65-0.22-3.35-0.34-5.09-0.34c-2.12,0-4.18,0.18-6.15,0.51
          c0.91-3.95,1.62-10.75-0.95-10.75c-2.94,0-7.66,2.12-10.15,1.75c-0.95-0.14-1.85,0.44-2.09,1.37c-0.73,2.76-2.25,10.45-3.51,16.24
          c-0.13,0.59-0.07,1.01,0.15,1.28c-1.64,2.51-2.76,5.47-3.21,8.89c-0.05,0.4-0.02,0.79,0.07,1.15
          C497.47,931.78,503.26,928.86,506.36,927.49z"
          data-ref="bota"
          :style="`fill: ${drawingColors.bota}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <!-- <g id="linhas_botas">
        <path class="st9" d="M543.44,932.54c-4.72,2.14-12.36,0.01-15.67-1.72c-3.51-2.33-18.11-2.41-21.42,0
          c-3.12,1.38-8.96,4.33-14.85,1.8"/>
        <path class="st10" d="M506.88,899.66l-3.12,13.82c-0.42,1.85,1.03,4,2.89,3.61c5.3-1.1,14.66-0.95,19.81-0.17
          c1.64,0.25,3.03-1.22,2.73-2.85l-2.77-14.78"/>
        <path class="st10" d="M528.49,909.17c0,0,4.54,0.55,9.43,5.05"/>
        <path class="st10" d="M528.9,910.6c0,0,4.5,0.54,8.9,5.56"/>
        <path class="st10" d="M503.62,910.39c0,0-3.85,0.78-8.38,5.42"/>
        <path class="st10" d="M503.62,911.75c0,0-4.1,1.04-8.16,6.03"/>
        <path class="st11" d="M525.71,912.45c-1.61-0.58-7.71-4.5-16.43-5.8"/>
        <path class="st11" d="M516.88,900.54c0.05-0.25,3.18-0.51,5.56,1.24c0.22,0.16,0.89,0.85,2.22,2.22c2.02,2.08,1.7,1.87,2.47,2.59
          c1.61,1.51,2.23,1.68,2.47,2.59c0.19,0.72,0.11,1.72-0.49,2.22c-0.95,0.79-2.89,0.09-3.95-0.99c-0.83-0.84-0.63-1.42-1.48-3.58
          c-0.64-1.63-1.24-3.16-2.47-4.32C519.26,900.68,516.84,900.76,516.88,900.54z"/>
        <path class="st11" d="M516.69,900.54c-0.05-0.25-3.18-0.51-5.56,1.24c-0.22,0.16-0.89,0.85-2.22,2.22
          c-2.02,2.08-1.7,1.87-2.47,2.59c-1.61,1.51-2.23,1.68-2.47,2.59c-0.19,0.72-0.11,1.72,0.49,2.22c0.95,0.79,2.89,0.09,3.95-0.99
          c0.83-0.84,0.63-1.42,1.48-3.58c0.64-1.63,1.24-3.16,2.47-4.32C514.3,900.68,516.73,900.76,516.69,900.54z"/>
        <path class="st10" d="M444.26,899.87l-3.12,13.82c-0.42,1.85,1.03,4,2.89,3.61c5.3-1.1,14.66-0.95,19.81-0.17
          c1.64,0.25,3.03-1.22,2.73-2.85l-2.77-14.78"/>
        <path class="st10" d="M465.87,909.39c0,0,5.37,1,9.35,4.98"/>
        <path class="st10" d="M466.28,910.82c0,0,4.55,0.57,8.94,5.58"/>
        <path class="st10" d="M441,910.6c0,0-3.85,0.78-8.38,5.42"/>
        <path class="st10" d="M441,911.97c0,0-4.6,1.41-8.66,6.4"/>
        <path class="st11" d="M463.09,912.67c-1.61-0.58-7.71-4.5-16.43-5.8"/>
        <path class="st11" d="M454.26,900.76c0.05-0.25,3.18-0.51,5.56,1.24c0.22,0.16,0.89,0.85,2.22,2.22c2.02,2.08,1.7,1.87,2.47,2.59
          c1.61,1.51,2.23,1.68,2.47,2.59c0.19,0.72,0.11,1.72-0.49,2.22c-0.95,0.79-2.89,0.09-3.95-0.99c-0.83-0.84-0.63-1.42-1.48-3.58
          c-0.64-1.63-1.24-3.16-2.47-4.32C456.64,900.9,454.21,900.98,454.26,900.76z"/>
        <path class="st11" d="M454.07,900.76c-0.05-0.25-3.18-0.51-5.56,1.24c-0.22,0.16-0.89,0.85-2.22,2.22
          c-2.02,2.08-1.7,1.87-2.47,2.59c-1.61,1.51-2.23,1.68-2.47,2.59c-0.19,0.72-0.11,1.72,0.49,2.22c0.95,0.79,2.89,0.09,3.95-0.99
          c0.83-0.84,0.63-1.42,1.48-3.58c0.64-1.63,1.24-3.16,2.47-4.32C451.68,900.9,454.11,900.98,454.07,900.76z"/>
        <path class="st11" d="M506.77,912.9c1.72-0.63,8.06-4.96,17.39-6.35"/>
        <path class="st11" d="M506.77,912.9c8.98-1.42,18.45,0,18.45,0"/>
        <path class="st11" d="M444.28,913.13c1.72-0.63,8.06-4.96,17.39-6.35"/>
        <path class="st11" d="M444.28,913.13c8.98-1.42,18.45,0,18.45,0"/>
        <path class="st9" d="M480.82,932.75c-4.72,2.14-12.36,0.01-15.67-1.72c-3.51-2.33-18.11-2.41-21.42,0
          c-3.12,1.38-8.96,4.33-14.85,1.8"/>
      </g> -->
      <g id="calca">
        <path
          class="st5" d="M539.67,874.03l-0.39-13.4l-0.59-20.49l-0.72-31.93c0,0-2.6-18.95-3.03-29.83c-0.01-0.21-0.18-0.37-0.39-0.37
          c-17.76-0.11-31.76,0.05-47.62-0.61c-12.5,0-33.93-0.92-47.62-1.4c-0.21-0.01-0.38,0.15-0.39,0.35
          c-0.5,10.87-3.23,31.07-3.23,31.07l-1.45,31.92l-0.72,20.49l-1.02,13.53c3.51,2.46,46.15,3.07,48.16,1.06l0,0l3.36-45.66
          c0.05-1.88,4.78-1.88,4.86,0.16l3.56,46.25l0,0C492.06,877.19,530.34,877.62,539.67,874.03z"
          data-ref="calca"
          :style="`fill: ${drawingColors.calca}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M430.6,896.05c-0.03,1.69,0.64,3.8,0.59,6.33c-0.02,1.02-1.18,2.73-1.21,3.68c-0.06,1.63,0.39,2.83,0.15,3.78
          c-0.75,2.9,1.51,2.95,2.78,2.77c0.5-0.07,0.57-1.85,0.95-2.17c0.61-0.5,0.94-0.66,2.97-0.82c7.03-0.56,16.91-3.2,25.44-1.73
          c2.47,0.43,9.31,1.08,12.27,3.32c1.6,1.21,1.31,3.62,1.83,3.7c2.92,0.43,2.43-3.36,2.16-5.07l0.52-1.02
          c0.34-0.67,0.46-1.43,0.34-2.18l-0.44-2.73l0.2-2.86l0.23-3.31C476.76,899.67,432.04,900.93,430.6,896.05z"
          data-ref="calca"
          :style="`fill: ${drawingColors.calca}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M493.27,898.62c-0.05-0.04-0.1-0.08-0.15-0.11l-0.36,4.49l-0.24,3.06l-0.62,1.34
          c-0.25,0.53-0.32,1.12-0.2,1.69l0.42,2.04c-0.02,0.73-0.21,3.85,2.64,3.72c0.52-0.02,0.79-2.4,2.4-3.6
          c2.97-2.22,11.3-2.87,13.63-3.79c2.81-1.11,13.04,0.66,20.07,1.26c2.03,0.17,5.81,0.94,6.64,1.78c0.35,0.36-0.09,2.23,0.41,2.3
          c1.26,0.18,2.92-0.32,2.96-2.39c0.02-0.86,0.42-1.49,0.43-2.63c0.01-0.92-1.19-2.96-1.21-3.95c-0.05-1.98,0.62-3.35,0.21-5.01
          c-0.07-0.26-0.12-0.65-0.16-1.11c0,0,0,0,0,0C537.33,900.36,496.55,900.93,493.27,898.62z"
          data-ref="calca"
          :style="`fill: ${drawingColors.calca}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M431.31,890.54C431.31,890.54,431.31,890.54,431.31,890.54c0,0,18.22,4.92,48.12,1.31
          c0.03,0,0.06-0.01,0.09-0.01c-0.03-0.72-0.02-1.44,0.02-2.16l0.46-6.31c-5.3,0.67-39.94,1.21-48.09-2.29l-0.62,8.18
          C431.32,889.69,431.32,890.11,431.31,890.54z"
          data-ref="calca"
          :style="`fill: ${drawingColors.calca}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M539.92,882.29c-6.18,2.45-35.12,4.57-46.86,0.87l0.54,7.04c0.02,0.34,0.01,0.69,0.01,1.03
          c0.01,0,0.01,0.01,0.02,0.01c11.87,3.16,36.94,3.16,46.34-0.18c0.01-1.71,0.06-3.22,0.09-3.94L539.92,882.29z"
          data-ref="calca"
          :style="`fill: ${drawingColors.calca}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <!-- <g id="Layer_11">
        <polygon class="st7" points="890,883.58 890,883.58 890.03,883.58"/>
        <path class="st5" d="M556.37,780.89"/>
        <path class="st5" d="M414.68,781.15"/>
      </g> -->
      <g id="riscas_x5F_refletoras">
        <path
          class="st5" d="M479.52,891.84c-0.03,0-0.06,0.01-0.09,0.01c-29.9,3.61-48.12-1.31-48.12-1.31s0,0,0,0
          c-0.03,1.53-0.23,3.05-0.6,4.53c-0.08,0.31-0.1,0.64-0.11,0.98c1.44,4.88,46.17,3.62,48.77,1.7l0.29-4.08
          C479.58,893.07,479.54,892.45,479.52,891.84z"
          data-ref="riscas_x5F_refletoras"
          :style="`fill: ${drawingColors.riscas_x5F_refletoras}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M432.5,873.33l-0.58,7.75c8.15,3.5,42.79,2.96,48.09,2.29l0.66-8.99l0,0
          C478.65,876.4,436.01,875.8,432.5,873.33z"
          data-ref="riscas_x5F_refletoras"
          :style="`fill: ${drawingColors.riscas_x5F_refletoras}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M539.92,882.29l-0.24-8.26c-9.33,3.59-47.62,3.16-47.24,1.1l0,0l0.62,8.03
          C504.8,886.86,533.74,884.74,539.92,882.29z"
          data-ref="riscas_x5F_refletoras"
          :style="`fill: ${drawingColors.riscas_x5F_refletoras}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M539.97,891.06c-9.39,3.34-34.46,3.34-46.34,0.18c-0.01,0-0.01-0.01-0.02-0.01c0.01,0.99-0.03,1.98-0.15,2.96
          l-0.34,4.3c0.05,0.04,0.1,0.08,0.15,0.11c3.28,2.32,44.06,1.75,46.87-0.9c0,0,0,0,0,0C539.98,896.07,539.95,893.38,539.97,891.06z"
          data-ref="riscas_x5F_refletoras"
          :style="`fill: ${drawingColors.riscas_x5F_refletoras}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_corpo">
        <g>
          <path
            class="st5" d="M439.77,679.91c-0.82,0-1.73,0.24-2.57,0.64c0.94-0.35,1.81-0.54,2.63-0.65L439.77,679.91z"
            data-ref="tronco_x5F_corpo"
            :style="`fill: ${drawingColors.tronco_x5F_corpo}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st5" d="M533.43,725.08c-1.47-4-0.23-26.39-1.48-37.25c-0.47-4.05-0.18-6.37-1.78-7.9l-16.24-1.9h-55.83l-17.01,1.75
            c0.1-0.01,0.2-0.02,0.3-0.02c-2.19,1.52-1.79,3.89-2.3,8.33c-1.25,10.86-0.01,33.25-1.48,37.25c-0.49,1.35-0.89,2.45-1.21,3.36
            l2.59,48.16c0,2.5,2.05,4.18,4.53,4.53c20.38,2.85,59.04,3.1,85.25,0c2.48-0.29,4.53-2.03,4.53-4.53l1.23-48.7
            C534.24,727.3,533.88,726.29,533.43,725.08z"
            data-ref="tronco_x5F_corpo"
            :style="`fill: ${drawingColors.tronco_x5F_corpo}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="colete">
        <path
          class="st12" d="M528.63,679.31l-14.09-1.05l-28.67,49.19l-27.76-49.41l-14.69,1.28c1.44,0,0.48,49.88-6.24,59.33
          c-0.92,9.82-1.33,39.44-1.33,39.44h100.63l-1.11-38.79C535.36,739.29,526.61,717.63,528.63,679.31z"
          data-ref="colete"
          :style="`fill: ${drawingColors.colete}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="persponto">
        <path class="st13" d="M488.57,792.67l0.7,25.44c0,0-10.33,4.16-10.93-5.23c-0.6-9.39-0.59-8.64-0.7-20.21"/>
        <path class="st13" d="M537.33,802.38l-15.72-4.34c-1.5-0.42-2.55-1.78-2.55-3.35v-3.33"/>
        <path class="st13" d="M453.24,789.65v5.48c0,1.38-1.06,2.58-2.58,2.93l-13.89,3.24"/>
        <line class="st14" x1="486.05" y1="728.05" x2="486.05" y2="791.37"/>
      </g>
      <g id="riscas_verticais_x5F_colete">
        <g>
          <polygon
            class="st15" points="448.38,678.55 456.63,678.03 458.11,762.14 448.38,762.14"
            data-ref="riscas_verticais_x5F_colete"
            :style="`fill: ${drawingColors.riscas_verticais_x5F_colete}!important;`"
            @click="changeColor($event)"
          />
        </g>
        <g>
          <polygon
            class="st15" points="516.28,678.05 523.42,678.55 523.25,761.92 514.27,761.92"
            data-ref="riscas_verticais_x5F_colete"
            :style="`fill: ${drawingColors.riscas_verticais_x5F_colete}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="riscas_x5F_colete">
        <rect
          x="434.96" y="778.44" class="st5" width="102.37" height="14.2"
          data-ref="riscas_x5F_colete"
          :style="`fill: ${drawingColors.riscas_x5F_colete}!important;`"
          @click="changeColor($event)"
        />
        <polygon
          class="st15" points="536.48,769.22 435.85,769.22 436.07,757.8 536.16,757.8"
          data-ref="riscas_x5F_colete"
          :style="`fill: ${drawingColors.riscas_x5F_colete}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="colarinho">
        <g>
          <line class="st5" x1="486.16" y1="718.32" x2="486.17" y2="700.89"/>
          <polyline
            class="st5" points="476.6,683.08 481.14,691.53 470.92,701.37 456.28,676.07 472.67,676.07 476.6,683.08"
            data-ref="colarinho"
            :style="`fill: ${drawingColors.colarinho}!important;`"
            @click="changeColor($event)"
          />
          <polyline
            class="st5" points="491.58,691.15 499.79,676.07 515.74,676.07 501.84,701.38 491.58,691.15"
              data-ref="colarinho"
            :style="`fill: ${drawingColors.colarinho}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="logo_colete">
        <g>
          <g>
            <path class="st16" d="M497.91,720.36L497.91,720.36c0,0.19-0.13,0.32-0.32,0.32c-0.17,0-0.32-0.13-0.32-0.32
              c0-0.17,0.15-0.32,0.32-0.32C497.78,720.04,497.91,720.19,497.91,720.36 M497.85,723.52L497.85,723.52h-0.52v-2.61h0.52V723.52z
              M499.45,720.86L499.45,720.86c-0.26,0-0.51,0.1-0.7,0.27v-0.22h-0.43v2.61h0.52v-1.55c0-0.32,0.26-0.59,0.59-0.59
              c0.32,0,0.59,0.26,0.59,0.59v1.55h0.52v-1.55C500.54,721.36,500.06,720.86,499.45,720.86z M501.9,720.35L501.9,720.35h0.42v-0.49
              h-0.42c-0.52,0-0.93,0.34-0.93,0.91v2.74h0.52v-2.15h0.83v-0.46h-0.83v-0.15C501.48,720.53,501.67,720.35,501.9,720.35z
              M504.07,720.9L504.07,720.9h-0.36c-0.25,0-0.48,0.08-0.62,0.22v-0.22h-0.43v2.61h0.52v-1.57c0-0.33,0.26-0.54,0.59-0.54h0.29
              V720.9z M506.85,720.9L506.85,720.9h-0.45v0.33c-0.23-0.23-0.54-0.37-0.9-0.37c-0.75,0-1.35,0.61-1.35,1.34
              c0,0.75,0.6,1.35,1.35,1.35c0.35,0,0.67-0.15,0.9-0.37v0.33h0.45V720.9z M505.51,723.03L505.51,723.03
              c-0.46,0-0.82-0.37-0.82-0.83c0-0.45,0.37-0.82,0.82-0.82c0.46,0,0.83,0.37,0.83,0.82C506.34,722.66,505.97,723.03,505.51,723.03
              z M508.58,723.56L508.58,723.56c0.31,0,0.61-0.12,0.82-0.31v1.32h0.52v-3.66h-0.45v0.33c-0.23-0.22-0.56-0.37-0.9-0.37
              c-0.75,0-1.35,0.61-1.35,1.35C507.23,722.96,507.83,723.56,508.58,723.56z M508.59,721.39L508.59,721.39
              c0.44,0,0.82,0.37,0.82,0.82c0,0.45-0.38,0.82-0.82,0.82c-0.46,0-0.82-0.37-0.82-0.82C507.76,721.76,508.13,721.39,508.59,721.39
              z M511.51,723.56L511.51,723.56c-0.61,0-1.11-0.5-1.11-1.11v-1.55h0.52v1.55c0,0.32,0.27,0.59,0.59,0.59
              c0.32,0,0.59-0.27,0.59-0.59v-1.55h0.52v1.55C512.62,723.06,512.12,723.56,511.51,723.56z M513.65,720.36L513.65,720.36
              c0,0.19-0.13,0.32-0.32,0.32c-0.17,0-0.32-0.13-0.32-0.32c0-0.17,0.15-0.32,0.32-0.32C513.52,720.04,513.65,720.19,513.65,720.36
              z M513.59,723.52L513.59,723.52h-0.52v-2.61h0.52V723.52z M515.19,720.86L515.19,720.86c-0.26,0-0.51,0.1-0.7,0.27v-0.22h-0.43
              v2.61h0.52v-1.55c0-0.32,0.26-0.59,0.59-0.59c0.32,0,0.59,0.26,0.59,0.59v1.55h0.52v-1.55
              C516.28,721.36,515.8,720.86,515.19,720.86z M518.05,722.99L518.05,722.99h-0.44c-0.23,0-0.41-0.18-0.41-0.4v-1.22h0.86v-0.46
              h-0.86v-0.83h-0.52v2.51c0,0.58,0.37,0.94,0.92,0.94h0.45V722.99z M520.97,720.9L520.97,720.9h-0.45v0.33
              c-0.23-0.23-0.54-0.37-0.9-0.37c-0.75,0-1.35,0.61-1.35,1.34c0,0.75,0.6,1.35,1.35,1.35c0.35,0,0.67-0.15,0.9-0.37v0.33h0.45
              V720.9z M519.63,723.03c-0.46,0-0.82-0.37-0.82-0.83c0-0.45,0.37-0.82,0.82-0.82c0.46,0,0.83,0.37,0.83,0.82
              C520.45,722.66,520.09,723.03,519.63,723.03z"/>
          </g>
          <g>
            <path class="st17" d="M504.63,706.48c0.79,0,1.44,0.64,1.44,1.44c0,0.79-0.64,1.44-1.44,1.44c-0.79,0-1.44-0.64-1.44-1.44
              C503.19,707.13,503.83,706.48,504.63,706.48"/>
          </g>
          <g>
            <path class="st17" d="M509.14,706.48c3.28,0,5.95,2.66,5.95,5.94c0,1.4-0.48,2.68-1.29,3.69h1.29v2.24h-5.9
              c-0.01,0-0.03,0-0.04,0c-3.29,0-5.95-2.66-5.95-5.94v0v-1.28h2.25v1.28v0c0,2.04,1.66,3.69,3.7,3.69h0c2.04,0,3.7-1.65,3.7-3.69
              c0-2.04-1.66-3.69-3.7-3.69h-1.29v-2.24H509.14z"/>
          </g>
        </g>
      </g>
      <g id="camisola_x5F_pescoco">
        <polygon
          class="st5" points="474.86,679.84 486.17,700.89 497.86,679.84"
          data-ref="camisola"
          :style="`fill: ${drawingColors.camisola}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="manga_x5F_esq">
        <path
          class="st5" d="M437.62,725.33c-2.22,6.04-2.47,7.31-2.56,7.81c-0.38,2.09-3.4,17.9-5.62,31.23c-0.18,1.07-3.29,0.74-3.5,1.65
          c-0.7,3.07-1.09,8.37-1.74,8.42c-7.26,0.48-16.44-0.97-21.92-4.3c-0.29-0.17-0.42-0.51-0.35-0.84c0.31-1.41,1.15-5.22,1.69-7.63
          c0.17-0.76-2.01-1.9-2.06-2.18c-0.73-4.11,5.38-31.68,7.4-36c9.37-20.03,15.36-31.11,20.26-36.7c5.61-6.4,9.1-6.8,12.18-7.03
          c-2.19,1.52-1.79,3.89-2.3,8.33C437.84,698.94,439.09,721.33,437.62,725.33z"
          data-ref="camisola"
          :style="`fill: ${drawingColors.camisola}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="manga_x5F_dta">
        <path
          class="st5" d="M533.43,725.08c2.22,6.04,2.47,7.31,2.56,7.81c0.38,2.09,3.4,17.9,5.62,31.23c0.18,1.07,3.29,0.74,3.5,1.65
          c0.7,3.07,1.09,8.37,1.74,8.42c7.26,0.48,16.44-0.97,21.92-4.3c0.29-0.17,0.42-0.51,0.35-0.84c-0.31-1.41-1.15-5.22-1.69-7.63
          c-0.17-0.76,2.01-1.9,2.06-2.18c0.73-4.11-5.38-31.68-7.4-36c-9.37-20.03-15.36-31.11-20.26-36.7c-5.61-6.4-9.1-6.8-12.18-7.03
          c2.19,1.52,1.79,3.89,2.3,8.33C533.21,698.69,531.97,721.08,533.43,725.08z"
          data-ref="camisola"
          :style="`fill: ${drawingColors.camisola}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mao_x5F_esq">
        <path
          class="st9" d="M423.15,774.49c-6.72,0.27-14.79-1.04-20.05-3.89c-0.91,3.23-0.6,11.59-0.6,11.59l1.53,11.59
          c0,0,5.25,11.81,6.78,12.25c1.53,0.44,1.93-1.4,1.93-1.4s0.37,0.96,2.23,0.53c1.86-0.44,1.64-2.84,1.64-2.84s-1.29-4.97-2.02-6.96
          c-0.68-1.83-0.67-7.4-0.17-8.58c0.88-2.03,2.73-3.72,2.73-3.72l4.16,4.59c0,0,1.09,2.3,3.72,1.42s1.42-3.5,1.42-3.5L423.15,774.49z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mao_x5F_dta">
        <path
          class="st9" d="M547.9,774.24l-3.3,11.09c0,0-1.2,2.62,1.42,3.5c2.63,0.88,3.72-1.42,3.72-1.42l4.16-4.59
          c0,0,1.86,1.69,2.73,3.72c0.5,1.17,0.51,6.74-0.17,8.58c-0.74,1.99-2.02,6.96-2.02,6.96s-0.22,2.41,1.64,2.84
          c1.86,0.44,2.23-0.53,2.23-0.53s0.4,1.84,1.93,1.4c1.53-0.44,6.78-12.25,6.78-12.25l1.53-11.59c0,0,0.31-8.36-0.6-11.59
          C562.68,773.2,554.62,774.51,547.9,774.24z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tracos_x5F_mao">
        <path class="st9" d="M566.14,785.54c0,0-0.88,0.66-1.09,1.42c-0.13,0.46-0.66,2.82-1.06,4.63c-0.32,1.44-0.8,2.83-1.45,4.15
          l-4.49,9.16"/>
        <path class="st9" d="M404.91,785.8c0,0,0.88,0.66,1.09,1.42c0.13,0.46,0.66,2.82,1.06,4.63c0.32,1.44,0.8,2.83,1.45,4.15l4.49,9.16
          "/>
        <path class="st5" d="M544.86,765.48c2.17,1.03,19.61-1.8,22.63-4.39"/>
        <path class="st5" d="M426.19,765.73c-2.17,1.03-19.61-1.8-22.63-4.39"/>
      </g>
      <g id="cabeca_x5F_pescoco">
        <polygon
          class="st15" points="505.05,676.39 499.6,676.39 496.24,682.51 476.25,682.51 472.99,676.39 467.67,676.39 467.67,670.68 505.05,670.68"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st15" d="M539.7,622.86c-1.79,0-3.42,0.64-4.73,1.72l0.43-10.58c-14.52-40.08-65.81-4-81.36-35.6
          c0,22.52-11.22,29.62-18.37,40.31l0.31,7.64c-1.4-2.11-3.71-3.49-6.33-3.49c-4.27,0-7.73,3.65-7.73,8.14c0,4.5,3.46,8.14,7.73,8.14
          c2.86,0,5.36-1.64,6.69-4.08l0.75,18.28c0,10.61,8.68,19.29,19.29,19.29h58.12c10.61,0,19.29-8.68,19.29-19.29l0.67-16.37
          c1.38,1.34,3.22,2.17,5.24,2.17c4.27,0,7.73-3.65,7.73-8.14C547.43,626.51,543.97,622.86,539.7,622.86z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="oculos">
        <path
          class="st9" d="M542.29,610.65h-7.53c-1.79-1.14-3.87-1.79-6.07-1.87l-23.45-0.83c-6.81-0.25-12.92,4.41-14.58,10.95h-8.45
          l-0.02-0.09c-1.3-7.01-7.68-12.12-14.79-11.85l-23.45,0.83c-2.2,0.08-4.28,0.73-6.07,1.87h-7.07v9.24h1.45
          c-0.14,1.58,0.04,3.2,0.55,4.78l3.42,10.62c1.99,6.2,7.71,10.36,14.22,10.36h10.43c5.79,0,11.27-2.79,14.68-7.47l4.35-5.97
          c1.44-1.97,2.34-4.28,2.65-6.67H490c0.17,2.74,1.1,5.41,2.74,7.66l4.35,5.97c3.41,4.68,8.89,7.47,14.68,7.47h10.43
          c6.51,0,12.22-4.16,14.22-10.36l3.42-10.62c0.51-1.57,0.68-3.19,0.55-4.78h1.91V610.65z M476.06,628.43l-4.35,5.97
          c-2.52,3.46-6.58,5.52-10.86,5.52h-10.43c-4.45,0-8.35-2.84-9.71-7.08l-3.42-10.62c-0.72-2.24-0.36-4.62,0.99-6.55
          c1.35-1.92,3.47-3.08,5.82-3.16l23.45-0.83c4.81-0.16,9.09,3.26,9.96,7.99l0.23,1.26C478.25,623.55,477.63,626.28,476.06,628.43z
          M535.33,623.22l-3.42,10.62c-1.36,4.23-5.27,7.08-9.71,7.08h-10.43c-4.28,0-8.34-2.06-10.85-5.52l-4.35-5.97
          c-1.57-2.15-2.19-4.88-1.7-7.5l0.23-1.26c0.86-4.61,4.96-8,9.62-8c0.11,0,0.23,0,0.34,0.01l23.45,0.83
          c2.35,0.08,4.47,1.24,5.82,3.16C535.68,618.59,536.05,620.98,535.33,623.22z"
          data-ref="oculos"
          :style="`fill: ${drawingColors.oculos}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="boneca_x5F_rosto">
        <g class="st2">
          <path
            class="st18" d="M485.38,658.39c-6.09,0-12.02-2.86-15.74-7.87c-0.78-1.06-0.56-2.55,0.49-3.34
            c1.06-0.78,2.55-0.56,3.34,0.49c3.6,4.85,9.94,7.04,15.77,5.43c3.2-0.88,6.04-2.85,7.99-5.53c0.78-1.07,2.27-1.3,3.33-0.52
            c1.07,0.78,1.3,2.27,0.52,3.33c-2.59,3.55-6.34,6.15-10.58,7.32C488.82,658.16,487.09,658.39,485.38,658.39z"
          />
        </g>
        <g class="st2">
          <path
            class="st18" d="M486.06,634.3c-2.5,0-4.94,1.18-6.47,3.24c-0.32,0.43-0.23,1.05,0.2,1.37c0.44,0.32,1.05,0.23,1.37-0.2
            c1.48-2,4.09-2.89,6.48-2.23c1.32,0.36,2.48,1.17,3.29,2.27c0.32,0.44,0.93,0.53,1.37,0.21c0.44-0.32,0.53-0.93,0.21-1.37
            c-1.06-1.46-2.61-2.53-4.35-3.01C487.47,634.39,486.76,634.3,486.06,634.3z"
          />
        </g>
        <g class="st2">
          <g>
            <path
              class="st4" d="M463.94,620.8c3.32,0,6.01,2.69,6.01,6.01c0,3.32-2.69,6.01-6.01,6.01c-3.32,0-6.01-2.69-6.01-6.01
              C457.94,623.49,460.63,620.8,463.94,620.8"
            />
          </g>
        </g>
        <g class="st2">
          <g>
            <path
              class="st4" d="M509.93,620.8c3.32,0,6.01,2.69,6.01,6.01c0,3.32-2.69,6.01-6.01,6.01c-3.32,0-6.01-2.69-6.01-6.01
              C503.92,623.49,506.61,620.8,509.93,620.8"
            />
          </g>
        </g>
      </g>
      <g id="cabeca">
        <path
          class="st1" d="M1178.47,258.9c-1.08,0.68-2.27,1.2-3.57,1.51c-1.54,0.36-3.08,0.4-4.55,0.17c-0.54,1.85-1.08,3.72-1.61,5.67
          c1.81,1.12,4.84,1.24,7.68,0.57c2.84-0.67,5.49-2.14,6.61-3.95C1180.99,262.21,1179.58,260.65,1178.47,258.9z"
          data-ref="skin2"
          :style="`fill: ${drawingColors.skin2}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1183.84,245.41c-0.15-0.62-0.35-1.22-0.58-1.79c0,0.01-0.01,0.01-0.01,0.02c0.03,0.12,0.07,0.24,0.1,0.36
          c0,0-0.14,0.05-0.36,0.13c-0.38,0.69-1.04,1.87-1.93,3.35c-0.86,1.42-3.32,0.46-8.8,1.75l0-0.01l0,0.01
          c-5.48,1.3-7.25,3.26-8.65,2.38c-1.44-0.91-2.55-1.66-3.2-2.11c-0.21,0.03-0.36,0.05-0.46,0.06c0.06,0.48,0.14,0.97,0.25,1.45
          c1.6,6.74,8.18,10.95,14.71,9.41C1181.44,258.86,1185.43,252.15,1183.84,245.41z"
          data-ref="skin2"
          :style="`fill: ${drawingColors.skin2}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="chapeu">
        <g>
          <path
            class="st1" d="M1178.87,232.74c-1.85-1.16-6.94-0.8-10.61,0.07s-8.38,2.82-9.51,4.69c-0.86,1.42-0.66,3.63,1.04,12.07
            c0,0,6.37-0.79,11.94-2.11c5.58-1.32,11.62-3.47,11.62-3.47C1181.09,235.69,1180.28,233.62,1178.87,232.74z"
            data-ref="chapeu"
            :style="`fill: ${drawingColors.chapeu}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1171.29,245.14c-6.5,1.54-11.54,3.89-11.54,3.89s1.48,1.08,3.86,2.57c1.4,0.88,3.17-1.08,8.65-2.38l0-0.01
            l0,0.01c5.48-1.3,7.94-0.34,8.8-1.75c1.45-2.4,2.3-4.03,2.3-4.03S1177.79,243.6,1171.29,245.14z"
            data-ref="chapeu"
            :style="`fill: ${drawingColors.chapeu}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="taco_x5F_02_00">
        <path
          class="st1" d="M1136.07,207.45c3.26-0.87,5.91-6.88,8.77-4.63l2.49,1.96l1.42-1.8l-2.49-1.96
          c-4.99-3.94-10.8-0.81-12.21,0.98C1132.56,203.88,1133.65,208.1,1136.07,207.45z"
          data-ref="taco_x5F_02_00"
          :style="`fill: ${drawingColors.taco_x5F_02_00}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="taco_x5F_pau">
          <rect
            x="1140.94" y="222.51" transform="matrix(-0.785 -0.6195 0.6195 -0.785 1954.6066 1124.8485)" class="st1" width="63.16" height="1.39"
            data-ref="taco_x5F_pau"
            :style="`fill: ${drawingColors.taco_x5F_pau}!important;`"
            @click="changeColor($event)"
          />
      </g>
      <g id="mao_x5F_01">
        <path
          class="st1" d="M1206.41,250.47c-0.81,0.54-2.51,1.88-4.59,3.58l4.52,4.58c2.03-2.08,3.7-3.83,4.46-4.72
          C1212.73,251.64,1209.3,248.54,1206.41,250.47z"
          data-ref="skin2"
          :style="`fill: ${drawingColors.skin2}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="taco_x5F_pega">
        <path
          class="st1" d="M1202.97,244.89l15.56,12.28c0.25,0.2,0.29,0.56,0.1,0.82l-1.57,1.99c-0.2,0.25-0.56,0.29-0.82,0.1
          l-15.56-12.28L1202.97,244.89z"
          data-ref="taco_x5F_pega"
          :style="`fill: ${drawingColors.taco_x5F_pega}!important;`"
          @click="changeColor($event)"
        />
        <polygon
          class="st1" points="1197.74,242.23 1202.97,244.89 1200.69,247.79 1196.88,243.32"
          data-ref="taco_x5F_pega"
          :style="`fill: ${drawingColors.taco_x5F_pega}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mao_x5F_02">
        <path
          class="st1" d="M1213.33,254.2c-0.67,0.71-1.36,1.69-3.01,3.81l4.62,3.92c1.51-2.48,2.75-3.8,3.29-4.84
          C1219.61,254.45,1215.72,251.67,1213.33,254.2z"
          data-ref="skin2"
          :style="`fill: ${drawingColors.skin2}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="blusa">
        <path
          class="st1" d="M1196.18,269.06l10.15-10.43l-5.01-5.09c-2.27,1.86-8.44,7.27-13.3,11.55
          C1190.85,266.43,1194.14,268.05,1196.18,269.06z"
          data-ref="blusa"
          :style="`fill: ${drawingColors.blusa}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1210.33,258.01c-5.18,5.01-12.18,12.02-12.18,12.02s-12.78-6.39-15.12-7.17c-1.12,1.81-3.77,3.28-6.61,3.95
          c-2.84,0.67-5.87,0.55-7.68-0.57c-1.74,14.3,2.04,47.99,2.04,47.99l30.25-2.71l-3.57-31.34c0,0,2.3,0.31,3.22-0.59
          c2.89-2.79,9.61-10.93,14.85-17.17L1210.33,258.01z"
          data-ref="blusa"
          :style="`fill: ${drawingColors.blusa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="traco_x5F_blusa">
        <path class="st1" d="M1197.46,280.19c0,0-19.68-3.14-21.58-3.45"/>
      </g>
      <g id="calca">
        <path
          class="st1" d="M1185.96,341.77l0.25-20.21l7.46,47.73c0.57-0.39,3.12,2.46,5.11,2.38c1.4-0.06,2.05-1.45,2.61-1.96
          c0.24-0.22,0.69-0.17,0.78,0.01l-1.13-58.19l-30.23,2.71l1.49,28.06l-4.61,19.37c-1.11,5.53,5.91,5.55,7.25,1.87l10.34-19.14
          C1185.71,343.6,1185.95,342.69,1185.96,341.77z"
          data-ref="calca2"
          :style="`fill: ${drawingColors.calca2}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="pala_x5F_sapato">
        <path
          class="st1" d="M1192.78,370.01c0,0,0.86-2.94,1.33-3.07c0.5-0.14,1.19,3.38,1.19,3.38L1192.78,370.01z"
          data-ref="pala_x5F_sapato"
          :style="`fill: ${drawingColors.pala_x5F_sapato}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sola">
        <path
          class="st1" d="M1202.56,373.75c-0.02,0.8-0.06,1.39-0.06,1.39s-6.31,1.48-11.69,1.3c-6.89-0.23-7.62-1.73-7.62-1.73
          s-0.07-0.02-0.04-0.21c-0.06-0.03-0.13,0.05-0.11,0.7c0.02,0.62,0.16,0.76,0.27,0.83c2.34,1.46,4.48,1.77,6.95,1.77
          c1.82,0,5.39,0,8.12,0c1.57,0,4.22,0,4.22,0C1203.6,377.81,1202.91,374.25,1202.56,373.75z"
          data-ref="sola2"
          :style="`fill: ${drawingColors.sola2}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sapato_x5F_dto">
        <path
          class="st1" d="M1202.5,375.14c0,0,0.28-3.99-0.34-5.42c-0.08-0.19-0.53-0.24-0.78-0.01c-0.56,0.51-1.21,1.9-2.61,1.96
          c-2.01,0.08-4.6-2.84-5.13-2.37c-0.92,0.81-2.76,2.25-5.29,3.03c-2.11,0.65-4.05,0.87-4.66,1.14c-0.47,0.21-0.51,0.8-0.54,1.03
          c-0.03,0.19,0.04,0.21,0.04,0.21s0.74,1.5,7.62,1.73C1196.19,376.62,1202.5,375.14,1202.5,375.14z"
          data-ref="sapato_x5F_dto"
          :style="`fill: ${drawingColors.sapato_x5F_dto}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sapato_x5F_esq">
        <path
          class="st1" d="M1174.93,363.54c-1.34,3.67-8.36,3.66-7.25-1.87l0.24-1.01l-0.07-0.03c0,0-1.91,3.17-1.91,4.6
          c0,1.43-0.01,12.57,2.71,12.57c1.48,0,4.07,0,4.96,0C1175.8,377.81,1174.93,363.54,1174.93,363.54L1174.93,363.54z"
          data-ref="sapato_x5F_esq"
          :style="`fill: ${drawingColors.sapato_x5F_esq}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="pin">
        <g>
          <path
            class="st1" d="M1240.14,372.23h-2.04c0.25,0.65,0.91,1.3,1.16,2.08c0.32,1.03,0.35,3.34,0.35,3.34s0.25,0.15,0.53,0.15
            c0.28,0,0.53-0.15,0.53-0.15s0.03-2.31,0.35-3.34c0.25-0.78,0.92-1.43,1.16-2.08H1240.14z"
            data-ref="pin"
            :style="`fill: ${drawingColors.pin}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1242.18,372.23c0,0.17-0.91,0.31-2.04,0.31c-1.13,0-2.04-0.14-2.04-0.31c0-0.17,0.91-0.31,2.04-0.31
            C1241.27,371.92,1242.18,372.06,1242.18,372.23z"
            data-ref="pin"
            :style="`fill: ${drawingColors.pin}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="bola">
        <circle
          class="st1" cx="1240.14" cy="369.25" r="3.69"
          data-ref="bola"
          :style="`fill: ${drawingColors.bola}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_03_x5F_dta">
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 79.5042 877.7289)" class="st1" cx="1099.26" cy="342.89" rx="15.21" ry="15.21"
            data-ref="roda_d_2"
            :style="`fill: ${drawingColors.roda_d_2}!important;`"
            @click="changeColor($event)"
          />
      </g>
      <g id="roda_x5F_02_x5F_dta">
        <path
          class="st1" d="M1105.14,337.01c3.25,3.25,3.25,8.51,0,11.76c-3.25,3.25-8.51,3.25-11.76,0s-3.25-8.51,0-11.76
          C1096.63,333.77,1101.9,333.77,1105.14,337.01z"
          data-ref="roda_d_3"
          :style="`fill: ${drawingColors.roda_d_3}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_01_x5F_dta">
        <path
          class="st1" d="M1095.25,342.89c0,2.21,1.8,4.01,4.01,4.01c2.21,0,4.01-1.8,4.01-4.01c0-2.21-1.8-4.01-4.01-4.01
          C1097.05,338.88,1095.25,340.68,1095.25,342.89z"
          data-ref="roda_d_4"
          :style="`fill: ${drawingColors.roda_d_4}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_03_x5F_esq">
          <ellipse
            transform="matrix(0.6584 -0.7527 0.7527 0.6584 81.3896 865.1101)" class="st1" cx="993.72" cy="342.89" rx="15.21" ry="15.21"
            data-ref="roda_d_2"
            :style="`fill: ${drawingColors.roda_d_2}!important;`"
            @click="changeColor($event)"
          />
      </g>
      <g id="roda_x5F_02_x5F_esq">
        <path
          class="st1" d="M999.6,337.01c3.25,3.25,3.25,8.51,0,11.76c-3.25,3.25-8.51,3.25-11.76,0s-3.25-8.51,0-11.76
          C991.09,333.77,996.35,333.77,999.6,337.01z"
          data-ref="roda_d_3"
          :style="`fill: ${drawingColors.roda_d_3}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_01_x5F_esq">
        <path
          class="st1" d="M989.71,342.89c0,2.21,1.8,4.01,4.01,4.01c2.21,0,4.01-1.8,4.01-4.01c0-2.21-1.8-4.01-4.01-4.01
          C991.51,338.88,989.71,340.68,989.71,342.89z"
          data-ref="roda_d_4"
          :style="`fill: ${drawingColors.roda_d_4}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_01">
        <path
          class="st1" d="M1105.2,322.24c-6.79-1.85-21.17-2.21-28.13,1.69c0,0-6.71,18.06-7.96,18.06c-3.79,0-37.09,0-48.09,0
          c-1.43,0-2.63-1.08-2.78-2.5c-1.15-10.68-11.31-20.12-20.46-20.12c-9.09,0-21.89,0-21.89,0c-1.08,1.56-4.56,18.33-4.56,20.09
          c0,1.47,4.83,1.46,5.37,1.09c1.15-8.37,8.33-14.85,17.02-14.85c9.48,0,17.2,7.71,17.2,17.2c0,0.62-0.04,1.23-0.1,1.83
          c0.69,0.69,1.71,0.91,2.26,0.91h67.48c0,0,0.62-9.09,3.51-13.24c3.67-5.27,8.61-8.06,13.67-8.06h8.15
          C1105.88,324.35,1106.04,322.47,1105.2,322.24z"
          data-ref="carro_x5F_01"
          :style="`fill: ${drawingColors.carro_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_02">
        <path
          class="st1" d="M1018.24,339.49c0.15,1.42,1.35,2.5,2.78,2.5c5.11,0,15.03,0,24.57,0V316.1h-60.56
          c-1.93,0-3.73,0.97-4.79,2.58l-0.46,0.69c4.5,0,12.05,0,17.99,0C1006.93,319.37,1017.09,328.81,1018.24,339.49z"
          data-ref="carro_x5F_02"
          :style="`fill: ${drawingColors.carro_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_03">
        <path
          class="st1" d="M1104.19,322c-3.14-8.52-9.27-12.16-20.99-14.29l-6.13,16.23C1083.71,320.21,1097.08,320.37,1104.19,322z"
          data-ref="carro_x5F_03"
          :style="`fill: ${drawingColors.carro_x5F_03}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_04">
        <polygon
          class="st1" points="1082.69,309.06 1056.55,285.28 1055.38,286.56 1082.03,310.81"
          data-ref="carro_x5F_04"
          :style="`fill: ${drawingColors.carro_x5F_04}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_05">
        <path
          class="st1" d="M1061.75,279.57l-11.56,12.7c-0.17,0.19-0.46,0.2-0.64,0.03l-1.04-0.95c-0.19-0.17-0.2-0.46-0.03-0.64
          l11.56-12.7c0.17-0.19,0.46-0.2,0.64-0.03l1.04,0.95C1061.9,279.1,1061.92,279.38,1061.75,279.57z"
          data-ref="carro_x5F_05"
          :style="`fill: ${drawingColors.carro_x5F_05}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_06">
        <path
          class="st1" d="M1085.38,308.13l-20.68-50.32h-2.01l20.51,49.89C1083.95,307.84,1084.68,307.98,1085.38,308.13z"
          data-ref="carro_x5F_06"
          :style="`fill: ${drawingColors.carro_x5F_06}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_07">
        <path
          class="st1" d="M1071.95,250.35h-88.03c-1.11,0-2,0.9-2,2v4.86c0,0.33,0.27,0.59,0.59,0.59h90.85c0.33,0,0.59-0.27,0.59-0.59 v-4.86C1073.95,251.25,1073.05,250.35,1071.95,250.35z"
          data-ref="carro_x5F_07"
          :style="`fill: ${drawingColors.carro_x5F_07}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_08">
        <rect
          x="1012.69" y="257.81" class="st1" width="3.3" height="48.92"
          data-ref="carro_x5F_08"
          :style="`fill: ${drawingColors.carro_x5F_08}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_09">
        <path
          class="st1" d="M1046.14,309.63h-16.78l-6.14-15.63c-0.65-1.66-2.53-2.48-4.19-1.83c-1.66,0.65-2.48,2.53-1.83,4.19l7.74,19.7 v0.03h21.2c1.79,0,3.23-1.45,3.23-3.23C1049.37,311.08,1047.93,309.63,1046.14,309.63z"
          data-ref="carro_x5F_09"
          :style="`fill: ${drawingColors.carro_x5F_09}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_10">
        <polygon
          class="st1" points="990.79,306.73 1021.28,306.73 1024.95,316.1 990.79,316.1"
          data-ref="carro_x5F_09"
          :style="`fill: ${drawingColors.carro_x5F_09}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="taco_x5F_01">
        <path
          class="st1" d="M990.18,270.94c1.79,1.22,5.99,0.23,5.99,2.43v1.91h1.51v-1.91c0-3.84-4-5.42-5.5-5.43 C990.6,267.94,988.85,270.03,990.18,270.94z"
          data-ref="taco_x5F_01"
          :style="`fill: ${drawingColors.taco_x5F_01}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="996.47" y="275.04" class="st1" width="0.92" height="31.69"
          data-ref="taco_x5F_01"
          :style="`fill: ${drawingColors.taco_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="taco_x5F_02">
        <path
          class="st1" d="M999.48,270.94c1.79,1.22,5.99,0.23,5.99,2.43v1.91h1.51v-1.91c0-3.84-4-5.42-5.5-5.43 C999.89,267.94,998.15,270.03,999.48,270.94z"
          data-ref="taco_x5F_02"
          :style="`fill: ${drawingColors.taco_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1005.76" y="275.04" class="st1" width="0.92" height="31.69"
          data-ref="taco_x5F_02"
          :style="`fill: ${drawingColors.taco_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tee">
        <path
          class="st1" d="M1445.53,461.53c-36.31-2.88-124.36,6.2-162.59,11.07s-92.55,11.95-85.95,35.62
          c6.6,23.67,77.8,28.41,122.57,22.27c64.16-8.8,53.61-1.58,102.55,0c48.94,1.58,71.88-19.93,82.9-38.81
          S1479.69,464.23,1445.53,461.53z M1467.05,489.41c-2.34,0-4.24-0.49-4.24-1.1s1.9-1.1,4.24-1.1s4.24,0.49,4.24,1.1
          S1469.39,489.41,1467.05,489.41z"
          data-ref="tee"
          :style="`fill: ${drawingColors.tee}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="pau">
        <rect
          x="1476.35" y="391.86" class="st1" width="2.29" height="92.98"
          data-ref="pau"
          :style="`fill: ${drawingColors.pau}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="bandeira">
        <polygon
          class="st1" points="1477.49,394.62 1477.49,410.37 1495.45,410.37"
          data-ref="bandeira"
          :style="`fill: ${drawingColors.bandeira}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_07">
        <path
          class="st1" d="M798.4,223.7c-29.78,0-53.92,24.15-53.92,53.93c0,28.72,22.43,52.17,50.72,53.83v-30.74l-7.07-7.07l-20.54,9
          l-1.28-2.95l19.38-8.5l-20.19-20.19l2.28-2.27l27.43,27.42v-21.75l-19.19-19.2l2.27-2.28l16.92,16.92v-24.11h6.44v13.6l11.17-11.17
          l2.28,2.27l-13.45,13.45v46.72l10.9-8.16v-39.24h3.22v14.66l12.58-12.59l2.27,2.28l-14.85,14.84v17.64l16.85-12.61l1.94,2.58
          l-32.91,24.63v16.81c28.27-1.68,50.68-25.12,50.68-53.82C852.33,247.86,828.19,223.7,798.4,223.7z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_07">
        <polygon
          class="st1" points="834.55,290.01 832.61,287.43 815.76,300.05 815.76,282.41 830.62,267.57 828.34,265.29 815.76,277.88 815.76,263.21 812.54,263.21 812.54,302.45 801.64,310.61 801.64,263.89 815.1,250.44 812.82,248.17 801.64,259.34 801.64,245.74 795.21,245.74 795.21,269.85 778.29,252.93 776.02,255.22 795.21,274.41 795.21,296.16 767.78,268.74 765.5,271.01 785.69,291.2 766.31,299.69 767.59,302.64 788.13,293.64 795.21,300.71 795.21,439.69 801.64,439.69 801.64,314.64"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_06">
        <path
          class="st1" d="M737.44,320.86c-25.27,0-45.75,20.5-45.75,45.77c0,24.05,18.56,43.76,42.14,45.61v-3.63l-25.23-25.2l2.27-2.28
          l12.08,12.07v-17.38h3.22v20.5h-0.09l7.75,7.74v-33.43l-5.08-5.08v0.01h-14.47v-3.22h11.25l-9.83-9.83l2.27-2.27l15.84,15.83v-18.9
          h4.83v11.98l10.16-10.17l2.28,2.28l-12.44,12.44v26.52l14.06-14.05h-0.08v-15.13h3.22v11.99l6.6-6.6l2.27,2.28l-12.95,12.94
          l13.92,0.01v3.22l-17.13-0.01l-9.9,9.9v17.59c24.72-0.65,44.55-20.88,44.55-45.74C783.21,341.36,762.72,320.86,737.44,320.86z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_06">
        <polygon
          class="st1" points="765.69,384.88 765.69,381.67 751.77,381.65 764.72,368.72 762.45,366.43 755.85,373.03 755.85,361.04 752.64,361.04 752.64,376.17 752.72,376.17 738.66,390.22 738.66,363.71 751.1,351.27 748.82,348.99 738.66,359.16 738.66,347.18 733.83,347.18 733.83,366.08 717.98,350.25 715.71,352.52 725.54,362.35 714.28,362.35 714.28,365.57 728.75,365.57 728.75,365.55 733.83,370.63 733.83,404.06 726.08,396.32 726.17,396.32 726.17,375.82 722.95,375.82 722.95,393.19 710.87,381.12 708.6,383.41 733.83,408.6 733.83,446.38 738.66,446.38 738.66,394.77 748.56,384.87"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_05">
        <path
          class="st1" d="M667.05,231.04c-29.78,0-53.92,24.15-53.92,53.93c0,28.72,22.43,52.17,50.72,53.83v-30.74l-7.07-7.07l-20.54,9
          l-1.28-2.95l19.38-8.5l-20.19-20.19l2.28-2.27l27.43,27.42v-21.75l-19.19-19.2l2.27-2.28l16.92,16.92v-24.11h6.44v13.6l11.17-11.17
          l2.28,2.27l-13.45,13.45v46.72l10.9-8.16v-39.24h3.22v14.66l12.58-12.59l2.27,2.28l-14.85,14.84v17.64l16.85-12.61l1.94,2.58
          l-32.91,24.63v16.81c28.27-1.68,50.68-25.12,50.68-53.82C720.98,255.19,696.84,231.04,667.05,231.04z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_05">
        <polygon
          class="st1" points="703.2,297.35 701.26,294.77 684.41,307.38 684.41,289.75 699.27,274.91 696.99,272.63 684.41,285.21 684.41,270.55 681.2,270.55 681.2,309.79 670.29,317.95 670.29,271.23 683.75,257.77 681.47,255.51 670.29,266.68 670.29,253.08 663.86,253.08 663.86,277.19 646.94,260.27 644.67,262.55 663.86,281.75 663.86,303.5 636.43,276.08 634.15,278.34 654.34,298.53 634.96,307.03 636.24,309.98 656.78,300.98 663.86,308.05 663.86,447.03 670.29,447.03 670.29,321.98"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_04">
        <path
          class="st1" d="M595.03,346.22c-22.78,0-41.23,18.18-41.23,40.62c0,21.34,16.72,38.83,37.96,40.47v-0.47l-22.72-25.2
          l2.05-2.27l10.88,12.07v-17.39h2.9v20.5h-0.09l6.98,7.75v-33.44l-4.57-5.08v0.03l-13.03-0.01v-3.22h10.15l-8.86-9.82l2.04-2.28
          l14.27,15.83v-18.9h4.36v11.97l9.16-10.15l2.06,2.27l-11.21,12.44v26.53l12.67-14.07h-0.07v-15.13h2.9v11.99l5.94-6.59l2.05,2.27
          l-11.66,12.95h12.53v3.22h-15.44l-8.92,9.9v14.4c22.27-0.57,40.14-18.52,40.14-40.58C636.26,364.4,617.81,346.22,595.03,346.22z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_04">
        <polygon
          class="st1" points="620.47,403.12 620.47,399.9 607.94,399.9 619.61,386.95 617.56,384.68 611.62,391.26 611.62,379.28 608.72,379.28 608.72,394.4 608.79,394.4 596.12,408.47 596.12,381.94 607.33,369.5 605.28,367.23 596.12,377.39 596.12,365.41 591.76,365.41 591.76,384.31 577.49,368.48 575.45,370.76 584.3,380.58 574.16,380.58 574.16,383.8 587.19,383.81 587.19,383.78 591.76,388.86 591.76,422.3 584.79,414.55 584.87,414.55 584.87,394.05 581.97,394.05 581.97,411.44 571.09,399.37 569.04,401.64 591.76,426.84 591.76,438.2 596.12,438.2 596.12,413.01 605.04,403.12"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_03">
        <path
          class="st1" d="M522.14,240.25c-29.78,0-53.92,24.15-53.92,53.93c0,28.72,22.43,52.17,50.72,53.83v-30.74l-7.07-7.07l-20.54,9
          l-1.28-2.95l19.38-8.5l-20.19-20.19l2.28-2.27l27.43,27.43v-21.75l-19.19-19.2l2.27-2.28l16.92,16.92v-24.11h6.44v13.6l11.17-11.17
          l2.28,2.27l-13.45,13.45v46.72l10.9-8.16v-39.24h3.22v14.66l12.58-12.59l2.27,2.28l-14.85,14.84v17.64l16.85-12.61l1.94,2.58
          l-32.91,24.63V348c28.27-1.68,50.68-25.12,50.68-53.82C576.07,264.41,551.92,240.25,522.14,240.25z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_03">
        <polygon
          class="st1" points="558.29,306.56 556.35,303.98 539.5,316.6 539.5,298.96 554.35,284.12 552.08,281.84 539.5,294.43 539.5,279.76 536.28,279.76 536.28,319 525.38,327.16 525.38,280.44 538.84,266.99 536.56,264.72 525.38,275.89 525.38,262.29 518.95,262.29 518.95,286.4 502.03,269.48 499.75,271.77 518.95,290.96 518.95,312.71 491.52,285.29 489.24,287.55 509.43,307.74 490.05,316.24 491.33,319.19 511.87,310.19 518.95,317.26 518.95,456.24 525.38,456.24 525.38,331.19"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_02">
        <path
          class="st1" d="M531.26,414.14c-22.78,0-41.23,18.18-41.23,40.62c0,21.34,16.72,38.83,37.96,40.47v-0.47l-22.72-25.2
          l2.05-2.27l10.88,12.07v-17.39h2.9v20.5h-0.09l6.98,7.75v-33.44l-4.57-5.08v0.03l-13.03-0.01v-3.22h10.15l-8.86-9.82l2.04-2.28
          l14.27,15.83v-18.9h4.36v11.97l9.16-10.15l2.06,2.27l-11.21,12.44v26.53l12.67-14.07h-0.07v-15.13h2.9v11.99l5.94-6.59l2.05,2.27
          l-11.66,12.95h12.53v3.22h-15.44l-8.92,9.9v14.4c22.27-0.57,40.14-18.52,40.14-40.58C572.49,432.32,554.04,414.14,531.26,414.14z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_02">
        <polygon
          class="st1" points="556.7,471.03 556.7,467.82 544.17,467.82 555.83,454.86 553.79,452.59 547.85,459.18 547.85,447.19 544.95,447.19 544.95,462.32 545.01,462.32 532.35,476.38 532.35,449.85 543.56,437.42 541.5,435.15 532.35,445.3 532.35,433.33 527.99,433.33 527.99,452.23 513.72,436.4 511.68,438.68 520.53,448.49 510.38,448.49 510.38,451.71 523.42,451.73 523.42,451.7 527.99,456.78 527.99,490.22 521.01,482.47 521.1,482.47 521.1,461.96 518.2,461.96 518.2,479.36 507.32,467.29 505.27,469.55 527.99,494.75 527.99,506.12 532.35,506.12 532.35,480.93 541.27,471.03"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="copa_x5F_01">
        <path
          class="st1" d="M452.46,365.59c-25.27,0-45.75,20.5-45.75,45.77c0,24.05,18.56,43.76,42.14,45.61v-3.63l-25.23-25.2l2.27-2.28
          l12.08,12.07v-17.38h3.22v20.5h-0.09l7.75,7.74v-33.43l-5.08-5.08v0.01H429.3v-3.22h11.25l-9.83-9.83l2.27-2.27l15.84,15.83v-18.9
          h4.83v11.98l10.16-10.17l2.28,2.28l-12.44,12.44v26.52l14.06-14.05h-0.08v-15.12h3.22v11.99l6.6-6.6l2.27,2.28l-12.95,12.94
          l13.92,0.01v3.22l-17.13-0.01l-9.9,9.9v17.59c24.72-0.65,44.55-20.88,44.55-45.74C498.22,386.09,477.74,365.59,452.46,365.59z"
          data-ref="copa"
          :style="`fill: ${drawingColors.copa}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco_x5F_01">
        <polygon
          class="st1" points="480.71,429.62 480.71,426.4 466.79,426.39 479.74,413.45 477.47,411.17 470.87,417.77 470.87,405.78 467.65,405.78 467.65,420.9 467.73,420.9 453.67,434.95 453.67,408.44 466.12,396 463.84,393.72 453.67,403.89 453.67,391.91 448.84,391.91 448.84,410.81 433,394.98 430.72,397.25 440.55,407.08 429.3,407.08 429.3,410.3 443.76,410.3 443.76,410.28 448.84,415.36 448.84,448.79 441.1,441.05 441.19,441.05 441.19,420.55 437.97,420.55 437.97,437.93 425.89,425.86 423.61,428.14 448.84,453.34 448.84,491.11 453.67,491.11 453.67,439.5 463.57,429.61"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="arbusto_x5F_02">
        <path
          class="st1" d="M579.88,468.59h67.59c0,0,5.45-17.75-11.39-19.89c0,0-6.27-0.08-11.13,3.79c0,0,0.9-12.18-7.76-13.94 c-9.74-1.98-17,3.39-15.84,18.08c0,0-4.39-2.73-11.65-0.75C582.45,457.86,579.88,468.59,579.88,468.59z"
          data-ref="arbusto_x5F_02"
          :style="`fill: ${drawingColors.arbusto_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="arbusto_x5F_01">
        <path
          class="st1" d="M464.7,503.79h-82.4c0,0-7.09-34.55,17.04-37.52c21.25-2.62,25.62,12.96,25.21,16.79c0,0,5.82-9.1,17.83-5.14 c9.04,2.98,7.12,13.47,7.12,13.47s5.82-3.57,12.69,0.92C468.66,496.52,464.7,503.79,464.7,503.79z"
          data-ref="arbusto_x5F_01"
          :style="`fill: ${drawingColors.arbusto_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="nuvem_x5F_01">
        <path
          class="st1" d="M1194.82,95.91c-0.43-9.95-6.41-18.47-14.95-22.5c0.13-1.24,0.22-2.5,0.22-3.81
          c0-19.43-15.78-35.23-35.24-35.23c-12.73,0-23.84,6.77-30.03,16.9c-0.78-0.37-1.57-0.67-2.38-0.94
          c-5.8-13.55-19.25-23.05-34.9-23.05c-13.74,0-25.71,7.3-32.38,18.21c-2.97-1.41-6.3-2.21-9.8-2.21c-12.68,0-22.98,10.3-22.98,22.95
          c0,0.82,0.05,1.62,0.13,2.42c-10.22,4.05-17.48,13.94-17.75,25.54c0,0.21-0.05,0.42-0.05,0.63c0,0.38,0.08,0.73,0.1,1.1H1194.82z"
          data-ref="nuvem_x5F_01"
          :style="`fill: ${drawingColors.nuvem_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="nuvem_x5F_02">
        <path
          class="st1" d="M1202.65,176.12c0-0.22,0.06-0.44,0.06-0.67c0-0.16-0.04-0.32-0.04-0.47c-0.25-8.79-7.28-16.25-17.14-19.33
          c0.08-0.59,0.12-1.2,0.12-1.82c0-9.58-9.94-17.37-22.2-17.37c-3.39,0-6.59,0.61-9.47,1.67c-6.44-8.25-18.02-13.77-31.26-13.77
          c-20.25,0-36.68,12.88-36.68,28.73c0,1.05,0.08,2.08,0.24,3.1c-9.06,3.34-15.38,10.46-15.61,18.77c0,0.18-0.06,0.34-0.06,0.5
          c0,0.23,0.06,0.44,0.08,0.67H1202.65z"
          data-ref="nuvem_x5F_02"
          :style="`fill: ${drawingColors.nuvem_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </svg>
  </div>
  <div class="o-Drawing__colors">
    <div class="o-Colors">
      <a
        v-for="(color, index) in COLORS"
        :key="index"
        href="#"
        class="o-Color"
        :style="`background-color:${color};`"
        @click.prevent="selectColor(color)"
      />
    </div>
    <button
      class="o-Drawing__action"
      @click.prevent="saveDrawing"
    >
      Guardar
    </button>
    <button
      class="o-Drawing__action"
      @click.prevent="clearDrawing"
    >
      Limpar
    </button>
  </div>
</div>
</template>

<script>
import COLORS from '@/enums';

const { saveSvgAsPng } = require('save-svg-as-png');

export default {
  name: 'Recicle',
  data: () => ({
    COLORS,
    selectedColor: COLORS.WHITE,
    drawingColors: {},
  }),
  mounted() {
    this.drawingColors = JSON.parse(this.$store.state.aquaDrawing);
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
    },
    changeColor(e) {
      this.$set(this.drawingColors, e.target.getAttribute('data-ref'), this.selectedColor);
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify(this.drawingColors),
      });
    },
    saveDrawing() {
      try {
        saveSvgAsPng(document.getElementById('drawing'), 'drawing.png');
      } catch (err) {
        console.log('Error trying to download image');
      }
    },
    clearDrawing() {
      Object.keys(this.drawingColors).forEach((key) => {
        this.$set(this.drawingColors, key, COLORS.WHITE);
      });
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify({}),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#FFFFFF;stroke:#221F1F;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st2{opacity:0.3;}
.st3{fill:#221F1F;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#221F1F;}
.st5{fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
.st6{opacity:0.2;fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st7{fill:#BCBDBF;}
.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#BCBDBF;}
.st9{fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st10{fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.2;stroke-miterlimit:10;stroke-dasharray:1;}
.st11{fill:#FFFFFF;stroke:#231F20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

.st12{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st13{opacity:0.8;fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:1;}
.st14{fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2;}
.st15{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
.st16{fill:#A7A8AB;}
.st17{fill-rule:evenodd;clip-rule:evenodd;fill:#A7A8AB;}
.st18{fill:#231F20;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
</style>
