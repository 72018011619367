<template>
<div class="o-Drawing">
  <div class="o-Drawing__svg">
    <svg id="drawing" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
    <g id="background">
      <rect
        y="0" class="st0" width="1920" height="1080"
        data-ref="background"
        :style="`fill: ${drawingColors.background}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="monte">
      <path
        class="st1" d="M-0.12,516.09l279.51-130.53c0,0,260.68-111.44,566.8-1.51C967.57,427.64,1847.07,571.09,1920,632.14V1080H0 L-0.12,516.09z"
        data-ref="monte"
        :style="`fill: ${drawingColors.monte}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="sol">
      <g>
        <ellipse
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 282.4338 1007.5367)" class="st1" cx="1357.42" cy="162.84" rx="65.1" ry="65.1"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1421.69,125.73L1421.69,125.73c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7c2.54-1.46,5.78-0.6,7.24,1.94l0,0 c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7C1426.4,129.14,1423.16,128.27,1421.69,125.73z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1421.69,199.95L1421.69,199.95c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7c2.54,1.46,3.4,4.71,1.94,7.24l0,0 c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7C1421.1,205.73,1420.23,202.48,1421.69,199.95z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1357.42,237.06L1357.42,237.06c2.93,0,5.3,2.37,5.3,5.3v23.4c0,2.93-2.37,5.3-5.3,5.3l0,0 c-2.93,0-5.3-2.37-5.3-5.3v-23.4C1352.12,239.43,1354.49,237.06,1357.42,237.06z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1293.15,199.95L1293.15,199.95c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7c-2.54,1.46-5.78,0.6-7.24-1.94l0,0 c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7C1288.44,196.54,1291.69,197.41,1293.15,199.95z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1293.15,125.73L1293.15,125.73c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7c-2.54-1.46-3.4-4.71-1.94-7.24 l0,0c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7C1293.74,119.96,1294.61,123.2,1293.15,125.73z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1357.42,88.63L1357.42,88.63c-2.93,0-5.3-2.37-5.3-5.3v-23.4c0-2.93,2.37-5.3,5.3-5.3l0,0 c2.93,0,5.3,2.37,5.3,5.3v23.4C1362.72,86.25,1360.35,88.63,1357.42,88.63z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1320.31,98.57L1320.31,98.57c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05c-1.46-2.54-0.6-5.78,1.94-7.24l0,0 c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05C1323.72,93.86,1322.85,97.1,1320.31,98.57z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1283.21,162.84L1283.21,162.84c0,2.93-2.37,5.3-5.3,5.3h-15.07c-2.93,0-5.3-2.37-5.3-5.3l0,0 c0-2.93,2.37-5.3,5.3-5.3h15.07C1280.83,157.54,1283.21,159.91,1283.21,162.84z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1320.31,227.11L1320.31,227.11c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05c-1.46,2.54-4.71,3.4-7.24,1.94l0,0 c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05C1314.54,226.52,1317.78,225.65,1320.31,227.11z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1394.53,227.11L1394.53,227.11c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05c1.46,2.54,0.6,5.78-1.94,7.24l0,0 c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05C1391.12,231.82,1391.99,228.58,1394.53,227.11z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1431.64,162.84L1431.64,162.84c0-2.93,2.37-5.3,5.3-5.3H1452c2.93,0,5.3,2.37,5.3,5.3l0,0 c0,2.93-2.37,5.3-5.3,5.3h-15.07C1434.01,168.14,1431.64,165.77,1431.64,162.84z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1394.53,98.57L1394.53,98.57c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05c1.46-2.54,4.71-3.4,7.24-1.94l0,0 c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05C1400.31,99.16,1397.06,100.03,1394.53,98.57z"
          data-ref="sol"
          :style="`fill: ${drawingColors.sol}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="rosto_x5F_sol">
      <g class="st2">
        <path class="st3" d="M1345.52,209.22c-7.34,0-14.48-3.45-18.97-9.49c-0.84-1.13-0.6-2.73,0.53-3.57c1.13-0.84,2.73-0.6,3.57,0.53
          c4.5,6.06,12.41,8.78,19.69,6.78c4-1.1,7.54-3.56,9.98-6.9c0.83-1.14,2.43-1.39,3.57-0.56c1.14,0.83,1.39,2.43,0.56,3.57
          c-3.12,4.28-7.65,7.41-12.75,8.82C1349.66,208.95,1347.58,209.22,1345.52,209.22z"/>
      </g>
      <g class="st2">
        <path class="st4" d="M1326.36,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
          C1319,166.87,1322.29,163.58,1326.36,163.58"/>
      </g>
      <g class="st2">
        <path class="st4" d="M1364.62,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
          C1357.27,166.87,1360.56,163.58,1364.62,163.58"/>
      </g>
    </g>
    <g id="nuvem_x5F_01">
      <path
        class="st1" d="M890.04,173.28h-0.61c0.53-1.57,0.82-8.24,0.82-10.41c0-13.45-10.9-26.42-24.36-26.42
        c-2.55,0-5.01-0.64-7.32,0.09c-4.99-19.64-22.79-34.68-43.98-34.68c-21.26,0-39.1,14.36-44.02,34.1c-2.47-0.85-5.12-1.44-7.88-1.44
        c-8.24,0-15.52,4.03-19.93,10.3c-5.02-7.58-13.62-12.62-23.4-12.62c-15.49,0-28.05,16.67-28.05,32.16c0,1.64,0.15,7.37,0.42,8.93
        h-7.65c-8.26,0-14.96,6.96-14.96,14.89c0,7.93,6.7,14.89,14.96,14.89h205.97c8.26,0,14.96-6.96,14.96-14.89
        C905,180.24,898.3,173.28,890.04,173.28z"
        data-ref="nuvem"
        :style="`fill: ${drawingColors.nuvem}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="nuvem_x5F_02">
      <path
        class="st1" d="M792.23,215.68h-0.43c0.37-1.1,0.58-6.25,0.58-7.77c0-9.42-7.64-18.74-17.06-18.74c-1.78,0-3.5-0.57-5.12-0.06
        c-3.5-13.75-15.96-24.35-30.8-24.35c-14.89,0-27.38,10.03-30.83,23.85c-1.73-0.59-3.59-1.02-5.52-1.02
        c-5.77,0-10.87,2.82-13.96,7.21c-3.51-5.31-9.54-8.85-16.39-8.85c-10.85,0-19.64,12.15-19.64,23c0,1.15,0.11,5.64,0.3,6.74H648
        c-5.79,0-10.48,4.33-10.48,9.88c0,5.56,4.69,9.88,10.48,9.88h144.23c5.79,0,10.48-4.32,10.48-9.88
        C802.71,220.01,798.02,215.68,792.23,215.68z"
        data-ref="nuvem"
        :style="`fill: ${drawingColors.nuvem}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="andorinhas">
      <path
        class="st5" d="M1088.81,154.64c-0.59-2.02-5.13-2.58-11.25-1.72c-7-15.66-27.36-32.28-51.67-27.84
        c18.45,4.92,29.5,11,36.72,31.42c-18.24,4.39-36.88,5.67-54.93,11.8c81.13-13.66,32.51,3.99,4.38,15.04
        c18.51-4.53,35.7-10.77,53.6-16.28c4.75,20.87-1.31,31.9-14.15,45.89c22.67-9.24,30.97-33.82,28.68-50.78
        C1085.84,159.6,1089.4,156.67,1088.81,154.64z"
        data-ref="andorinhas"
        :style="`fill: ${drawingColors.andorinhas}!important;`"
        @click="changeColor($event)"
      />
      <path
        class="st5" d="M958.97,122.75c6.63-12.37,7.19-33.86-7.85-47.38c6.05,14.4,7.65,24.6-1.94,39.49
        c-11.97-9.6-22.13-21.02-35.14-29.63c49.46,45.66,13.7,23.04-7.53,10.37c12.2,9.71,24.85,17.7,37.37,26.53
        c-11.13,13.52-21.3,15.13-36.78,13.82c17.33,10.04,37.39,3.1,47.22-6.87c4.49,2.37,8.17,3.2,9.18,1.81
        C964.5,129.5,962.59,126.28,958.97,122.75z"
        data-ref="andorinhas"
        :style="`fill: ${drawingColors.andorinhas}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="mao_x5F_dta">
      <path class="st6" d="M1405.31,696.22c0,0,2.45,6.77,4.14,12.04c1.69,5.27,1.13,20.32,1.13,20.32l-2.63,19.95
        c0,0-9.03,20.32-11.67,21.08c-2.63,0.75-3.32-2.41-3.32-2.41s-0.63,1.66-3.83,0.91c-3.2-0.75-2.82-4.89-2.82-4.89
        s2.21-8.55,3.48-11.97c1.17-3.16,1.15-12.74,0.29-14.76c-1.51-3.5-4.7-6.4-4.7-6.4l-7.15,7.9c0,0-1.88,3.95-6.4,2.45
        c-4.52-1.51-2.45-6.02-2.45-6.02l7.9-26.54v-10.16L1405.31,696.22z"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="rabo_x5F_cavalo">
      <g>
        <path class="st7" d="M1243.11,400.09c0.27,0.1,0.54,0.19,0.82,0.29C1243.65,400.28,1243.38,400.19,1243.11,400.09z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1247.17,401.41c0.34,0.1,0.68,0.19,1.02,0.28C1247.85,401.6,1247.51,401.51,1247.17,401.41z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1249.25,401.96c0.35,0.09,0.7,0.18,1.05,0.26C1249.95,402.14,1249.61,402.05,1249.25,401.96z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1236.64,397.07c0.25,0.14,0.5,0.28,0.75,0.42C1237.14,397.35,1236.89,397.21,1236.64,397.07z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1234.83,395.97c0.2,0.13,0.4,0.25,0.6,0.37C1235.23,396.22,1235.03,396.1,1234.83,395.97z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1238.52,398.08c0.26,0.13,0.52,0.26,0.78,0.39C1239.04,398.34,1238.78,398.21,1238.52,398.08z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1240.53,399.02c0.24,0.11,0.47,0.22,0.71,0.32C1241,399.24,1240.77,399.13,1240.53,399.02z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1224.37,541.8c-18.29,0-33.26-14.97-33.26-33.26l-2.45-59.73c7.11-10.64,16.56-19.23,23.28-32.05
          c-12.49,23.8-34.4,32.98-33.22,67.96c-24.28-70.92-15.36-116.13,18.24-130.39c13.42-23.53,45.07-35.87,78.18-36.24
          c-0.53-1.01-1.15-1.78-1.88-2.28c-41.08-28.18-131.87-7.63-126.44,79.27c5.43,86.9,38.03,157.95,5.6,165.98
          c-23.36,5.78-25.51-11.25-25.51-11.25s-10.73,49.63,58.86,47.86c47.86-1.22,72.23-25.9,83.86-55.87H1224.37z"
            data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
          />
        <path class="st7" d="M1258.62,403.63c2.47,0.3,4.99,0.5,7.55,0.65C1263.61,404.14,1261.09,403.93,1258.62,403.63z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1274.68,404.55C1274.68,404.55,1274.68,404.55,1274.68,404.55c-2.81-0.04-5.59-0.11-8.33-0.26
          C1269.08,404.44,1271.87,404.52,1274.68,404.55z"
            data-ref="rabo_x5F_cavalo"
            :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
            @click="changeColor($event)"
          />
        <path class="st7" d="M1254.37,403.03c0.43,0.07,0.87,0.14,1.31,0.21C1255.25,403.17,1254.81,403.1,1254.37,403.03z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1256.52,403.36c0.46,0.07,0.92,0.13,1.39,0.19C1257.44,403.49,1256.98,403.42,1256.52,403.36z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1251.44,402.46c0.67,0.14,1.35,0.28,2.04,0.4C1252.79,402.74,1252.11,402.61,1251.44,402.46z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1245.12,400.79c0.32,0.1,0.64,0.2,0.96,0.3C1245.76,400.99,1245.44,400.89,1245.12,400.79z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1219.85,389.5c0.14-1.41,0.24-2.87,0.32-4.35C1220.09,386.63,1219.99,388.09,1219.85,389.5z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1220.22,384c0.07-1.53,0.11-3.08,0.11-4.7C1220.33,380.92,1220.29,382.48,1220.22,384z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1219.33,393.68c0.2-1.35,0.38-2.73,0.52-4.16C1219.71,390.96,1219.52,392.33,1219.33,393.68z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1212.1,416.48c1.01-1.96,1.96-4.01,2.83-6.19C1214.06,412.47,1213.11,414.53,1212.1,416.48z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1221.53,381.57c0.13,0.23,0.26,0.45,0.39,0.68C1221.79,382.03,1221.66,381.8,1221.53,381.57z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1215.14,409.76c0.83-2.14,1.59-4.4,2.25-6.79C1216.73,405.36,1215.97,407.62,1215.14,409.76z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1217.59,402.22c0.63-2.38,1.16-4.91,1.59-7.58C1218.75,397.31,1218.22,399.83,1217.59,402.22z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1232.67,394.47c0.15,0.11,0.29,0.23,0.44,0.33C1232.96,394.7,1232.82,394.58,1232.67,394.47z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1229.14,391.5c0.2,0.19,0.4,0.37,0.6,0.55C1229.54,391.87,1229.34,391.69,1229.14,391.5z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1228.06,390.43c-0.16-0.17-0.33-0.33-0.49-0.5C1227.73,390.1,1227.89,390.26,1228.06,390.43z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1230.8,392.99c0.2,0.17,0.4,0.34,0.6,0.51C1231.2,393.33,1231,393.16,1230.8,392.99z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1222.81,383.72c0.15,0.24,0.31,0.49,0.47,0.73C1223.12,384.21,1222.96,383.97,1222.81,383.72z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1225.88,387.99c0.07,0.09,0.14,0.18,0.21,0.26C1226.01,388.16,1225.94,388.07,1225.88,387.99z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1224.2,385.79c0.15,0.21,0.3,0.43,0.46,0.64C1224.5,386.22,1224.35,386,1224.2,385.79z"
          data-ref="rabo_x5F_cavalo"
          :style="`fill: ${drawingColors.rabo_x5F_cavalo}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="pescoco">
      <polygon
        class="st8" points="1308.29,548.03 1296.49,548.03 1290.31,558.85 1258.14,559.05 1252.27,548.03 1243.84,548.03 1243.84,538.44 1308.29,538.44"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="rosto">
      <path class="st8" d="M1368.04,455.98c-3.08,0-5.9,1.11-8.16,2.96l0.75-18.25c-25.04-69.11-113.48-6.89-140.29-61.38
        c0,38.84-19.34,51.07-31.67,69.51l0.54,13.17c-2.41-3.63-6.4-6.01-10.92-6.01c-7.36,0-13.33,6.29-13.33,14.04
        c0,7.76,5.97,14.04,13.33,14.04c4.94,0,9.24-2.84,11.54-7.04l1.29,31.52c0,18.29,14.97,33.26,33.26,33.26h100.21
        c18.29,0,33.26-14.97,33.26-33.26l1.16-28.23c2.38,2.32,5.55,3.74,9.04,3.74c7.36,0,13.33-6.29,13.33-14.04
        C1381.36,462.26,1375.4,455.98,1368.04,455.98z"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="olhos_x5F_boca">
      <g class="st2">
        <path class="st9" d="M1274.38,517.23c-10.5,0-20.72-4.93-27.14-13.58c-1.35-1.82-0.97-4.4,0.85-5.75c1.83-1.35,4.4-0.97,5.75,0.85
          c6.21,8.37,17.14,12.13,27.18,9.36c5.52-1.52,10.41-4.91,13.78-9.53c1.34-1.84,3.91-2.24,5.74-0.9c1.84,1.34,2.24,3.91,0.9,5.74
          c-4.46,6.12-10.94,10.6-18.24,12.62C1280.3,516.84,1277.33,517.23,1274.38,517.23z"/>
      </g>
      <g class="st2">
        <path class="st9" d="M1275.55,475.7c-4.32,0-8.52,2.03-11.16,5.58c-0.56,0.75-0.4,1.81,0.35,2.36c0.75,0.56,1.81,0.4,2.36-0.35
          c2.55-3.44,7.04-4.99,11.18-3.85c2.27,0.63,4.28,2.02,5.67,3.92c0.55,0.76,1.61,0.92,2.36,0.37c0.75-0.55,0.92-1.61,0.37-2.36
          c-1.83-2.52-4.5-4.36-7.5-5.19C1277.98,475.86,1276.76,475.7,1275.55,475.7z"/>
      </g>
      <g class="st2">
        <g>
          <path class="st4" d="M1237.42,452.42c5.72,0,10.36,4.64,10.36,10.36c0,5.72-4.64,10.36-10.36,10.36
            c-5.72,0-10.36-4.64-10.36-10.36C1227.06,457.06,1231.69,452.42,1237.42,452.42"/>
        </g>
      </g>
      <g class="st2">
        <g>
          <path class="st4" d="M1316.7,452.42c5.72,0,10.36,4.64,10.36,10.36c0,5.72-4.64,10.36-10.36,10.36
            c-5.72,0-10.36-4.64-10.36-10.36C1306.34,457.06,1310.98,452.42,1316.7,452.42"/>
        </g>
      </g>
    </g>
    <g id="cabelo_x5F_frente">
      <path class="st7" d="M1178.72,484.73c-1.64-48.77,41.61-47.36,41.61-105.42c31.17,63.33,145.6-31.03,146.94,106.49
        c17.81-49.47,21.31-109.3-13.98-141.32c-43.04-39.03-131.33-33.99-156.33,9.86C1163.37,368.59,1154.44,413.81,1178.72,484.73z"
        data-ref="cabelo"
        :style="`fill: ${drawingColors.cabelo}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="oculos">
      <path class="st6" d="M1372.5,434.93h-12.99c-3.09-1.96-6.68-3.09-10.47-3.22l-40.44-1.44c-11.74-0.43-22.28,7.6-25.14,18.88h-14.57
        l-0.03-0.15c-2.24-12.09-13.24-20.89-25.49-20.44l-40.44,1.44c-3.8,0.13-7.39,1.26-10.47,3.22h-12.2v15.93h2.5
        c-0.23,2.73,0.07,5.52,0.94,8.24l5.89,18.31c3.44,10.69,13.29,17.87,24.52,17.87h17.99c9.97,0,19.44-4.81,25.31-12.88l7.5-10.29
        c2.48-3.4,4.03-7.38,4.56-11.49h12.87c0.28,4.73,1.9,9.34,4.73,13.21l7.5,10.29c5.87,8.06,15.33,12.88,25.31,12.88h17.99
        c11.23,0,21.08-7.18,24.52-17.87l5.89-18.31c0.87-2.71,1.18-5.51,0.94-8.24h3.29V434.93z M1258.31,465.59l-7.5,10.29
        c-4.34,5.96-11.34,9.52-18.72,9.52h-17.99c-7.67,0-14.4-4.91-16.75-12.21l-5.89-18.31c-1.24-3.86-0.62-7.97,1.71-11.29
        c2.33-3.32,5.99-5.3,10.04-5.45l40.44-1.44c8.3-0.27,15.67,5.63,17.18,13.77l0.4,2.17
        C1262.08,457.16,1261.01,461.88,1258.31,465.59z M1360.49,456.6l-5.89,18.31c-2.35,7.3-9.08,12.21-16.75,12.21h-17.99
        c-7.38,0-14.37-3.56-18.72-9.52l-7.5-10.29c-2.7-3.71-3.77-8.42-2.93-12.93l0.4-2.17c1.48-7.95,8.55-13.79,16.59-13.79
        c0.2,0,0.39,0,0.59,0.01l40.44,1.44c4.05,0.14,7.71,2.13,10.04,5.45C1361.11,448.62,1361.74,452.74,1360.49,456.6z"
        data-ref="oculos"
        :style="`fill: ${drawingColors.oculos}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="mangas">
      <path class="st7" d="M1350.16,630.76c3.81,10.39,4.24,12.58,4.4,13.44c0.66,3.59,5.84,30.81,9.67,53.74
        c0.31,1.85,5.67,1.28,6.02,2.84c1.2,5.27,1.87,14.41,3,14.48c12.5,0.83,28.28-1.67,37.71-7.41c0.49-0.3,0.73-0.88,0.61-1.44
        c-0.53-2.42-1.98-8.99-2.91-13.13c-0.29-1.3,3.45-3.28,3.54-3.76c1.26-7.07-9.26-54.51-12.73-61.94
        c-16.12-34.47-26.43-53.53-34.86-63.15c-9.65-11.02-15.65-11.71-20.96-12.09c3.78,2.61,3.07,6.68,3.96,14.33
        C1349.77,585.35,1347.64,623.88,1350.16,630.76z"
        data-ref="mangas"
        :style="`fill: ${drawingColors.mangas}!important;`"
        @click="changeColor($event)"
      />
      <path class="st6" d="M1204.56,553.4c0.52-0.62,1.57-1.28,0.76-1.24c-1.63,0.08-3.35,0.95-6.94,4.47
        c-3.25,3.19-17.83,37.36-27.34,50.94c-1.91,2.73-7.94,2.43-13.57,3.93l-1.88,1.37l-7.76-0.39c5.27,2.26,19.47,38.15-23.22,43.79
        l13.06,6.84l7.81,4.35l24.36-0.95c13.3-1.83,20.05-6.59,25.31-11.33c1.66-1.5,2.75-3.51,3.09-5.72c0.83-5.45,2.44-23.28,2.97-26.62
        C1203.98,605.49,1199.24,559.72,1204.56,553.4z"
        data-ref="mangas"
        :style="`fill: ${drawingColors.mangas}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="bota_x5F_dta">
      <path class="st7" d="M1431.72,977.69c0,0,0-9.62-14.15-18.41c-9.66-6-22.43-17.69-22.43-17.69l-13.99-13.41l-0.94-12.97
        c0,0-4.17-3.89-9.71-2.5c-5.54,1.39-52.72,1.12-52.72,1.12s-5.18,0.07-5.54,5.35c-0.36,5.28-1.97,35.69,0,48.86
        c0.71,4.78,25.38,9.55,25.38,9.55s11.2,0.2,14.45,2.59c3.25,2.39,12.84,4.41,23.05,4.3c5.43-0.06,14.21-0.01,19.2-0.28
        c3.48-0.19,8.16-1.94,10.89-4.4c4.86-4.38,16.25-4.22,19.26-2.92c4.24,1.84,3.33,5.04,5.16,4.76
        C1432.23,981.26,1431.72,977.69,1431.72,977.69z"
        data-ref="bota"
        :style="`fill: ${drawingColors.bota}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="sola">
      <path class="st7" d="M1431.72,979.1c-0.12,1.05-0.58,2.32-2.06,2.54c-1.83,0.27-0.93-2.93-5.16-4.76
        c-3.01-1.31-14.4-1.46-19.26,2.92c-2.73,2.45-7.41,4.21-10.89,4.4c-5,0.27-13.77,0.23-19.2,0.28c-10.21,0.1-19.81-1.91-23.05-4.3
        c-3.25-2.39-14.45-2.59-14.45-2.59s-24.66-4.78-25.38-9.55c-0.06-0.43-0.12-0.88-0.18-1.35c-0.42,0.55-0.73,1.23-0.74,1.72
        c-0.08,4.68-0.24,16.17,0.03,16.38c0.22,0.17,3.83,1.85,6.08,2.17c0.13,0.02,0.74-1.79,0.87-1.78c0.2,0.02,0.32,1.01,0.37,1.59
        c0.02,0.23,0.19,0.41,0.4,0.43c1.32,0.09,6.04,0.43,10.62,0.74c0.32,0.02,0.39-1.63,0.71-1.61c0.39,0.03,0.34,1.7,0.73,1.73
        c0.33,0.02,6.12,0.39,7.95,0.5c1.44,0.09,3.33-2.65,4.02-2.62c0.97,0.05,1.65,2.29,2.73,2.98c3.51,2.26,6.97,4.63,6.97,4.63
        s7.07,2.77,18.6,2.6c3.92-0.06,9.96,0.01,10.33,0.01c0.12,0,0.51-2.06,0.63-2.06c0.27,0,0.31,2.06,0.58,2.06
        c0.24,0,6.3-0.04,6.53-0.04c0.17,0,0.35-0.01,0.52-0.01c0.35-0.01,0.69-0.01,1.03-0.02c1.87-0.04,2.02,0.13,3.75,0.05
        c0.15-0.01,0.37-2.35,0.52-2.35c0.09,0,0.5,2.3,0.58,2.3c0.13-0.01,0.13-0.01,0.25-0.01c2.75-0.14,6.89-0.32,8.71-0.46
        c0.38-0.03,1.28-0.04,1.61-0.07c0.44-0.04,0.43-2.68,0.73-2.81c0.12-0.05,0.57,1.22,0.84,2c0.12,0.35,0.43,0.5,0.76,0.48
        c3.64-0.22,13.01-0.29,20-1.75c3.17-0.66,2.9-5.38,2.9-5.38L1431.72,979.1z"
        data-ref="sola"
        :style="`fill: ${drawingColors.sola}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <!-- <g id="persponto_x5F_bota_x5F_dta">
      <path class="st10" d="M1407.96,961.36c-7.57-0.47-17.44,5.02-22.59,0.8c0,0-21.95-20.41-35.61-36.61
        c-1.01-1.19,13.88-1.81,12.8-2.88"/>
      <path class="st11" d="M1384.52,961.32c-8.72,1.09-11.36,14.08-14.66,23.03"/>
      <path class="st10" d="M1382.15,959.11c-7.21,1.99-11.51,12.5-15.25,24.99"/>
      <path class="st11" d="M1374.4,964.9c-30.99-15.33-45.49-37.16-62.36-38.66"/>
      <path class="st11" d="M1372.86,968.54c-36.04-16.77-41.25-33-60.82-37.71"/>
      <path class="st11" d="M1318.2,932.85c0,0-2.85,29.67,0.33,38.86"/>
      <path class="st11" d="M1321.91,934.72c0,0-2.69,28.79-0.01,37.67"/>
      <path class="st11" d="M1312.22,919.81c0,0,4.8,5.08,28.74,4.67c1.04-0.02,15.59,15.73,15.59,15.73l-2.38,11.05"/>
      <path class="st11" d="M1376.73,929.75l31.42,26.66c0,0,3.13,4.04,0.74,4.87"/>
    </g> -->
    <!-- <g id="cordoes">
      <path class="st12" d="M1371.99,931.96c-0.1-0.56-6.65-1.16-11.62,2.8c-0.46,0.37-1.86,1.93-4.65,5.04
        c-4.23,4.73-3.56,4.25-5.17,5.88c-3.37,3.42-4.66,3.8-5.17,5.88c-0.4,1.63-0.22,3.91,1.03,5.04c1.98,1.79,6.04,0.2,8.27-2.24
        c1.74-1.91,1.32-3.22,3.1-8.12c1.34-3.7,2.6-7.16,5.17-9.81C1367,932.28,1372.08,932.46,1371.99,931.96z"/>
      <polyline class="st12" points="1379.58,950.17 1381.74,936.44 1366.12,937.67 1390.34,944.47"/>
      <path class="st12" d="M1403.61,954.81c0,0-12.5,2.09-13.65,4.78c-1.15,2.69,2.5-13.46,2.5-13.46"/>
      <line class="st12" x1="1380.57" y1="950.94" x2="1402.07" y2="954.81"/>
      <path class="st13" d="M1371.65,932.08c-0.03-0.57,6.23-2.6,11.96,0.17c0.53,0.26,2.24,1.47,5.65,3.89c5.17,3.68,4.41,3.36,6.34,4.6
        c4.04,2.59,5.38,2.68,6.34,4.6c0.74,1.5,1.08,3.76,0.1,5.15c-1.53,2.18-5.85,1.52-8.56-0.37c-2.12-1.48-2-2.85-4.81-7.24
        c-2.12-3.32-4.11-6.42-7.2-8.43C1376.58,931.29,1371.67,932.59,1371.65,932.08z"/>
      <path class="st14" d="M1311.93,973.45c0.71,4.78,25.38,9.55,25.38,9.55s11.2,0.2,14.45,2.59s12.84,4.41,23.05,4.3
        c5.43-0.06,14.21-0.01,19.2-0.28c3.48-0.19,8.16-1.94,10.89-4.4c4.86-4.38,16.25-4.22,19.26-2.92c4.24,1.84,3.33,5.04,5.16,4.76
        c2.57-0.38,2.06-3.96,2.06-3.96"/>
    </g> -->
    <g id="bota_x5F_esq">
      <path class="st7" d="M1122.2,975.55c0,0,0.47-10.12,15.65-15.09c15.19-4.98,26.53-14.73,26.53-14.73l18.45-16.72l2.05-12.34
        c0,0-0.8-4.18,5.37-2.79c6.17,1.39,58.71,1.12,58.71,1.12s5.77,0.07,6.17,5.35c0.4,5.28,2.19,35.69,0,48.86
        c-0.8,4.78-38.01,7.56-38.01,7.56s-12.14,0.8-16.09,2.59c-15.89,7.21-41.24,3.89-46.8,3.62c-3.88-0.19-9.49-0.32-12.38-2.91
        c-1.45-1.29-3.34-1.81-5.73-2.02C1130.6,977.57,1123.59,977.39,1122.2,975.55z"
        data-ref="bota"
        :style="`fill: ${drawingColors.bota}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="sola_x5F_bota_x5F_esq">
      <path class="st7" d="M1255.09,969.32c-1.32,4.71-37.97,7.46-37.97,7.46s-12.14,0.8-16.09,2.59c-15.89,7.21-41.24,3.89-46.8,3.62
        c-3.88-0.19-9.49-0.32-12.38-2.91c-1.45-1.29-3.34-1.81-5.73-2.02c-5.52-0.48-12.53-0.66-13.92-2.5c0,0,0.01-0.13,0.04-0.35
        l-0.47,0.13c-0.55,0.04-0.39,3.02-0.4,3.58l-0.09,6.61c0,0-0.35,5,3.23,5.38c2.98,0.32,17.37,2.91,22.08,3.77
        c0.85,0.15,1.7-0.31,2.02-1.11c0.26-0.64,0.53-1.24,0.62-1.21c0.34,0.13,0.32,2.78,0.82,2.81c0.36,0.03,1.37,0.04,1.79,0.07
        c1.88,0.13,5.75,0.49,8.75,0.65c0.55,0.03,1.04-0.33,1.17-0.87c0.16-0.64,0.33-1.39,0.38-1.39c0.17,0.01,0.41,2.35,0.58,2.35
        c1.92,0.08,2.09-0.1,4.18-0.05c0.38,0.01,0.77,0.02,1.15,0.02c0.19,0,0.39,0.01,0.58,0.01c0.26,0,6.66,0.02,6.93,0.02
        c0.3,0,0.34-2.06,0.65-2.06c0.13,0,0.56,2.06,0.7,2.06c0.42,0,6.13-0.22,10.4-1.03c11.47-2.15,19.14-4.13,20.12-4.39
        c0.07-0.02,0.14-0.03,0.22-0.03c0.76-0.03,5.28-0.23,9.26-0.4c1.42-0.06,1.95-2.93,3.03-2.98c0.77-0.03,2.87,2.71,4.48,2.62
        c2.04-0.12,8.49-0.48,8.85-0.5c0.43-0.03,0.38-1.7,0.82-1.73c0.36-0.02,0.43,1.63,0.79,1.61c4.37-0.27,8.85-0.55,10.97-0.69
        c0.71-0.05,1.28-0.58,1.41-1.28c0.07-0.42,0.18-0.78,0.31-0.8c0.14-0.01,0.82,1.8,0.97,1.78c2.51-0.32,6.57-0.93,6.86-1.01
        c0.48-0.14,0.44-13,0.02-17.11C1255.39,969.73,1255.26,969.5,1255.09,969.32z"
        data-ref="sola"
        :style="`fill: ${drawingColors.sola}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <!-- <g id="persponto_x5F_bota_x5F_esq">
      <path class="st10" d="M1148.75,955.73c7.57-0.47,15.34,8.04,20.48,3.81c0,0,22.86-21.26,36.52-37.47c1-1.19-13.88-1.81-12.8-2.88"
        />
      <path class="st11" d="M1174.16,954.88c0,0,14.95,11.77,18.79,26.39"/>
      <path class="st11" d="M1176.23,952.89c0,0,15.07,11.75,18.92,26.37"/>
      <path class="st11" d="M1185.15,960.28c30.99-15.33,52.71-31.67,69.59-33.18"/>
      <path class="st11" d="M1186.35,962.49c34.61-13.72,47.09-28.47,68.62-30.42"/>
      <path class="st11" d="M1246.39,934.02c0,0,2.85,29.67-0.33,38.86"/>
      <path class="st11" d="M1242.25,934.89c0,0,3.11,29.8,0.44,38.68"/>
      <path class="st11" d="M1253.44,920.99c0,0-1.35,6.12-39.03,0.9c-1.26-0.18-17.14,19.5-17.14,19.5l2.95,11.05"/>
    </g>
    <g id="cordoes_x5F_bota_x5F_esq">
      <path class="st13" d="M1185.85,927.52c0.1-0.56,6.65-1.16,11.62,2.8c0.46,0.37,1.86,1.93,4.65,5.04c4.23,4.73,3.56,4.25,5.17,5.88
        c3.37,3.42,4.66,3.8,5.17,5.88c0.4,1.63,0.22,3.91-1.03,5.04c-1.98,1.79-6.04,0.2-8.27-2.24c-1.74-1.91-1.32-3.22-3.1-8.12
        c-1.34-3.7-2.6-7.16-5.17-9.81C1190.84,927.83,1185.77,928.02,1185.85,927.52z"/>
      <polyline class="st13" points="1179.44,946.48 1177.28,932.76 1192.9,933.98 1168.68,940.78"/>
      <path class="st13" d="M1155.41,951.12c0,0,12.5,2.09,13.65,4.78c1.15,2.69-2.5-13.46-2.5-13.46"/>
      <line class="st12" x1="1178.46" y1="947.25" x2="1156.95" y2="951.12"/>
      <path class="st14" d="M1255.14,974.65c-0.8,4.78-38.01,7.56-38.01,7.56s-12.14,0.8-16.09,2.59c-15.89,7.21-41.24,3.89-46.8,3.62
        c-3.88-0.19-9.49-0.32-12.38-2.91c-1.45-1.29-3.34-1.81-5.73-2.02c-5.52-0.48-12.53-0.66-13.92-2.5"/>
    </g> -->
    <g id="calca">
      <path class="st7" d="M1305.84,927.11c-0.21,0.07-0.41,0.12-0.61,0.18l1.6,7.43l3,4.69c0.14,1.24-1.62,5.8-0.51,7.97
        c0.41,0.8,1.06,2.2,3.51-0.23c4.54-4.51,13.82-3.98,17.86-5.52c28.24-10.8,43.1-14.53,57.95-7.37c1.98,0.96,5.28,2.28,4.62-1.02
        c-0.61-3.05-4.21-5.97-4.55-7.6s0.67-2.07,0.22-3.92c-0.3-1.23-1.22-4.37-1.8-5.81c-0.38-0.94,0.04-1.73,0.23-3
        c-0.6,0.23-1.22,0.46-1.85,0.7C1366.12,920.99,1328.06,919.81,1305.84,927.11z"
        data-ref="calca"
        :style="`fill: ${drawingColors.calca}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1171.47,919.19c-0.95,2.19-0.54,7.04-1.32,8.96c-2.33,5.72-1.1,8.66,1.96,7.84
        c5.65-3.91,19.19-0.37,31.42,1.13c20.5,2.51,37.61,17,52.88,15.26c3.77-0.43,0.86-10.3,0.67-11.27c-0.01-0.06,3.07-6.79,3.07-6.86
        l0.21-3.28c-23.94-0.3-66.69-8.47-83.69-16.13C1175.27,916.35,1172.04,917.88,1171.47,919.19z"
        data-ref="calca"
        :style="`fill: ${drawingColors.calca}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1264.87,885.36c0.06,0.06,0.12,0.13,0.18,0.19l3.52-36.74l4.92-35c0.26-1.65,1.66-2.88,3.34-2.92
        c1.14-0.03,2.13,0.77,2.35,1.89l16.98,71.85c1.01,4.28,73.94-5.11,81.45-10.78l-0.89-4.43l-3.51-17.42
        c0,0-19.72-116.76-23.1-135.19c-0.07-0.35-0.38-0.6-0.74-0.59c-94.57,2-143.33,1.12-150.66,0.44c-0.42-0.04-0.78,0.26-0.82,0.67
        c-0.42,4.28-2.57,25.81-4.12,33.16c-0.82,3.88-7.43,95.66-7.43,95.66s0.49,10.53-0.5,21.61c-0.03,0.28-0.06,0.7-0.12,1.22
        c0.05,0.04,0.09,0.07,0.13,0.11C1194.99,877.03,1262.14,887.71,1264.87,885.36z"
        data-ref="calca"
        :style="`fill: ${drawingColors.calca}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1382.36,894.95c-0.13-2.93-0.64-5.83-1.51-8.62l-0.07-0.24c-0.07,0.02-0.13,0.04-0.2,0.07
        c-14.78,4.92-43.07,9.77-82.1,12.89c0.2,2.55,0.76,5.06,1.69,7.45l3.02,7.78c0.07,0.34,0.12,0.68,0.18,1.02c0,0,0,0,0,0
        c72.27-6.76,79.77-13.03,79.77-13.03s0.29-0.18,0.8-0.51c-0.76-1.66-1.36-3.05-1.42-3.73L1382.36,894.95z"
        data-ref="calca"
        :style="`fill: ${drawingColors.calca}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1263.83,898.28c-0.24,0-0.47,0-0.72,0.01c-20.86,0.8-67.97-10.32-75.72-15.31c-1.19-0.77-2.31-1.41-3.39-1.96
        c-0.15,0.58-0.3,1.05-0.45,1.33c-2.98,5.54-0.7,8.15-1.28,12.4c-0.22,1.61-2.85,4.45-2.98,6.07c0,0,0,0,0,0
        c0.77,0.32,1.55,0.64,2.36,0.99c15.61,6.74,51.01,14.5,78.52,14.66c0.53,0,1.07-0.06,1.62-0.15c0.28-1.19,0.53-2.29,0.53-2.3
        L1263.83,898.28z"
        data-ref="calca"
        :style="`fill: ${drawingColors.calca}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="riscas_x5F_calca_x5F_esq">
      <g>
        <path class="st7" d="M1261.79,916.32c-0.55,0.09-1.09,0.15-1.62,0.15c-27.51-0.16-62.9-7.92-78.52-14.66
          c-0.81-0.35-1.59-0.68-2.36-0.99c-0.3,3.68-1.99,7.73-1.99,12.79c0,0.41-0.26,0.83-0.65,1.25c17,7.66,59.75,15.83,83.69,16.13
          l0.73-11.6C1261.08,919.37,1261.45,917.77,1261.79,916.32z"
          data-ref="ricas"
          :style="`fill: ${drawingColors.ricas}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1187.39,882.97c7.75,5,54.86,16.11,75.72,15.31c0.25-0.01,0.48,0,0.72-0.01l1.22-12.72
          c-0.06-0.06-0.12-0.13-0.18-0.19c-2.74,2.35-69.88-8.33-79-16.29c-0.05-0.04-0.09-0.07-0.13-0.11c-0.29,2.94-0.99,9.11-1.74,12.06
          C1185.08,881.56,1186.2,882.21,1187.39,882.97z"
          data-ref="ricas"
          :style="`fill: ${drawingColors.ricas}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="riscas_x5F_calca_x5F_dta">
      <g>
        <path class="st7" d="M1387.14,909.54c-0.58-2.19-2.08-5.28-3.22-7.78c-0.5,0.33-0.8,0.51-0.8,0.51s-7.49,6.26-79.77,13.03
          c0,0,0,0,0,0c0.33,1.93,0.52,3.88,0.55,5.83l1.32,6.15c0.2-0.06,0.4-0.12,0.61-0.18c22.23-7.29,60.29-6.12,79.69-13.51
          c0.63-0.24,1.25-0.47,1.85-0.7C1387.5,912.06,1387.52,911,1387.14,909.54z"
          data-ref="ricas"
          :style="`fill: ${drawingColors.ricas}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1296.17,884.63l0.81,3.44l1.11,4.68l0.33,5.7c0.01,0.2,0.04,0.4,0.06,0.6c39.02-3.12,67.31-7.97,82.1-12.89
          c0.07-0.02,0.13-0.04,0.2-0.07l-1.94-6.24l-1.21-6.01C1370.11,879.52,1297.18,888.91,1296.17,884.63z"
          data-ref="ricas"
          :style="`fill: ${drawingColors.ricas}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="persponto_calca">
      <g class="st15">
        <g class="st16">
          <path class="st17" d="M1283.71,743.23l1.2,43.86c0,0-17.81,7.17-18.84-9.02s-1.02-14.91-1.2-34.84"/>
          <g>
            <line class="st18" x1="1283.71" y1="743.23" x2="1283.73" y2="743.73"/>
            <line class="st19" x1="1283.75" y1="744.73" x2="1284.88" y2="786.09"/>
            <path class="st18" d="M1284.9,786.59l0.01,0.5c0,0-0.17,0.07-0.47,0.17"/>
            <path class="st20" d="M1283.49,787.57c-4.24,1.3-16.57,3.89-17.42-9.5c-1.01-15.91-1.02-14.94-1.19-33.83"/>
            <path class="st18" d="M1264.88,743.73c0-0.17,0-0.33,0-0.5"/>
          </g>
        </g>
        <path class="st21" d="M1355.51,762.81l-27.1-9.22c-2.59-0.88-4.39-7.44-4.39-7.1v-7.07"/>
        <path class="st21" d="M1220.76,740.65v9.44c0,2.37-1.82,4.45-4.45,5.06l-23.95,5.59"/>
      </g>
    </g>
    <g id="tracos_x5F_mao">
      <path class="st7" d="M1406.44,734.79c0,0-1.51,1.13-1.88,2.45c-0.23,0.79-1.13,4.85-1.82,7.97c-0.55,2.47-1.38,4.87-2.5,7.14
        l-7.72,15.76"/>
      <path class="st18" d="M1390.2,736.93l0.06-9.13c-0.23-1.68-1.66-2-2.58-0.57l-2.32,2.87"/>
      <path class="st7" d="M1369.83,700.27c3.73,1.78,33.73-3.1,38.93-7.55"/>
      <path class="st18" d="M1154.69,612.87c7.52,0,12.22,43.49-17.02,50.24"/>
    </g>
    <g id="tronco">
      <g>
        <path class="st7" d="M1204.25,552.34l-0.14,0.02c-0.01,0.01-0.03,0.03-0.05,0.04C1204.12,552.38,1204.18,552.36,1204.25,552.34z"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1350.16,630.76c-2.52-6.88-0.39-45.41-2.55-64.09c-0.88-7.64-0.18-11.72-3.96-14.33l-22.82-3.13l-26.67,3
          l-38.96,0.19l-28.61-3.19l-21.14,2.96c0.5,0.06-0.41,0.66-0.89,1.23c-5.32,6.32-0.58,52.09-3.35,69.45
          c-0.53,3.34-2.14,21.17-2.97,26.62c-0.09,0.56-0.22,1.11-0.4,1.64l2.24,66.84c9.33,0.38,17.57,0.47,21.88,0.63
          c34.51,1.3,64.48-0.32,110.54-0.63c4.31-0.03,8.95,0.79,15.61,0l2.57-85.79C1350.51,631.7,1350.34,631.25,1350.16,630.76z"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g>
        <path class="st7" d="M1221.96,737.8l0-19.22c-11.55-0.18-19.68-0.42-24.12-0.63c0,0-1.67,7.01-2.84,17.7
          c9.09,1.16,18.14,2.04,27.07,2.7C1222,738.17,1221.96,737.99,1221.96,737.8z"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1318.76,738.2c21.48-1.5,34.94-3.41,34.94-3.41s-0.72-10.46-3.01-17.32c-11.28,0.3-22.02,0.54-32.19,0.73
          L1318.76,738.2z"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1306.84,718.41c-29.34,0.46-53.48,0.48-71.8,0.32v19.65c0,0.28-0.08,0.54-0.2,0.77
          c26.94,1.39,52.09,0.87,72.26-0.22L1306.84,718.41z"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="cinto">
      <g>
        <path
          class="st7" d="M1221.96,737.8l0-19.22c-11.55-0.18-19.68-0.42-24.12-0.63c0,0-1.67,7.01-2.84,17.7 c9.09,1.16,18.14,2.04,27.07,2.7C1222,738.17,1221.96,737.99,1221.96,737.8z"
          data-ref="cinto"
          :style="`fill: ${drawingColors.cinto}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st7" d="M1318.76,738.2c21.48-1.5,34.94-3.41,34.94-3.41s-0.72-10.46-3.01-17.32c-11.28,0.3-22.02,0.54-32.19,0.73 L1318.76,738.2z"
          data-ref="cinto"
          :style="`fill: ${drawingColors.cinto}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st7" d="M1306.84,718.41c-29.34,0.46-53.48,0.48-71.8,0.32v19.65c0,0.28-0.08,0.54-0.2,0.77 c26.94,1.39,52.09,0.87,72.26-0.22L1306.84,718.41z"
          data-ref="cinto"
          :style="`fill: ${drawingColors.cinto}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="fecho_cinto">
      <path
        class="st7" d="M1317.71,739.57l-9.54,0.51c-0.59,0-1.06-0.47-1.06-1.06l-0.28-22.02c0-0.59,0.47-1.06,1.06-1.06h9.54 c0.59,0,1.06,0.47,1.06,1.06l0.28,21.51C1318.77,739.09,1318.29,739.57,1317.71,739.57z"
        data-ref="calca_x5F_01"
        :style="`fill: ${drawingColors.calca_x5F_01}!important;`"
        @click="changeColor($event)"
      />
      <path
        class="st7" d="M1223.72,739.5l9.55,0.58c0.98,0,1.77-0.76,1.77-1.7v-20.42c0-0.94-0.79-1.7-1.77-1.7l-9.55-0.21 c-0.98,0-1.77,0.76-1.77,1.7l0,20.05C1221.96,738.74,1222.75,739.5,1223.72,739.5z"
        data-ref="calca_x5F_01"
        :style="`fill: ${drawingColors.calca_x5F_01}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="fecho_x5F_cinto">
      <path class="st7" d="M1288.15,740.55l-24.45,0.18l-2.23-0.09c-0.92,0-1.67-0.71-1.67-1.59v-20.3c0-0.88,0.75-1.59,1.67-1.59h26.68
        c0.92,0,1.67,0.71,1.67,1.59v20.22C1289.82,739.84,1289.07,740.55,1288.15,740.55z"
        data-ref="fecho_cinto"
        :style="`fill: ${drawingColors.fecho_cinto}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="colarinho">
      <polyline
        class="st7" points="1283.35,571.7 1296.49,548.03 1323.98,548.03 1301.04,589.35 1283.35,571.7"
        data-ref="colarinho"
        :style="`fill: ${drawingColors.colarinho}!important;`"
        @click="changeColor($event)"
      />
      <polyline
        class="st7" points="1257.51,557.78 1265.34,572.36 1247.73,589.32 1224,548.03 1252.27,548.03 1257.51,557.78"
        data-ref="colarinho"
        :style="`fill: ${drawingColors.colarinho}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="camisola_x5F_01">
      <line
        class="st7" x1="1273.99" y1="618.55" x2="1274.01" y2="588.5"
        data-ref="camisola_x5F_01"
        :style="`fill: ${drawingColors.camisola_x5F_01}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="camisola">
      <polygon
        class="st7" points="1254.52,552.21 1274.01,588.5 1294.17,552.21"
        data-ref="camisola"
        :style="`fill: ${drawingColors.camisola}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="logo">
      <g class="st2">
        <g>
          <path class="st22" d="M1294.26,622.06L1294.26,622.06c0,0.32-0.23,0.55-0.56,0.55c-0.29,0-0.55-0.23-0.55-0.55
            c0-0.29,0.26-0.55,0.55-0.55C1294.04,621.51,1294.26,621.77,1294.26,622.06 M1294.15,627.51L1294.15,627.51h-0.9V623h0.9V627.51z
            M1296.92,622.93L1296.92,622.93c-0.45,0-0.89,0.17-1.21,0.46V623h-0.75v4.51h0.9v-2.67c0-0.56,0.45-1.01,1.02-1.01
            c0.55,0,1.01,0.45,1.01,1.01v2.67h0.9v-2.67C1298.8,623.78,1297.97,622.93,1296.92,622.93z M1301.13,622.04L1301.13,622.04h0.72
            v-0.85h-0.72c-0.89,0-1.61,0.59-1.61,1.58v4.73h0.89v-3.71h1.44V623h-1.44v-0.25C1300.42,622.36,1300.74,622.04,1301.13,622.04z
            M1304.87,623L1304.87,623h-0.61c-0.43,0-0.82,0.14-1.07,0.38V623h-0.74v4.51h0.9v-2.71c0-0.57,0.44-0.93,1.01-0.93h0.51V623z
            M1309.68,623L1309.68,623h-0.78v0.57c-0.4-0.4-0.94-0.64-1.54-0.64c-1.29,0-2.33,1.05-2.33,2.32c0,1.29,1.04,2.33,2.33,2.33
            c0.6,0,1.16-0.25,1.55-0.64v0.57h0.77V623z M1307.37,626.67L1307.37,626.67c-0.79,0-1.42-0.63-1.42-1.42
            c0-0.78,0.63-1.42,1.42-1.42c0.79,0,1.43,0.64,1.43,1.42C1308.79,626.04,1308.16,626.67,1307.37,626.67z M1312.65,627.58
            L1312.65,627.58c0.54,0,1.05-0.21,1.42-0.54v2.27h0.9V623h-0.77v0.57c-0.4-0.39-0.96-0.64-1.55-0.64c-1.29,0-2.33,1.05-2.33,2.33
            C1310.33,626.54,1311.36,627.58,1312.65,627.58z M1312.66,623.85L1312.66,623.85c0.77,0,1.42,0.63,1.42,1.41
            c0,0.78-0.65,1.42-1.42,1.42c-0.79,0-1.42-0.63-1.42-1.42C1311.25,624.48,1311.88,623.85,1312.66,623.85z M1317.71,627.58
            L1317.71,627.58c-1.06,0-1.91-0.86-1.91-1.91V623h0.89v2.67c0,0.55,0.46,1.01,1.02,1.01c0.56,0,1.01-0.46,1.01-1.01V623h0.9v2.67
            C1319.62,626.72,1318.76,627.58,1317.71,627.58z M1321.4,622.06L1321.4,622.06c0,0.32-0.23,0.55-0.56,0.55
            c-0.29,0-0.55-0.23-0.55-0.55c0-0.29,0.26-0.55,0.55-0.55C1321.17,621.51,1321.4,621.77,1321.4,622.06z M1321.29,627.51
            L1321.29,627.51h-0.9V623h0.9V627.51z M1324.05,622.93L1324.05,622.93c-0.45,0-0.89,0.17-1.21,0.46V623h-0.75v4.51h0.9v-2.67
            c0-0.56,0.45-1.01,1.02-1.01c0.55,0,1.01,0.45,1.01,1.01v2.67h0.9v-2.67C1325.93,623.78,1325.1,622.93,1324.05,622.93z
            M1328.98,626.6L1328.98,626.6h-0.76c-0.4,0-0.7-0.31-0.7-0.69v-2.11h1.48V623h-1.48v-1.43h-0.9v4.33c0,1,0.64,1.61,1.59,1.61
            h0.78V626.6z M1334.01,623L1334.01,623h-0.78v0.57c-0.4-0.4-0.94-0.64-1.54-0.64c-1.29,0-2.33,1.05-2.33,2.32
            c0,1.29,1.04,2.33,2.33,2.33c0.6,0,1.16-0.25,1.55-0.64v0.57h0.77V623z M1331.7,626.67c-0.79,0-1.42-0.63-1.42-1.42
            c0-0.78,0.63-1.42,1.42-1.42c0.79,0,1.43,0.64,1.43,1.42C1333.13,626.04,1332.5,626.67,1331.7,626.67z"/>
        </g>
        <g>
          <path class="st23" d="M1305.84,598.14c1.37,0,2.48,1.11,2.48,2.48c0,1.37-1.11,2.48-2.48,2.48c-1.37,0-2.48-1.11-2.48-2.48
            C1303.36,599.25,1304.47,598.14,1305.84,598.14"/>
        </g>
        <g>
          <path class="st23" d="M1313.62,598.14c5.66,0,10.25,4.58,10.25,10.24c0,2.41-0.83,4.62-2.23,6.37h2.22v3.87h-10.17
            c-0.03,0-0.05,0-0.08,0c-5.66,0-10.26-4.58-10.26-10.24v0v-2.22h3.88v2.22v0c0,3.52,2.86,6.37,6.38,6.37h0.01
            c3.52,0,6.38-2.85,6.38-6.37c0-3.52-2.86-6.37-6.38-6.37h-2.22v-3.87H1313.62z"/>
        </g>
      </g>
    </g>
    <g id="pa_x5F_02">
      <g>
        <path
          class="st24" d="M1042.12,853.17l56.56,5.24l56.56,5.24l-7.72,83.34c-3.14,33.95-39.09,59.58-62.36,57.43 c-23.27-2.15-53.9-33.95-50.75-67.9L1042.12,853.17L1042.12,853.17z"
          data-ref="pa_x5F_02"
          :style="`fill: ${drawingColors.pa_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st24" d="M1039.1,852.89l49.3,4.56l-1.04,11.23l-49.3-4.56c-1.28-0.12-2.24-1.27-2.12-2.55l0.61-6.57 C1036.67,853.72,1037.82,852.77,1039.1,852.89L1039.1,852.89z"
          data-ref="pa_x5F_02"
          :style="`fill: ${drawingColors.pa_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st24" d="M1158.26,863.92l-49.3-4.56l-1.04,11.23l49.3,4.56c1.28,0.12,2.43-0.83,2.55-2.12l0.61-6.57 C1160.49,865.19,1159.54,864.04,1158.26,863.92L1158.26,863.92z"
          data-ref="pa_x5F_02"
          :style="`fill: ${drawingColors.pa_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st24" d="M1113.99,805.02L1103.9,914c-0.52,5.65-5.58,9.85-11.23,9.33l0,0c-5.65-0.52-9.85-5.58-9.32-11.23 l10.09-108.98L1113.99,805.02L1113.99,805.02z"
          data-ref="pa_x5F_02"
          :style="`fill: ${drawingColors.pa_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st24" d="M1102.57,816.31c2.79,0.26,4.84,2.73,4.58,5.51c-0.26,2.79-2.73,4.83-5.51,4.58 c-2.79-0.26-4.83-2.73-4.58-5.51C1097.32,818.1,1099.79,816.05,1102.57,816.31L1102.57,816.31z"
          data-ref="pa_x5F_02"
          :style="`fill: ${drawingColors.pa_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="maodta">
      <g>
        <path class="st7" d="M1123.12,657.07c5.05,0.25,10.09-1,14.35-3.73c4.19-2.69,8.74-6.69,10.2-11.97
          c2.91-10.56,2.55-21.12,1.46-25.49c-1.09-4.37-8.39-16.45-8.39-16.45l-4.49-4.92c-0.75-0.69-1.43-1.45-2-2.3
          c-0.95-1.4-2.78-3.65-4.79-3.65c-2.91,0-4.37-0.73-6.19,6.92c-1.26,5.28,2.18,9.35,4.42,11.36c0.41,0.37,0.07,1.03-0.47,0.92
          l-18.88-3.91l-6.19-1.82c0,0-6.89,11.27-6.5,11.27c0.63,0-2.69,1.62-3.11,2.03c-0.9,0.87-1.43,1.73-1.68,3.09
          c-0.46,2.51-0.61,7.23,1.58,8.69c1.2,0.8,0.52,2.59-0.23,3.33c-1.08,1.07-1.7,2.53-1.78,4.05c-0.12,2.18,0.14,5.11,1.89,7.24
          c0.42,0.51,1.14,1.06,1.93,1.2c1.89,0.36,1.98-0.33,1.45,2.77c-0.22,1.32-0.45,3.08-0.09,4.91c0.18,0.92,0.58,1.72,1.06,2.39
          c1.33,1.85,3.5,2.93,5.78,3.04L1123.12,657.07z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
        <path class="st7" d="M1154.23,624.03c-1.31-3.96-3.34-7.61-5.88-10.38c0.36,0.85,0.63,1.61,0.78,2.22
          c1.09,4.37,1.46,14.93-1.46,25.49c-1.46,5.28-6.01,9.29-10.2,11.97c-2.17,1.39-7.6,3.15-10.08,3.77c1.92,0.07,6.69,2.12,9,2.11
          C1152.45,651.92,1155.57,634.51,1154.23,624.03z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="pa_x5F_01">
      <path class="st24" d="M1133.25,485.03c-5.65-0.52-10.7,3.67-11.23,9.32l-28.59,308.76l13,1.2l7.55,0.7
        c0,0,11.74-145.88,16.34-195.52c0.18-1.91-7.76-3.07-6.83-12.9c1.38-14.64,10.03-5.37,10.03-5.37c2.19-23.61,9.05-94.96,9.05-94.96
        C1143.1,490.61,1138.9,485.55,1133.25,485.03z"
        data-ref="pa_x5F_01"
        :style="`fill: ${drawingColors.pa_x5F_01}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="dedos">
      <path class="st7" d="M1118.98,620.14l0.57,0.78c1.58,2.16,1.25,5.16-0.76,6.92l0,0c-1.19,1.04-2.79,1.48-4.34,1.2l-9.54-1.23
        c-0.4-0.07-2.26-0.32-2.67-0.3l-6.04-0.13c-1.85,0.1-3.66-0.77-4.62-2.35c-1.2-1.98-2.5-4.19-0.34-8.01
        c3.53-6.23,11.93-3,15.81-1.71c0.25,0.08,0.47,0.21,0.72,0.28l8.58,2.68C1117.41,618.59,1118.33,619.25,1118.98,620.14z"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1121.78,610.84l0.43,0.87c1.2,2.39,0.37,5.3-1.9,6.7l0,0c-1.34,0.83-2.99,1-4.48,0.45l-9.2-2.82
        c-0.38-0.14-2.17-0.7-2.58-0.74l-5.93-1.14c-1.84-0.21-3.48-1.37-4.16-3.1c-0.85-2.15-1.76-4.55,1.01-7.95
        c4.52-5.56,12.26-0.96,15.87,0.97c0.23,0.12,0.43,0.28,0.66,0.4l8.01,4.08C1120.49,609.05,1121.28,609.85,1121.78,610.84z"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1118.77,632.01c0,0,4.1,8.47-4.6,9.78c0,0-11.09,0.43-11.5,0.45l-6.46,0.36c-1.85,0.1-3.66-0.77-4.62-2.35
        c-1.2-1.98-2.5-4.19-0.34-8.01c3.53-6.23,9.19-4.91,12.56-4.54C1104.07,627.73,1117.17,628.8,1118.77,632.01z"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
      <path class="st7" d="M1106.14,652.34c0,0,0.07-1.98,4.08-1.75c2.47,0.14,13.43,0.29,9.51-7.19c-0.75-1.43-2.6-1.75-3.77-1.78
        c-4.47-0.14-9.92,0.22-14.58,0.7c-2.73,0.28-5.72,0.96-5.7,3.39"
        data-ref="skin"
        :style="`fill: ${drawingColors.skin}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_01">
      <path
        class="st25" d="M843.79,742.43c4.6-11.51-6.28-26.69-6.28-26.69s-18.34,3.51-22.94,15.02c-4.6,11.51,6.28,26.69,6.28,26.69 S839.19,753.94,843.79,742.43z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_02">
      <path
        class="st25" d="M801.34,705.84c9.65,4.67,23.4-3.79,23.4-3.79s-1.91-16.03-11.55-20.69c-9.65-4.67-23.4,3.79-23.4,3.79 S791.7,701.17,801.34,705.84z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_03">
      <path
        class="st25" d="M789.63,668.41c10.76-1.54,17.54-16.45,17.54-16.45s-10.69-12.41-21.45-10.87 c-10.76,1.54-17.54,16.45-17.54,16.45S778.87,669.95,789.63,668.41z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_04">
      <path
        class="st25" d="M816.81,633.48c-0.21,8.02,9.79,14.8,9.79,14.8s10.35-6.23,10.56-14.25c0.21-8.02-9.79-14.8-9.79-14.8 S817.03,625.46,816.81,633.48z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_05">
      <path
        class="st25" d="M800.38,617.05c9.22-1.17,15.21-13.82,15.21-13.82s-8.97-10.75-18.19-9.58 c-9.22,1.17-15.21,13.82-15.21,13.82S791.16,618.23,800.38,617.05z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_06">
      <path
        class="st25" d="M780.69,581.54c9.13,10.26,29.56,6.99,29.56,6.99s5.61-19.91-3.53-30.17c-9.13-10.26-29.56-6.99-29.56-6.99 S771.56,571.29,780.69,581.54z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_07">
      <path
        class="st25" d="M824.77,582.02c-0.25,9.29,11.34,17.15,11.34,17.15s11.99-7.22,12.24-16.51 c0.25-9.29-11.34-17.15-11.34-17.15S825.02,572.73,824.77,582.02z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_08">
      <path
        class="st25" d="M847.87,518.44c10.67,4.06,24.47-6.18,24.47-6.18s-3.5-16.83-14.17-20.89c-10.67-4.06-24.47,6.18-24.47,6.18 S837.2,514.38,847.87,518.44z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_09">
      <path
        class="st25" d="M866.57,475.44c-3.78,15.08,12.29,32.11,12.29,32.11s22.2-7.44,25.98-22.51 c3.78-15.08-12.29-32.11-12.29-32.11S870.35,460.36,866.57,475.44z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_10">
      <path
        class="st25" d="M904.44,586.6c-0.86,9.83,10.93,18.9,10.93,18.9s13.17-6.89,14.03-16.73c0.86-9.83-10.93-18.9-10.93-18.9 S905.29,576.77,904.44,586.6z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_11">
      <path
        class="st25" d="M938.56,566.33c3.41,7.46,15.65,9.19,15.65,9.19s6.7-10.38,3.29-17.85c-3.41-7.46-15.65-9.19-15.65-9.19 S935.15,558.86,938.56,566.33z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_12">
      <path
        class="st25" d="M970.19,545c-8.86,9.74-3.69,28.9-3.69,28.9s19.56,3.34,28.42-6.4c8.86-9.74,3.69-28.9,3.69-28.9 S979.06,535.26,970.19,545z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_13">
      <path
        class="st25" d="M936.03,628.2c2.44,5.35,11.21,6.59,11.21,6.59s4.8-7.44,2.36-12.79c-2.44-5.35-11.21-6.59-11.21-6.59 S933.58,622.85,936.03,628.2z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_14">
      <path
        class="st25" d="M966.78,628.28c-9.41-0.33-17.47,11.35-17.47,11.35s7.22,12.21,16.63,12.54 c9.41,0.33,17.47-11.35,17.47-11.35S976.2,628.61,966.78,628.28z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_15">
      <path
        class="st25" d="M991.84,640.2c-9.72,8.38-6.97,27.51-6.97,27.51s18.52,5.53,28.24-2.85c9.72-8.38,6.97-27.51,6.97-27.51 S1001.56,631.82,991.84,640.2z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_16">
      <path
        class="st25" d="M1008.78,678.15c-7.1-7.66-22.57-4.87-22.57-4.87s-3.96,15.22,3.13,22.88c7.1,7.66,22.57,4.87,22.57,4.87 S1015.87,685.8,1008.78,678.15z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_17">
      <path
        class="st25" d="M954.8,704.84c-8.85-2.31-18.97,7.04-18.97,7.04s4.25,13.11,13.1,15.42c8.85,2.31,18.97-7.04,18.97-7.04 S963.65,707.15,954.8,704.84z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="folha_x5F_18">
      <path
        class="st25" d="M938.35,742.62c0.22-12.24-15.14-22.44-15.14-22.44s-15.71,9.66-15.92,21.89 c-0.22,12.24,15.14,22.44,15.14,22.44S938.14,754.86,938.35,742.62z"
        data-ref="folha"
        :style="`fill: ${drawingColors.folha}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="veios_x5F_folhas">
      <g>
        <line class="st26" x1="879.09" y1="504.49" x2="890.41" y2="459.5"/>
        <polyline class="st27" points="877.34,467.05 884.75,480.11 896.5,471.69"/>
        <polyline class="st27" points="871.25,482 880.8,497.67 896.5,485.91"/>
      </g>
      <g>
        <line class="st26" x1="870.13" y1="511.83" x2="838.31" y2="499.69"/>
        <polyline class="st27" points="842.68,509.87 852.85,505.6 847.73,496.3"/>
        <polyline class="st27" points="853.06,515.6 865.3,509.99 858.1,497.53"/>
      </g>
      <g>
        <line class="st26" x1="808.13" y1="586.85" x2="780.9" y2="556.21"/>
        <polyline class="st27" points="779.92,569.51 793.14,570.59 792.83,557.82"/>
        <polyline class="st27" points="787.8,581.39 804,582.2 803.22,564.89"/>
      </g>
      <g>
        <line class="st26" x1="915.2" y1="603.56" x2="917.78" y2="574.22"/>
        <polyline class="st27" points="910.35,580.26 916.3,587.71 922.83,581.25"/>
        <polyline class="st27" points="908.03,590.25 915.59,599.11 924.25,590.17"/>
      </g>
      <g>
        <line class="st26" x1="923.59" y1="722.57" x2="922.91" y2="759.09"/>
        <polyline class="st27" points="931.59,750.98 923.59,742.27 916.07,750.82"/>
        <polyline class="st27" points="933.6,738.43 923.49,728.11 913.54,739.91"/>
      </g>
      <g>
        <line class="st26" x1="937.63" y1="712.03" x2="964.04" y2="718.96"/>
        <polyline class="st27" points="959.67,711.23 951.94,715.51 956.83,722.47"/>
        <polyline class="st27" points="950.91,707.55 941.63,713.08 948.46,722.39"/>
      </g>
      <g>
        <line class="st26" x1="837" y1="718.13" x2="823.25" y2="752.48"/>
        <polyline class="st27" points="834.38,747.91 829.93,736.79 819.74,742.19"/>
        <polyline class="st27" points="840.8,736.74 834.92,723.34 821.26,730.95"/>
      </g>
      <g>
        <line class="st26" x1="822.71" y1="701.47" x2="793.93" y2="687.52"/>
        <polyline class="st27" points="797.19,697.39 807.04,694.23 803.01,685.11"/>
        <polyline class="st27" points="806.43,703.6 818.34,699.35 812.61,687.11"/>
      </g>
      <g>
        <line class="st26" x1="805.13" y1="652.63" x2="773.01" y2="657.2"/>
        <polyline class="st27" points="781.35,663.66 787.85,655.41 779.29,650.02"/>
        <polyline class="st27" points="792.64,663.65 800.26,653.32 788.5,646.26"/>
      </g>
      <g>
        <line class="st26" x1="826.37" y1="646.71" x2="827.03" y2="622.78"/>
        <polyline class="st27" points="821.29,628.05 826.49,633.8 831.46,628.24"/>
        <polyline class="st27" points="819.9,636.26 826.47,643.08 833.06,635.4"/>
      </g>
      <g>
        <line class="st26" x1="987.84" y1="674.53" x2="1009" y2="697.4"/>
        <polyline class="st27" points="1009.55,687.28 999.48,686.65 999.91,696.36"/>
        <polyline class="st27" points="1003.37,678.37 991.05,678 991.9,691.14"/>
      </g>
      <g>
        <line class="st26" x1="951.15" y1="639.38" x2="979.25" y2="640.38"/>
        <polyline class="st27" points="973.12,633.6 966.32,639.64 972.79,645.54"/>
        <polyline class="st27" points="963.49,631.88 955.42,639.53 964.37,647.34"/>
      </g>
      <g>
        <line class="st26" x1="967.9" y1="571.71" x2="994.37" y2="542.65"/>
        <polyline class="st27" points="981.6,543.05 981.89,555.77 994.04,554.19"/>
        <polyline class="st27" points="971.06,551.77 971.91,567.3 988.34,564.81"/>
      </g>
      <g>
        <line class="st26" x1="986.47" y1="665.76" x2="1015.5" y2="640.77"/>
        <polyline class="st27" points="1003.09,639.66 1001.88,651.99 1013.82,651.9"/>
        <polyline class="st27" points="991.87,646.85 990.88,661.97 1007.06,661.5"/>
      </g>
      <g>
        <line class="st26" x1="953.29" y1="574.18" x2="943.13" y2="551.9"/>
        <polyline class="st27" points="940.23,559.32 947.59,562.26 949.66,554.92"/>
        <polyline class="st27" points="942.65,567.49 951.75,570.8 954.35,560.78"/>
      </g>
      <g>
        <line class="st26" x1="946.59" y1="633.83" x2="939.31" y2="617.86"/>
        <polyline class="st27" points="937.23,623.18 942.5,625.29 943.99,620.03"/>
        <polyline class="st27" points="938.96,629.04 945.48,631.41 947.35,624.23"/>
      </g>
      <g>
        <line class="st26" x1="835.85" y1="597.35" x2="836.61" y2="569.62"/>
        <polyline class="st27" points="829.97,575.72 835.98,582.39 841.75,575.95"/>
        <polyline class="st27" points="828.35,585.24 835.96,593.15 843.6,584.25"/>
      </g>
      <g>
        <line class="st26" x1="813.85" y1="603.77" x2="786.32" y2="607.24"/>
        <polyline class="st27" points="793.37,612.88 799.03,605.92 791.79,601.2"/>
        <polyline class="st27" points="803.02,613.03 809.67,604.3 799.71,598.11"/>
      </g>
    </g>
    <g id="troco_x5F_cen">
      <path class="st1" d="M820.36,709.11l2.54,5.85l40.78-17.87l14.04,14.04l0,216.32c4.95,1.15,9.11,2.84,12.78,4.8l0-193.46
        l90.78-67.38l-3.85-5.12l-58.89,43.52v-35.01l29.49-29.46l-4.51-4.53l-24.97,24.99v-29.11h-6.39l0,77.9l-21.64,16.2l0-92.75
        l75.19-59.29l-4.53-4.5l-70.67,54.76V513.73h-12.78l0,136.15l-61.29-61.94l-4.52,4.53l65.8,66.47v43.18l-80.95-76.25l-4.53,4.5
        l66.59,61.89L820.36,709.11z"
        data-ref="troco_x5F_cen"
        :style="`fill: ${drawingColors.troco_x5F_cen}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="terra">
      <g>
        <path class="st24" d="M954.93,981.21c-6.43-10.16-17.01-16.79-28.99-16.79c-15.02,0-27.85,10.43-33.03,25.15 c3.3,3.75,5.34,8.86,5.34,14.51h41.43C939.67,993.63,946.01,984.71,954.93,981.21z"
          data-ref="terra"
          :style="`fill: ${drawingColors.terra}!important;`"
          @click="changeColor($event)"
        />
        <path class="st24" d="M879.49,983.3c10.36,0,18.75,9.3,18.75,20.78h-37.51C860.73,992.6,869.13,983.3,879.49,983.3L879.49,983.3z"
          data-ref="terra"
          :style="`fill: ${drawingColors.terra}!important;`"
          @click="changeColor($event)"
        />
        <path class="st24" d="M963.29,979.63c13.04,0,23.62,10.95,23.62,24.45h-47.24C939.67,990.58,950.24,979.63,963.29,979.63 L963.29,979.63z"
          data-ref="terra"
          :style="`fill: ${drawingColors.terra}!important;`"
          @click="changeColor($event)"
        />
        <path class="st1" d="M946.43,948c-43.15,15.57-37.87-22.22-83.86-22.14c-45.99,0.08-70.55,44.28-70.55,44.28
          c-24.03,18.75,17.02,25.46,70.23,25.77c2.87-7.41,9.5-12.61,17.24-12.61c5.26,0,10.01,2.41,13.41,6.27
          c5.18-14.72,18.02-25.15,33.03-25.15c11.98,0,22.56,6.64,28.99,16.79c2.6-1.02,5.42-1.58,8.36-1.58c7.11,0,13.48,3.26,17.81,8.4
          c20.62-3.08,34.26-6.69,34.26-10.13C1015.36,962.37,989.58,932.43,946.43,948z"
            data-ref="terra"
          :style="`fill: ${drawingColors.terra}!important;`"
          @click="changeColor($event)"
        />
        <path class="st24" d="M1005.77,992.82c6.01,0,10.88,5.04,10.88,11.26h-21.76C994.89,997.86,999.76,992.82,1005.77,992.82 L1005.77,992.82z"
          data-ref="terra"
          :style="`fill: ${drawingColors.terra}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </g>
    <g id="copa_x5F_01">
      <polygon
        class="st1" points="457.56,556.9 451.9,551.24 435.47,558.45 434.44,556.09 449.95,549.29 433.8,533.14 435.62,531.33 457.56,553.26 457.56,535.87 442.21,520.51 444.03,518.68 457.56,532.21 457.56,512.93 462.71,512.93 462.71,523.81 471.65,514.87 473.47,516.68 462.71,527.45 462.71,564.82 471.43,558.29 471.43,526.9 474.01,526.9 474.01,538.63 484.07,528.57 485.89,530.39 474.01,542.26 474.01,556.37 487.49,546.28 489.04,548.34 462.71,568.05 462.71,582.97 514.15,582.97 461.42,452.43 406.33,582.97 457.56,582.97"
        data-ref="copa"
        :style="`fill: ${drawingColors.copa}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="tronco_x5F_01">
      <polygon
        class="st28" points="489.04,548.34 487.49,546.28 474.01,556.37 474.01,542.26 485.89,530.39 484.07,528.57 474.01,538.63 474.01,526.9 471.43,526.9 471.43,558.29 462.71,564.82 462.71,527.45 473.47,516.68 471.65,514.87 462.71,523.81 462.71,512.93 457.56,512.93 457.56,532.22 444.03,518.68 442.21,520.51 457.56,535.87 457.56,553.26 435.62,531.33 433.8,533.14 449.95,549.29 434.44,556.09 435.47,558.45 451.9,551.25 457.56,556.9 457.56,668.08 462.71,668.08 462.71,568.05"
        data-ref="tronco1"
        :style="`fill: ${drawingColors.tronco1}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="copa_x5F_02">
      <polygon
        class="st1" points="551.77,526.67 544.28,519.18 522.54,528.71 521.19,525.59 541.7,516.6 520.33,495.23 522.75,492.83 551.77,521.85 551.77,498.84 531.46,478.52 533.87,476.11 551.77,494.01 551.77,468.49 558.58,468.49 558.58,482.89 570.4,471.07 572.82,473.47 558.58,487.7 558.58,537.14 570.12,528.51 570.12,486.98 573.52,486.98 573.52,502.5 586.83,489.18 589.24,491.6 573.52,507.3 573.52,525.96 591.35,512.62 593.41,515.35 558.58,541.41 558.58,561.14 626.62,561.14 556.87,388.48 483.99,561.14 551.77,561.14"
        data-ref="copa"
        :style="`fill: ${drawingColors.copa}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="tronco_x5F_02">
      <polygon
        class="st28" points="593.41,515.35 591.35,512.62 573.52,525.96 573.52,507.3 589.24,491.6 586.83,489.18 573.52,502.5 573.52,486.98 570.12,486.98 570.12,528.51 558.58,537.14 558.58,487.7 572.82,473.47 570.4,471.07 558.58,482.89 558.58,468.49 551.77,468.49 551.77,494.01 533.87,476.11 531.46,478.52 551.77,498.84 551.77,521.85 522.75,492.83 520.33,495.23 541.7,516.6 521.19,525.59 522.54,528.71 544.28,519.18 551.77,526.67 551.77,673.73 558.58,673.73 558.58,541.41"
        data-ref="tronco1"
        :style="`fill: ${drawingColors.tronco1}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="copa_x5F_03">
      <polygon
        class="st1" points="631.92,562.09 625.85,556.02 608.25,563.74 607.15,561.21 623.76,553.93 606.46,536.63 608.41,534.69 631.92,558.19 631.92,539.55 615.47,523.1 617.42,521.14 631.92,535.64 631.92,514.98 637.43,514.98 637.43,526.64 647.01,517.06 648.96,519 637.43,530.53 637.43,570.57 646.77,563.57 646.77,529.95 649.53,529.95 649.53,542.52 660.31,531.73 662.26,533.69 649.53,546.4 649.53,561.51 663.97,550.71 665.63,552.92 637.43,574.02 637.43,590 692.53,590 636.04,450.18 577.03,590 631.92,590"
        data-ref="copa"
        :style="`fill: ${drawingColors.copa}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="tronco_x5F_03">
      <polygon
        class="st28" points="665.63,552.92 663.97,550.71 649.53,561.51 649.53,546.4 662.26,533.69 660.31,531.73 649.53,542.52 649.53,529.95 646.77,529.95 646.77,563.57 637.43,570.57 637.43,530.53 648.96,519 647.01,517.06 637.43,526.64 637.43,514.98 631.92,514.98 631.92,535.64 617.42,521.14 615.47,523.1 631.92,539.55 631.92,558.19 608.41,534.69 606.46,536.63 623.76,553.93 607.15,561.21 608.25,563.74 625.85,556.02 631.92,562.09 631.92,681.17 637.43,681.17 637.43,574.02"
        data-ref="tronco1"
        :style="`fill: ${drawingColors.tronco1}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="copa_x5F_04">
      <path class="st1" d="M740.17,342.09c-33.9,0-61.37,27.5-61.37,61.39c0,32.25,24.89,58.7,56.52,61.18v-4.87l-33.83-33.8l3.05-3.06
        l16.2,16.19v-23.31h4.32v27.5h-0.13l10.39,10.38v-44.83l-6.81-6.81v0.02h-19.4v-4.32h15.1l-13.18-13.18l3.05-3.04l21.25,21.23
        V377.4h6.48v16.06l13.63-13.64l3.06,3.06l-16.69,16.68v35.57l18.86-18.85h-0.11v-20.29h4.32v16.08l8.85-8.85l3.04,3.06
        l-17.36,17.35l18.67,0.02v4.32l-22.98-0.02l-13.28,13.28v23.6c33.15-0.87,59.75-28.01,59.75-61.35
        C801.55,369.59,774.08,342.09,740.17,342.09z"
        data-ref="copa2"
        :style="`fill: ${drawingColors.copa2}!important;`"
        @click="changeColor($event)"
      />
    </g>
    <g id="tronco_x5F_04">
      <polygon
        class="st28" points="778.06,427.97 778.06,423.66 759.39,423.64 776.75,406.28 773.71,403.22 764.86,412.07 764.86,395.99 760.55,395.99 760.55,416.28 760.66,416.28 741.8,435.13 741.8,399.56 758.49,382.88 755.43,379.82 741.8,393.46 741.8,377.4 735.32,377.4 735.32,402.75 714.06,381.52 711.01,384.56 724.2,397.74 709.1,397.74 709.1,402.06 728.51,402.06 728.51,402.04 735.32,408.85 735.32,453.69 724.93,443.31 725.05,443.31 725.05,415.81 720.73,415.81 720.73,439.12 704.53,422.93 701.48,425.99 735.32,459.79 735.32,510.45 741.8,510.45 741.8,441.23 755.07,427.96"
        data-ref="tronco2"
        :style="`fill: ${drawingColors.tronco2}!important;`"
        @click="changeColor($event)"
      />
    </g>
</svg>
  </div>
  <div class="o-Drawing__colors">
    <div class="o-Colors">
      <a
        v-for="(color, index) in COLORS"
        :key="index"
        href="#"
        class="o-Color"
        :style="`background-color:${color};`"
        @click.prevent="selectColor(color)"
      />
    </div>
    <button
      class="o-Drawing__action"
      @click.prevent="saveDrawing"
    >
      Guardar
    </button>
    <button
      class="o-Drawing__action"
      @click.prevent="clearDrawing"
    >
      Limpar
    </button>
  </div>
</div>
</template>

<script>
import COLORS from '@/enums';

const { saveSvgAsPng } = require('save-svg-as-png');

export default {
  name: 'Nature',
  data: () => ({
    COLORS,
    selectedColor: COLORS.WHITE,
    drawingColors: {},
  }),
  mounted() {
    this.drawingColors = JSON.parse(this.$store.state.aquaDrawing);
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
    },
    changeColor(e) {
      this.$set(this.drawingColors, e.target.getAttribute('data-ref'), this.selectedColor);
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify(this.drawingColors),
      });
    },
    saveDrawing() {
      try {
        saveSvgAsPng(document.getElementById('drawing'), 'drawing.png');
      } catch (err) {
        console.log('Error trying to download image');
      }
    },
    clearDrawing() {
      Object.keys(this.drawingColors).forEach((key) => {
        this.$set(this.drawingColors, key, COLORS.WHITE);
      });
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify({}),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#FFFFFF;stroke:#221F1F;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st2{opacity:0.3;}
.st3{fill:#221F1F;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#221F1F;}
.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#BCBDBF;}
.st6{fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st7{fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
.st9{fill:#231F20;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
.st10{fill:none;stroke:#1D1C1A;stroke-width:0.2;stroke-miterlimit:10;stroke-dasharray:1;}
.st11{fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.2;stroke-miterlimit:10;stroke-dasharray:1;}
.st12{fill:#FFFFFF;stroke:#231F20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st13{fill:none;stroke:#231F20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st14{fill:none;stroke:#231F20;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st15{opacity:0.8;}
.st16{opacity:0.5;}
.st17{fill:#FFFFFF;}
.st18{fill:none;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
.st19{fill:none;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:0.9971,0.9971;}
.st20{fill:none;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:1.0034,1.0034;}
.st21{opacity:0.5;fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:1;}
.st22{fill:#231F20;}
.st23{fill-rule:evenodd;clip-rule:evenodd;fill:#231F20;}

.st24{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st25{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st26{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st27{fill:none;stroke:#221F1F;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st28{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.25;stroke-miterlimit:10;}
</style>
