<template>
<div
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
  <br>
  <svg v-if="!hovering" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Layer_48" data-name="Layer 48">
          <circle class="cls-1" cx="127.56" cy="127.56" r="118.36" />
          <path class="cls-2" d="M196.65,179.34l.63-114.89s12,0,12.63,13.55,0,40,0,40a8.07,8.07,0,0,1-3,6.68c-3.39,2.77-3.54,2.25-3.22,8.11s2.27,34.05,3.49,46.24C207.8,184.83,197.89,188.57,196.65,179.34Z" />
          <path
              class="cls-2"
              d="M45.33,69.26s0,20.92,0,28.17c0,4.4.6,6.78,2.15,9.56a51.31,51.31,0,0,1,4.24,9,14.3,14.3,0,0,1,.84,6.28c-.08,6.23-2.07,41.79-1.76,57,.14,6.5,9.37,7.86,10.11-.16.92-9.88-.78-57-.78-57s-.08-4.32.66-6.33c1-2.78,5.59-9,5.59-9s2.25-2.85,2.15-9.08c0-3.66-.12-29.12-.12-29.12"
          />
          <line class="cls-3" x1="53.07" y1="68.58" x2="53.07" y2="95.88" />
          <line class="cls-3" x1="60.79" y1="68.58" x2="60.79" y2="95.88" />
          <circle class="cls-4" cx="128.21" cy="128.95" r="51.23" />
          <circle class="cls-4" cx="128.21" cy="128.95" r="31.82" />
      </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 255.12 255.12">
      <defs>
          <clipPath id="clip-path"><circle class="cls-1a" cx="127.56" cy="127.56" r="116.97" /></clipPath>
      </defs>
      <g id="Layer_49" data-name="Layer 49">
          <g class="cls-2a"><image width="5472" height="3648" transform="translate(3.79 10.23) scale(0.07)" xlink:href="/toalhete.jpeg" /></g>
          <circle class="cls-3a" cx="127.56" cy="127.56" r="118.36" />
      </g>
  </svg>
</div>
</template>

<script>
export default {
  data: () => ({
    hovering: false,
  }),
};
</script>

<style scoped>
  svg {
    height: auto;
  }
  .cls-1 {
      fill: #51a4b0;
      stroke-width: 11.34px;
  }
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4 {
      stroke: #fff;
  }
  .cls-1,
  .cls-4 {
      stroke-miterlimit: 10;
  }
  .cls-2,
  .cls-3,
  .cls-4 {
      fill: none;
      opacity: 0.5;
  }
  .cls-2,
  .cls-3 {
      stroke-linecap: round;
      stroke-linejoin: round;
  }
  .cls-2,
  .cls-4 {
      stroke-width: 5px;
  }
  .cls-3 {
      stroke-width: 3px;
  }

  .cls-1a,
  .cls-3a {
      fill: none;
  }
  .cls-2a {
      clip-path: url(#clip-path);
  }
  .cls-3a {
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 11.34px;
  }
</style>
