<template>
<div class="o-Drawing">
  <div class="o-Drawing__svg">
    <svg id="drawing" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
      <g id="background">
        <rect
          y="0" class="st0" width="1920" height="880.86"
          data-ref="background"
          :style="`fill: ${drawingColors.background}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="traco_x5F_bx">
        <rect
          y="1046.72" class="st1" width="1920" height="33.33"
          data-ref="traco_x5F_bx"
          :style="`fill: ${drawingColors.traco_x5F_bx}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="traco_x5F_topo">
        <rect
          x="-0.12" y="880.86" class="st1" width="1920.12" height="28.53"
          data-ref="traco_x5F_topo"
          :style="`fill: ${drawingColors.traco_x5F_topo}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="estrada">
        <rect
          x="-0.12" y="909.39" class="st1" width="1920.12" height="137.33"
          data-ref="estrada"
          :style="`fill: ${drawingColors.estrada}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tracos_x5F_estrada">
        <rect
          x="359.16" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="85.16" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="687.36" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1015.56" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1343.76" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
        <rect
          x="1649.76" y="969.8" class="st1" width="143.87" height="17.47"
          data-ref="tracos_x5F_estrada"
          :style="`fill: ${drawingColors.tracos_x5F_estrada}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="monte">
        <path
          class="st1" d="M0,467.8l279.49-95.61c0,0,260.67-81.63,566.76-1.11c121.37,31.93,1000.82,137,1073.75,181.72v328.05H0.12 L0,467.8z"
          data-ref="monte"
          :style="`fill: ${drawingColors.monte}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sol">
        <g>
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 282.4338 1007.5367)" class="st1" cx="1357.42" cy="162.84" rx="65.1" ry="65.1"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1421.69,125.73L1421.69,125.73c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7c2.54-1.46,5.78-0.6,7.24,1.94l0,0 c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7C1426.4,129.14,1423.16,128.27,1421.69,125.73z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1421.69,199.95L1421.69,199.95c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7c2.54,1.46,3.4,4.71,1.94,7.24l0,0 c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7C1421.1,205.73,1420.23,202.48,1421.69,199.95z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1357.42,237.06L1357.42,237.06c2.93,0,5.3,2.37,5.3,5.3v23.4c0,2.93-2.37,5.3-5.3,5.3l0,0 c-2.93,0-5.3-2.37-5.3-5.3v-23.4C1352.12,239.43,1354.49,237.06,1357.42,237.06z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1293.15,199.95L1293.15,199.95c1.46,2.54,0.6,5.78-1.94,7.24l-20.26,11.7c-2.54,1.46-5.78,0.6-7.24-1.94l0,0 c-1.46-2.54-0.6-5.78,1.94-7.24l20.26-11.7C1288.44,196.54,1291.69,197.41,1293.15,199.95z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1293.15,125.73L1293.15,125.73c-1.46,2.54-4.71,3.4-7.24,1.94l-20.26-11.7c-2.54-1.46-3.4-4.71-1.94-7.24 l0,0c1.46-2.54,4.71-3.4,7.24-1.94l20.26,11.7C1293.74,119.96,1294.61,123.2,1293.15,125.73z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1357.42,88.63L1357.42,88.63c-2.93,0-5.3-2.37-5.3-5.3v-23.4c0-2.93,2.37-5.3,5.3-5.3l0,0 c2.93,0,5.3,2.37,5.3,5.3v23.4C1362.72,86.25,1360.35,88.63,1357.42,88.63z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1320.31,98.57L1320.31,98.57c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05c-1.46-2.54-0.6-5.78,1.94-7.24l0,0 c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05C1323.72,93.86,1322.85,97.1,1320.31,98.57z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1283.21,162.84L1283.21,162.84c0,2.93-2.37,5.3-5.3,5.3h-15.07c-2.93,0-5.3-2.37-5.3-5.3l0,0 c0-2.93,2.37-5.3,5.3-5.3h15.07C1280.83,157.54,1283.21,159.91,1283.21,162.84z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1320.31,227.11L1320.31,227.11c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05c-1.46,2.54-4.71,3.4-7.24,1.94l0,0 c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05C1314.54,226.52,1317.78,225.65,1320.31,227.11z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1394.53,227.11L1394.53,227.11c2.54-1.46,5.78-0.6,7.24,1.94l7.53,13.05c1.46,2.54,0.6,5.78-1.94,7.24l0,0 c-2.54,1.46-5.78,0.6-7.24-1.94l-7.53-13.05C1391.12,231.82,1391.99,228.58,1394.53,227.11z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1431.64,162.84L1431.64,162.84c0-2.93,2.37-5.3,5.3-5.3H1452c2.93,0,5.3,2.37,5.3,5.3l0,0 c0,2.93-2.37,5.3-5.3,5.3h-15.07C1434.01,168.14,1431.64,165.77,1431.64,162.84z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1394.53,98.57L1394.53,98.57c-2.54-1.46-3.4-4.71-1.94-7.24l7.53-13.05c1.46-2.54,4.71-3.4,7.24-1.94l0,0 c2.54,1.46,3.4,4.71,1.94,7.24l-7.53,13.05C1400.31,99.16,1397.06,100.03,1394.53,98.57z"
            data-ref="sol"
            :style="`fill: ${drawingColors.sol}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="rosto_x5F_sol">
        <g class="st2">
          <path class="st3" d="M1345.52,209.22c-7.34,0-14.48-3.45-18.97-9.49c-0.84-1.13-0.6-2.73,0.53-3.57c1.13-0.84,2.73-0.6,3.57,0.53
            c4.5,6.06,12.41,8.78,19.69,6.78c4-1.1,7.54-3.56,9.98-6.9c0.83-1.14,2.43-1.39,3.57-0.56c1.14,0.83,1.39,2.43,0.56,3.57
            c-3.12,4.28-7.65,7.41-12.75,8.82C1349.66,208.95,1347.58,209.22,1345.52,209.22z"/>
        </g>
        <g class="st2">
          <path class="st4" d="M1326.36,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
            C1319,166.87,1322.29,163.58,1326.36,163.58"/>
        </g>
        <g class="st2">
          <path class="st4" d="M1364.62,163.58c4.06,0,7.35,3.29,7.35,7.35c0,4.06-3.29,7.35-7.35,7.35c-4.06,0-7.35-3.29-7.35-7.35
            C1357.27,166.87,1360.56,163.58,1364.62,163.58"/>
        </g>
      </g>
      <g id="volante">
        <rect
          x="971.22" y="641.3" class="st1" width="13.14" height="16.72"
          data-ref="volante"
          :style="`fill: ${drawingColors.volante}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_02">
        <path
          class="st1" d="M559.71,806.63c0,34.91,28.32,63.22,63.23,63.22c34.91,0,63.14-28.31,63.14-63.22 c0-34.83-28.23-63.23-63.14-63.23C588.03,743.4,559.71,771.81,559.71,806.63z"
          data-ref="roda_x5F_02"
          :style="`fill: ${drawingColors.roda_x5F_02}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M922.29,806.63c0,34.91,28.28,63.22,63.14,63.22c34.95,0,63.28-28.31,63.28-63.22 c0-34.83-28.32-63.23-63.28-63.23C950.57,743.4,922.29,771.81,922.29,806.63z"
          data-ref="roda_x5F_02"
          :style="`fill: ${drawingColors.roda_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="roda_x5F_01">
        <path
          class="st1" d="M599.88,806.63c0,12.64,10.29,23.06,23.06,23.06c12.78,0,23.02-10.42,23.02-23.06 c0-12.74-10.24-23.07-23.02-23.07C610.17,783.56,599.88,793.89,599.88,806.63z"
          data-ref="roda_x5F_01"
          :style="`fill: ${drawingColors.roda_x5F_01}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M962.37,806.63c0,12.64,10.37,23.06,23.06,23.06c12.74,0,23.06-10.42,23.06-23.06 c0-12.74-10.32-23.07-23.06-23.07C972.74,783.56,962.37,793.89,962.37,806.63z"
          data-ref="roda_x5F_01"
          :style="`fill: ${drawingColors.roda_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="ficha_x5F_02">
        <path
          class="st1" d="M677.75,489.11c0-2.4-1.95-4.35-4.37-4.35h-35.75v8.69h35.75C675.8,493.45,677.75,491.5,677.75,489.11z M677.75,453.73c0-2.4-1.95-4.35-4.37-4.35h-35.75v8.69h35.75C675.8,458.07,677.75,456.11,677.75,453.73z"
          data-ref="ficha_x5F_02"
          :style="`fill: ${drawingColors.ficha_x5F_02}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="bota_x5F_esq">
        <path class="st5" d="M1268.5,855.56c3.38-2.46,18.27-2.38,21.85,0c3.36,1.76,11.09,3.92,15.9,1.79c0.07-0.32,0.1-0.65,0.06-1
          c-0.53-4.13-2-7.62-4.2-10.46l0,0c-1.29-5.9-2.84-13.75-3.58-16.56c-0.25-0.95-1.17-1.54-2.14-1.4c-2.54,0.38-7.36-1.78-10.35-1.78
          c-2.59,0-10.82,0-13.45,0c-3,0-7.82,2.16-10.35,1.78c-0.97-0.14-1.89,0.45-2.14,1.4c-0.74,2.81-2.29,10.66-3.58,16.56
          c-0.13,0.6-2.67,6.88-3.13,10.37c-0.05,0.41-0.02,0.8,0.07,1.18C1259.43,859.94,1265.34,856.96,1268.5,855.56z"
          data-ref="bota"
          :style="`fill: ${drawingColors.bota}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="bota_x5F_dta">
        <path class="st5" d="M1328.08,855.34c3.38-2.46,18.27-2.38,21.85,0c3.36,1.76,11.09,3.92,15.9,1.79c0.07-0.32,0.1-0.65,0.06-1
          c-0.53-4.13-2-7.62-4.2-10.46c-1.29-5.9-2.84-13.75-3.58-16.56c-0.25-0.95-1.17-1.54-2.14-1.4c-2.54,0.38-7.36-1.78-10.35-1.78
          c-2.59,0-10.82,0-13.45,0c-3,0-7.82,2.16-10.35,1.78c-0.97-0.14-1.89,0.45-2.14,1.4c-0.74,2.81-2.29,10.66-3.58,16.56
          c-0.13,0.6-2.67,6.88-3.13,10.37c-0.05,0.41-0.02,0.8,0.07,1.18C1319.01,859.72,1324.92,856.74,1328.08,855.34z"
          data-ref="bota"
          :style="`fill: ${drawingColors.bota}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sola_x5F_esq">
        <path class="st5" d="M1306.33,857.32c-4.81,2.19-12.6,0.01-15.99-1.76c-3.58-2.38-18.46-2.46-21.85,0
          c-3.18,1.41-9.13,4.41-15.14,1.84c0,0-1.01,3.52-1.01,3.7v0c0,1.15,0.95,3.77,2.08,3.95c10.74,1.76,49.41,1.25,50.8,0
          c1.15,0,2.08-3.95,2.08-3.95C1308.3,859.09,1306.33,857.32,1306.33,857.32z"
          data-ref="sola"
          :style="`fill: ${drawingColors.sola}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="sola_x5F_dta">
        <path class="st5" d="M1365.91,857.1c-4.81,2.19-12.61,0.01-15.99-1.76c-3.58-2.38-18.46-2.46-21.85,0
          c-3.18,1.41-9.14,4.41-15.14,1.84c0,0-1.5,1.53-0.94,3.19c-0.04,0.16-0.07,0.33-0.07,0.51c0,1.15,0.95,3.77,2.08,3.95
          c10.74,1.76,49.41,1.25,50.8,0c1.15,0,2.08-2.8,2.08-3.95c0,0,0,0,0,0C1367.88,858.87,1365.91,857.1,1365.91,857.1z"
          data-ref="sola"
          :style="`fill: ${drawingColors.sola}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <!-- <g id="persponto_x5F_botas">
        <path class="st6" d="M1328.61,826.96l-3.18,14.09c-0.43,1.89,1.05,4.08,2.94,3.68c5.4-1.12,14.95-0.97,20.2-0.17
          c1.68,0.25,3.09-1.25,2.78-2.91l-2.82-15.07"/>
        <path class="st7" d="M1350.65,835.9c0,0,5.34,0.71,9.49,4.99"/>
        <path class="st7" d="M1351.07,838.12c0,0,5.36,1.03,9.85,6.15"/>
        <path class="st7" d="M1325.28,837.9c0,0-3.92,0.79-8.54,5.53"/>
        <path class="st7" d="M1325.66,840.01c0,0-5.07,1.56-9.21,6.65"/>
        <path class="st6" d="M1269.03,827.18l-3.18,14.09c-0.43,1.89,1.05,4.08,2.94,3.68c5.4-1.12,14.95-0.97,20.2-0.17
          c1.68,0.25,3.09-1.25,2.78-2.91l-2.82-15.07"/>
        <path class="st7" d="M1291.08,836.89c0,0,4.79,0.17,9.58,4.79"/>
        <path class="st7" d="M1291.7,839.17c0,0,5.16,0.72,9.64,5.83"/>
        <path class="st7" d="M1265.71,838.12c0,0-2.81,0-8.54,4.76"/>
        <path class="st7" d="M1265.97,840.23c0,0-4.99,1.81-9.12,6.17"/>
      </g> -->
      <!-- <g id="bota_x5F_sola_x5F_02">
        <path class="st8" d="M1365.9,860.49c-4.81,2.19-12.61,0.01-15.99-1.76c-3.58-2.38-18.46-2.46-21.85,0
          c-3.18,1.41-9.14,4.41-15.14,1.84"/>
        <path class="st9" d="M1306.32,860.71c-4.81,2.19-12.61,0.01-15.99-1.76c-3.58-2.38-18.46-2.46-21.85,0
          c-3.18,1.41-9.13,4.41-15.14,1.84"/>
      </g> -->
      <!-- <g id="cordoes">
        <path class="st10" d="M1330,840.15c1.64-0.6,7.68-4.73,16.58-6.05"/>
        <path class="st10" d="M1330,840.15c8.56-1.35,17.59,0,17.59,0"/>
        <path class="st10" d="M1347.81,840.01c-1.64-0.6-7.86-4.59-16.76-5.91"/>
        <path class="st10" d="M1338.8,827.86c0.05-0.25,3.24-0.52,5.67,1.26c0.22,0.17,0.91,0.87,2.27,2.27c2.06,2.12,1.73,1.91,2.52,2.65
          c1.64,1.54,2.27,1.71,2.52,2.64c0.19,0.73,0.11,1.76-0.5,2.27c-0.96,0.81-2.95,0.09-4.03-1.01c-0.85-0.86-0.64-1.45-1.51-3.65
          c-0.65-1.67-1.27-3.22-2.52-4.41C1341.24,828.01,1338.76,828.09,1338.8,827.86z"/>
        <path class="st10" d="M1338.61,827.86c-0.05-0.25-3.24-0.52-5.67,1.26c-0.22,0.17-0.91,0.87-2.27,2.27
          c-2.06,2.12-1.73,1.91-2.52,2.65c-1.64,1.54-2.27,1.71-2.52,2.64c-0.19,0.73-0.11,1.76,0.5,2.27c0.96,0.81,2.95,0.09,4.03-1.01
          c0.85-0.86,0.64-1.45,1.51-3.65c0.65-1.67,1.27-3.22,2.52-4.41C1336.18,828.01,1338.65,828.09,1338.61,827.86z"/>
        <g>
          <line class="st10" x1="1271.48" y1="834.32" x2="1286.17" y2="829.43"/>
          <path class="st10" d="M1270.43,840.37c1.64-0.6,7.68-4.73,16.58-6.05"/>
          <path class="st11" d="M1270.43,840.37c8.56-1.35,17.59,0,17.59,0"/>
          <line class="st10" x1="1272.5" y1="829.43" x2="1287" y2="834.32"/>
          <path class="st10" d="M1288.23,840.23c-1.64-0.6-7.86-4.59-16.76-5.91"/>
          <path class="st10" d="M1279.23,828.08c0.05-0.25,3.24-0.52,5.67,1.26c0.22,0.17,0.91,0.87,2.27,2.27
            c2.06,2.12,1.73,1.91,2.52,2.64c1.64,1.54,2.27,1.71,2.52,2.64c0.19,0.73,0.11,1.76-0.5,2.27c-0.96,0.81-2.95,0.09-4.03-1.01
            c-0.85-0.86-0.64-1.45-1.51-3.65c-0.65-1.67-1.27-3.22-2.52-4.41C1281.66,828.23,1279.19,828.31,1279.23,828.08z"/>
          <path class="st10" d="M1279.03,828.08c-0.05-0.25-3.24-0.52-5.67,1.26c-0.22,0.17-0.91,0.87-2.27,2.27
            c-2.06,2.12-1.73,1.91-2.52,2.64c-1.64,1.54-2.27,1.71-2.52,2.64c-0.19,0.73-0.11,1.76,0.5,2.27c0.96,0.81,2.95,0.09,4.03-1.01
            c0.85-0.86,0.64-1.45,1.51-3.65c0.65-1.67,1.27-3.22,2.52-4.41C1276.6,828.23,1279.08,828.31,1279.03,828.08z"/>
        </g>
      </g> -->
      <g id="calcas">
        <path class="st5" d="M1257.61,827.3c-0.92,3.32-2.62,8.32-2.59,10.81c0.02,1.91,0.98,2.49,2.15,2.33c0.47-0.06,0.84-2.06,1.17-2.39
          c0.77-0.78,2.33-0.98,4.2-1.13c6.5-0.51,22.18-3.61,24.49-1.6c1.75,1.52,10.47,2.17,13.21,4.24c1.48,1.12,1.71,3.32,2.19,3.34
          c2.63,0.13,2.29-3.86,2.28-4.53l-0.91-7.51c-4.92-2.08-9.37-4.38-14.32-4.86C1276.94,824.79,1265.58,825.94,1257.61,827.3z"
          data-ref="calcas"
          :style="`fill: ${drawingColors.calcas}!important;`"
          @click="changeColor($event)"
        />
        <path class="st5" d="M1292.43,818.95c3.39,0.44,6.86,2.24,10.55,3.9c0-0.48-0.01-0.97,0.02-1.45l0.61-8.27 c-12.86-3.82-30.6-7.17-44.86-0.88l-0.35,8.76c0,0.05,0,0.1,0,0.15C1265.46,818.99,1276.95,816.92,1292.43,818.95z"
          data-ref="calcas"
          :style="`fill: ${drawingColors.calcas}!important;`"
          @click="changeColor($event)"
        />
        <path class="st5" d="M1329.49,826.58c-5.63,0.51-8.54,2.69-13.52,4.65c-1.13,0.42-0.87,0.86-1.02,1.58l-0.77,6.03
          c-0.02,0.67-0.38,4.66,2.25,4.54c0.48-0.02,0.73-2.22,2.22-3.33c2.75-2.05,11.47-2.64,13.23-4.15c2.32-1.99,17.98,1.2,24.48,1.76
          c1.88,0.16,3.43,0.37,4.2,1.15c0.32,0.33-0.09,2.06,0.38,2.12c1.17,0.17,2.13-0.41,2.16-2.32c0.05-2.49-0.98-7.27-1.77-10.55
          C1353.38,826.66,1342.02,825.44,1329.49,826.58z"
          data-ref="calcas"
          :style="`fill: ${drawingColors.calcas}!important;`"
          @click="changeColor($event)"
        />
        <path class="st5" d="M1359.69,794.55l-0.54-18.94l-2.12-29.52c0,0-2.39-17.48-2.8-27.54c-0.01-0.2-0.16-0.38-0.36-0.38
          c-11.68-0.08-28.49,0.13-41.12,0l0-0.46c-0.96-0.03-1.93-0.07-2.91-0.11c-11.55,0-31.36-0.85-44.02-1.3
          c-0.2-0.01-0.35,0.18-0.36,0.38c-0.47,10.06-2.98,28.67-2.98,28.67l-2.31,29.51l-0.66,18.94l-0.44,11.15
          c21.98-6.78,37.55-2.23,45.08,1.25l3.03-41.13c0.05-1.73,4.42-1.73,4.49,0.15l0.42,5.46l0.21,2.75l0.14,1.88l2.42,31.43
          c7.5-3.44,23.15-8,45.23-1.03L1359.69,794.55z"
          data-ref="calcas"
          :style="`fill: ${drawingColors.calcas}!important;`"
          @click="changeColor($event)"
        />
        <path class="st5" d="M1360.3,813.02c-14.25-6.4-32.05-3.13-44.94,0.61l0.63,8.25c0.03,0.48,0.02,0.97,0.01,1.45 c3.7-1.64,7.19-3.41,10.57-3.84c15.49-1.93,26.96,0.21,34.01,2.43c0-0.05,0-0.1,0-0.15L1360.3,813.02z"
          data-ref="calcas"
          :style="`fill: ${drawingColors.calcas}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="riscas_x5F_calcas">
        <path
          class="st5" d="M1303.61,813.13l0.51-6.95c-7.53-3.48-23.1-8.03-45.08-1.25l-0.29,7.32 C1273.01,805.96,1290.75,809.31,1303.61,813.13z"
          data-ref="riscas_x5F_calcas"
          :style="`fill: ${drawingColors.riscas_x5F_calcas}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M1302.98,822.85c-3.69-1.67-7.16-3.46-10.55-3.9c-15.47-2.03-26.96,0.04-34.02,2.21
          c0.07,1.76-0.12,3.52-0.55,5.23c-0.07,0.29-0.16,0.6-0.25,0.92c7.96-1.36,19.33-2.51,31.87-1.29c4.95,0.48,9.4,2.78,14.32,4.86
          l-0.7-5.77C1303.02,824.35,1302.98,823.6,1302.98,822.85z"
          data-ref="riscas_x5F_calcas"
          :style="`fill: ${drawingColors.riscas_x5F_calcas}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M1326.59,819.5c-3.39,0.42-6.87,2.19-10.57,3.84c-0.01,0.75-0.05,1.5-0.15,2.25l-0.02,0.16
          c-0.38,2.56-0.79,4.69-0.79,5.81c4.99-1.97,8.81-4.45,14.43-4.97c12.54-1.14,23.89,0.08,31.84,1.49c-0.08-0.32-0.15-0.63-0.22-0.91
          c-0.42-1.71-0.59-3.47-0.52-5.23C1353.55,819.71,1342.07,817.57,1326.59,819.5z"
          data-ref="riscas_x5F_calcas"
          :style="`fill: ${drawingColors.riscas_x5F_calcas}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M1314.83,806.72l0.53,6.91c12.89-3.75,30.69-7.01,44.94-0.61l-0.24-7.33 C1337.98,798.73,1322.33,803.28,1314.83,806.72z"
          data-ref="riscas_x5F_calcas"
          :style="`fill: ${drawingColors.riscas_x5F_calcas}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="tronco">
        <path
          class="st5" d="M1351.41,670.55c-2.12-5.84-4.76-39.46,0.89-41.87c0.92-0.17,1.83-0.22,2.72-0.15
          c-0.58-0.3-1.23-0.48-1.92-0.48h-87.98c-0.73,0-1.41,0.2-2.01,0.53c1.01-0.12,2.05-0.1,3.1,0.1c5.65,2.41,3.01,36.03,0.89,41.87
          c-0.83,2.28-2.21,6.29-3.55,10.24l1.96,36.35c0,2.3,1.9,3.85,4.19,4.17c18.84,2.63,54.59,2.86,78.81,0
          c2.3-0.27,4.19-1.87,4.19-4.17l2-37.08C1353.45,676.35,1352.19,672.68,1351.41,670.55z"
          data-ref="tronco"
          :style="`fill: ${drawingColors.tronco}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="manga_x5F_esq_000">
        <path
          class="st5" d="M1351.41,670.3c1.98,5.48,7.16,21,7.26,21.52c0.4,2.17,0.53,9.8,2.85,23.67c0.19,1.12,1.11,0.95,1.42,1.86
          c1.07,3.09,0.7,8.6,1.81,8.76c7.71,1.15,21.43-1.84,27.05-5.49c0,0-1.19-5.39-1.88-8.51c-0.18-0.79,0.56-1.42,0.61-1.72
          c0.76-4.28-1.59-25.5-3.35-30.14c-9-23.64-19.78-54.89-34.89-52.03C1346.66,630.65,1349.29,664.43,1351.41,670.3z"
          data-ref="manga_x5F_esq_000"
          :style="`fill: ${drawingColors.manga_x5F_esq_000}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mao_x5F_dta">
        <path
          class="st5" d="M1384.55,743.82l-2.22-5.86l-1.47-1.12l1.52,7.31c0,0-0.65,2.98-3.01,3.45l0.81,1.2c0,0-1.07,0.59-1.08,3.53 c-0.01,2.93,3.66,2.43,3.66,2.43c0.01-2.93,1.73-5.92,1.73-5.92L1384.55,743.82z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M1393.37,726.09l-2.82-4.73c-5.57,2.96-16.11,5.29-23.41,4.96c0.15,1.52,0.45,3.44,1.04,5.61
          c0.54,2,2.48,5.13,3.1,7.01c1.12,3.38,2.33,6.17,3.42,6.99c6.27,4.75,7.69-1.78,7.69-1.78l-1.52-7.31l1.47,1.12l2.22,5.86
          l-0.05,5.01c0,0-1.72,2.98-1.73,5.92c-0.01,2.93,4.27,2.8,6.04,1.53c1.77-1.27,4.05-7.83,4.05-7.83s1.47-3.71,2-6.25
          c0.53-2.54,0.52-3.04,0.56-7.27C1395.46,729.65,1393.37,726.09,1393.37,726.09z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="manga_x5F_esq">
        <path
          class="st5" d="M1267.11,670.3c-1.98,5.48-7.16,21-7.26,21.52c-0.4,2.17-0.53,9.8-2.85,23.67c-0.19,1.12-1.11,0.95-1.42,1.86
          c-1.07,3.09-0.7,8.6-1.81,8.76c-7.71,1.15-21.43-1.84-27.05-5.49c0,0,1.19-5.39,1.88-8.51c0.18-0.79-0.56-1.42-0.61-1.72
          c-0.76-4.28,1.59-25.5,3.35-30.14c9-23.64,19.78-54.89,34.89-52.03C1271.87,630.65,1269.23,664.43,1267.11,670.3z"
          data-ref="manga_x5F_esq_000"
          :style="`fill: ${drawingColors.manga_x5F_esq_000}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="mao_x5F_esq">
        <path
          class="st5" d="M1247.18,751.73l0.33-1.41c-2.37,0.39-4.03-2.17-4.03-2.17l-1.16-7.38l-0.98,1.56l-0.01,6.27l1.81,4.67 c0,0,2.66,2.19,3.71,4.93c0,0,3.62-0.82,2.57-3.56C1248.39,751.91,1247.18,751.73,1247.18,751.73z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M1252.47,731.71c-0.2-2.13-0.57-3.95-0.95-5.4c-6.81,0.33-16.5-1.67-22.33-4.35l-2.35,13.18
          c0,0-0.7,4.07,1.2,8.99c1.52,3.95,1.7,4.42,3.09,6.61c1.39,2.19,4.08,5.14,4.08,5.14s4.44,5.34,6.55,5.9
          c2.11,0.56,6.16-0.82,5.11-3.56c-1.04-2.74-3.71-4.93-3.71-4.93l-1.81-4.67l0.01-6.27l0.98-1.56l1.16,7.38c0,0,3.63,5.61,7.82-1.05
          c0.73-1.16,0.87-4.19,0.73-7.75C1251.96,737.38,1252.67,733.77,1252.47,731.71z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="cinto_x5F_01">
        <path
          class="st5" d="M1264.77,717.17c0,0,37.15,3.52,89.52,1.84c0.85,4.92,1.54,9.34,1.54,9.34s-44.98,4.13-92.16-1.37 C1263.99,723.14,1264.77,717.17,1264.77,717.17z"
          data-ref="cinto_x5F_01"
          :style="`fill: ${drawingColors.cinto_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="presilha_x5F_calca">
        <path
          class="st5" d="M1330.77,730.26h-5.12c-0.31,0-0.57-0.25-0.57-0.57l-0.15-11.53c0-0.31,0.25-0.57,0.57-0.57h5.12 c0.31,0,0.57,0.25,0.57,0.57l0.15,11.53C1331.34,730,1331.08,730.26,1330.77,730.26z"
          data-ref="presilha_x5F_calca"
          :style="`fill: ${drawingColors.presilha_x5F_calca}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st5" d="M1282.16,729.51l5.12,0.32c0.52,0,0.95-0.42,0.95-0.95v-11.37c0-0.52-0.42-0.95-0.95-0.95l-5.12-0.27 c-0.52,0-0.95,0.42-0.95,0.95v11.32C1281.22,729.09,1281.64,729.51,1282.16,729.51z"
          data-ref="presilha_x5F_calca"
          :style="`fill: ${drawingColors.presilha_x5F_calca}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="cinto_0000014">
        <path
          class="st5" d="M1313.73,730.4l-13.11-0.12l-1.2-0.05c-0.49,0-0.89-0.38-0.89-0.85v-10.66c0-0.47,0.4-0.85,0.89-0.85 l14.31,0.37c0.49,0,0.89,0.38,0.89,0.85v10.45C1314.62,730.02,1314.22,730.4,1313.73,730.4z"
          data-ref="cinto_0000014"
          :style="`fill: ${drawingColors.cinto_0000014}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="traco_x5F_camisola">
        <line class="st5" x1="1309.12" y1="662.98" x2="1309.13" y2="646.87"/>
        <path class="st5" d="M1392.78,732.49c0,0-0.62,1.61-0.13,3.71c0.68,2.94,0.93,4.03,0.93,4.03l0.27,5.45"/>
        <path class="st5" d="M1229.64,740.92c0,0,1.14,1.29,1.43,3.42c0.4,3,0.55,4.1,0.55,4.1l1.67,5.2"/>
        <path class="st5" d="M1256.01,716.74c0,0-14.48-0.51-27.57-5.53"/>
        <path class="st5" d="M1362.51,716.74c0,0,14.48-0.51,27.57-5.53"/>
      </g>
      <g id="camisola">
        <polygon class="st5" points="1300.91,631.4 1309.13,646.87 1317.72,631.4"/>
      </g>
      <g id="pescoco">
        <polygon
          class="st13" points="1330.49,625.75 1322.12,625.75 1318.98,631.4 1300.91,631.4 1297.88,625.75 1289.07,625.75 1289.07,620.49 1330.49,620.49"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="colarinho">
        <polyline class="st5" points="1314.13,637.86 1321.18,625.17 1335.92,625.17 1323.62,647.33 1314.13,637.86"/>
        <polyline class="st5" points="1300.28,630.4 1304.48,638.22 1295.04,647.31 1282.31,625.17 1297.47,625.17 1300.28,630.4"/>
      </g>
      <g id="cabeca">
        <path
          class="st13" d="M1361.41,574.57c-0.9,0-1.75,0.18-2.55,0.5v-43.3h-95.03v44.06c-1.13-0.8-2.49-1.26-3.96-1.26
          c-3.94,0-7.13,3.36-7.13,7.51c0,4.15,3.19,7.51,7.13,7.51c1.46,0,2.82-0.47,3.96-1.26v14.36c0,9.79,8.01,17.79,17.79,17.79h59.44
          c9.79,0,17.79-8.01,17.79-17.79v-13.6c0.79,0.32,1.65,0.5,2.55,0.5c3.94,0,7.13-3.36,7.13-7.51
          C1368.54,577.93,1365.35,574.57,1361.41,574.57z"
          data-ref="skin"
          :style="`fill: ${drawingColors.skin}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="cabelo_x5F_02">
        <g>
          <path class="st13" d="M1345.24,516.58c5.21-22.56-33.73-23.4-37.09-27.66c-2.47,7.12,13.4,5.85-11.15,5.85
            c-7.92,0-13.47,0.56-19.32-3.6c-0.6,3.09,0.95,6.55,3.73,7.36c0.3,1.44-23.15-2.93-22.39-1.54c0.6,1.09,4.69,11.94,0.22,8.75
            c-5.01-3.57,1.72,18.86-4.51,15.92c-0.51,4.37-0.74,9.02-0.75,13.67c-0.05,16.09-2.43,33.39-1.76,43.99
            c1.72-0.68,5.57-1.25,8.62,3.47c1.33,5.41,2.75,11.48,3.93,14.66c0.04,0.42,0.07,0.85,0.11,1.3c0.13,1.77,2.29,2.95,3.1,0.96
            c1.74-4.32,0.19-24.65-0.54-38.21c1.11-15.83,5.83-30.3,23.05-25.11c22.1,6.66,32.02-2.97,44.94-2.82
            c6.47,0.08,14.41-2.2,18.11,15.95c1.04,5.11-3.19,43.98-0.71,50.14c0.8,1.99,2.97,0.81,3.1-0.96
            c1.54-21.34,9.88-20.53,12.66-19.42c0.82-13.15-2.29-29.76-2.3-31.48c0.23-0.47,0.86-2.32,4.75,2.05
            c-1.65-12.52-7.61-20.58-7.52-20.67c2.78-2.85,0.15-2.57,0.98-5.11C1357.8,514.06,1344.57,519.47,1345.24,516.58z"
            data-ref="cabelo_x5F_02"
            :style="`fill: ${drawingColors.cabelo_x5F_02}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="boneco_x5F_dto">
        <g class="st2">
          <path class="st4" d="M1296.86,572.67c3.06,0,5.54,2.48,5.54,5.54c0,3.06-2.48,5.54-5.54,5.54c-3.06,0-5.54-2.48-5.54-5.54
            C1291.32,575.15,1293.8,572.67,1296.86,572.67"/>
        </g>
        <g class="st2">
          <path class="st4" d="M1325.7,572.67c3.06,0,5.54,2.48,5.54,5.54c0,3.06-2.48,5.54-5.54,5.54c-3.06,0-5.54-2.48-5.54-5.54
            C1320.16,575.15,1322.64,572.67,1325.7,572.67"/>
        </g>
        <g class="st2">
          <path class="st3" d="M1311.3,607.34c-5.62,0-11.09-2.64-14.52-7.26c-0.72-0.98-0.52-2.35,0.46-3.08c0.98-0.72,2.35-0.52,3.08,0.46
            c3.32,4.48,9.17,6.49,14.55,5.01c2.95-0.81,5.57-2.63,7.38-5.1c0.72-0.99,2.09-1.2,3.07-0.48c0.98,0.72,1.2,2.09,0.48,3.07
            c-2.39,3.28-5.85,5.67-9.76,6.75C1314.47,607.14,1312.88,607.34,1311.3,607.34z"/>
        </g>
        <g>
          <g>
            <path class="st14" d="M1319.99,664.87L1319.99,664.87c0,0.17-0.12,0.29-0.3,0.29c-0.16,0-0.3-0.12-0.3-0.29
              c0-0.15,0.14-0.29,0.3-0.29C1319.87,664.57,1319.99,664.71,1319.99,664.87 M1319.93,667.79L1319.93,667.79h-0.48v-2.42h0.48
              V667.79z M1321.41,665.33L1321.41,665.33c-0.24,0-0.47,0.09-0.65,0.25v-0.21h-0.4v2.42h0.48v-1.43c0-0.3,0.24-0.54,0.55-0.54
              c0.3,0,0.54,0.24,0.54,0.54v1.43h0.48v-1.43C1322.42,665.79,1321.97,665.33,1321.41,665.33z M1323.67,664.86L1323.67,664.86h0.39
              v-0.45h-0.39c-0.48,0-0.86,0.31-0.86,0.85v2.54h0.48v-1.99h0.77v-0.43h-0.77v-0.14C1323.29,665.03,1323.46,664.86,1323.67,664.86
              z M1325.68,665.37L1325.68,665.37h-0.33c-0.23,0-0.44,0.07-0.57,0.2v-0.2h-0.4v2.42h0.48v-1.45c0-0.3,0.24-0.5,0.54-0.5h0.27
              V665.37z M1328.25,665.37L1328.25,665.37h-0.42v0.3c-0.21-0.21-0.5-0.34-0.83-0.34c-0.69,0-1.25,0.56-1.25,1.24
              c0,0.69,0.56,1.25,1.25,1.25c0.32,0,0.62-0.14,0.83-0.34v0.3h0.41V665.37z M1327.01,667.34L1327.01,667.34
              c-0.42,0-0.76-0.34-0.76-0.76c0-0.42,0.34-0.76,0.76-0.76c0.43,0,0.76,0.34,0.76,0.76C1327.78,667,1327.44,667.34,1327.01,667.34
              z M1329.85,667.83L1329.85,667.83c0.29,0,0.56-0.11,0.76-0.29v1.22h0.48v-3.38h-0.41v0.3c-0.21-0.21-0.51-0.34-0.83-0.34
              c-0.69,0-1.25,0.56-1.25,1.25C1328.6,667.27,1329.16,667.83,1329.85,667.83z M1329.85,665.82L1329.85,665.82
              c0.41,0,0.76,0.34,0.76,0.75c0,0.42-0.35,0.76-0.76,0.76c-0.42,0-0.76-0.34-0.76-0.76
              C1329.09,666.16,1329.43,665.82,1329.85,665.82z M1332.56,667.83L1332.56,667.83c-0.57,0-1.03-0.46-1.03-1.02v-1.43h0.48v1.43
              c0,0.29,0.25,0.54,0.55,0.54c0.3,0,0.54-0.25,0.54-0.54v-1.43h0.48v1.43C1333.58,667.37,1333.12,667.83,1332.56,667.83z
              M1334.54,664.87L1334.54,664.87c0,0.17-0.12,0.29-0.3,0.29c-0.15,0-0.3-0.12-0.3-0.29c0-0.15,0.14-0.29,0.3-0.29
              C1334.42,664.57,1334.54,664.71,1334.54,664.87z M1334.48,667.79L1334.48,667.79h-0.48v-2.42h0.48V667.79z M1335.96,665.33
              L1335.96,665.33c-0.24,0-0.47,0.09-0.65,0.25v-0.21h-0.4v2.42h0.48v-1.43c0-0.3,0.24-0.54,0.55-0.54c0.3,0,0.54,0.24,0.54,0.54
              v1.43h0.48v-1.43C1336.97,665.79,1336.52,665.33,1335.96,665.33z M1338.6,667.3L1338.6,667.3h-0.41c-0.21,0-0.38-0.16-0.38-0.37
              v-1.13h0.79v-0.43h-0.79v-0.77h-0.48v2.32c0,0.54,0.34,0.87,0.85,0.87h0.42V667.3z M1341.3,665.37L1341.3,665.37h-0.42v0.3
              c-0.21-0.21-0.5-0.34-0.83-0.34c-0.69,0-1.25,0.56-1.25,1.24c0,0.69,0.56,1.25,1.25,1.25c0.32,0,0.62-0.14,0.83-0.34v0.3h0.41
              V665.37z M1340.06,667.34c-0.42,0-0.76-0.34-0.76-0.76c0-0.42,0.34-0.76,0.76-0.76c0.43,0,0.77,0.34,0.77,0.76
              C1340.83,667,1340.49,667.34,1340.06,667.34z"/>
          </g>
          <g>
            <path class="st15" d="M1326.19,652.04c0.73,0,1.33,0.59,1.33,1.33c0,0.73-0.6,1.33-1.33,1.33c-0.73,0-1.33-0.59-1.33-1.33
              C1324.86,652.64,1325.46,652.04,1326.19,652.04"/>
          </g>
          <g>
            <path class="st15" d="M1330.36,652.04c3.04,0,5.5,2.46,5.5,5.49c0,1.29-0.45,2.48-1.19,3.42h1.19v2.07h-5.45
              c-0.01,0-0.03,0-0.04,0c-3.04,0-5.5-2.46-5.5-5.49v0v-1.19h2.08v1.19v0c0,1.89,1.53,3.41,3.42,3.42h0c1.89,0,3.42-1.53,3.42-3.42
              c0-1.89-1.53-3.41-3.42-3.42h-1.19v-2.07H1330.36z"/>
          </g>
        </g>
      </g>
      <g id="carregador_x5F_base">
        <rect
          x="1064.98" y="623.11" class="st1" width="129.05" height="236.55"
          data-ref="carregador_x5F_base"
          :style="`fill: ${drawingColors.carregador_x5F_base}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carregador_x5F_monitor">
        <rect
          x="1082.27" y="645.97" class="st1" width="94.45" height="59.55"
          data-ref="carregador_x5F_monitor"
          :style="`fill: ${drawingColors.carregador_x5F_monitor}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="folha_x5F_carregador">
        <g>
          <path
            class="st1" d="M1098.83,811.78c13.32,17.54,36.77,24.44,63.09,21.32c10.1-24.52,9.72-48.96-3.61-66.5 c-13.3-17.54-36.77-24.44-63.07-21.32C1085.15,769.81,1085.56,794.29,1098.83,811.78z"
            data-ref="folha_x5F_carregador"
            :style="`fill: ${drawingColors.folha_x5F_carregador}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M1161.93,833.11c0.49-1.21,0.97-2.39,1.4-3.65c-3.81-6.36-8.43-13.21-13.73-20.2
            c-10.3-13.57-20.94-24.7-29.42-31.29c4.08,9.96,11.95,23.17,22.23,36.7c5.32,7.03,10.73,13.39,15.91,18.84
            C1159.52,833.33,1160.71,833.24,1161.93,833.11z"
            data-ref="folha_x5F_carregador"
            :style="`fill: ${drawingColors.folha_x5F_carregador}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="tonco_x5F_01">
        <path
          class="st1" d="M928.04,658.02l-0.86-7.77c-0.27-2.48-2.37-4.36-4.87-4.36H877.1c-2.25,0-4.13,1.71-4.35,3.95l-0.78,8.18 H928.04z"
          data-ref="tonco_x5F_01"
          :style="`fill: ${drawingColors.tonco_x5F_01}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="ombro">
        <path
          class="st1" d="M912.15,658.02c-3.53-3.98-8.67-6.5-14.41-6.5s-10.88,2.52-14.41,6.5H912.15z"
          data-ref="ombro"
          :style="`fill: ${drawingColors.ombro}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="rosto_x5F_01">
        <g>
          <path
            class="st1" d="M922.12,615.13c-0.5-0.67-0.36-1.62,0.31-2.11c0.67-0.5,1.62-0.36,2.11,0.31c2.56,3.45,6.98,5.04,11.14,4.11
            v-50.37H861.1v55.67c0,7.68,6.29,13.97,13.97,13.97h46.65c7.68,0,13.97-6.29,13.97-13.97v-2.22c-0.78,0.13-1.56,0.23-2.35,0.23
            C929,620.75,924.78,618.71,922.12,615.13z"
            data-ref="rosto_x5F_01"
            :style="`fill: ${drawingColors.rosto_x5F_01}!important;`"
            @click="changeColor($event)"
          />
          <rect
            x="879.85" y="636.7" class="st1" width="40.29" height="9.19"
            data-ref="rosto_x5F_01"
            :style="`fill: ${drawingColors.rosto_x5F_01}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="rosto">
        <g>
          <path
            class="st16" d="M922.91,589.69c2.06,0,3.73,1.95,3.73,4.35c0,2.4-1.67,4.35-3.73,4.35c-2.06,0-3.73-1.95-3.73-4.35 C919.18,591.64,920.85,589.69,922.91,589.69"
            data-ref="skin2"
            :style="`fill: ${drawingColors.skin2}!important;`"
            @click="changeColor($event)"
          />
        </g>
        <path
          class="st17" d="M924.55,613.33c-0.5-0.67-1.44-0.81-2.11-0.31c-0.67,0.5-0.81,1.44-0.31,2.11c2.66,3.58,6.88,5.61,11.22,5.61 c0.78,0,1.57-0.09,2.35-0.23v-3.08C931.53,618.37,927.11,616.78,924.55,613.33z"
          data-ref="skin2"
          :style="`fill: ${drawingColors.skin2}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="cabelo">
        <g>
          <path
            class="st18" d="M869.69,550.27c-4.09-17.71,45.4-17.81,48.03-21.15c1.94,5.59-10.51,4.59,8.75,4.59
            c6.22,0,5.23,1.7,9.82-1.57c0.47,2.43-0.75,5.14-2.93,5.78c-0.23,1.13,12.24-2.23,11.64-1.14c-0.47,0.86,2.25,9.3,5.76,6.8
            c3.93-2.8-0.83,19.07-13.34,25.62c-6.21,3.25-12.37,3.44-22.48,4.14c-8.58,0.59-7.1,3.08-6.97,16.34
            c0.04,4.09,2.17,21.51,0.22,26.35c-0.63,1.57-9.85,1.09-9.82-0.3c0.36-18.62-11.25-15.25-8.91-15.4
            c-3.51,0.22-6.65,1.55-5.81,17.38c0.06,1.15-1.72,7.52-5.2,6.01c-1.02-0.44-3.29-2.25-4.48-2.24c-2.24,0.01-1.38,2.32-3.54,2.24
            c-4.33-0.16-3.91-2.27-4.9-2.45c-2.42-0.46-10.61,5.14-11.24,3.31c-10-28.94-1.89-47.49-1.88-48.41
            c-0.18-0.37-0.68-1.82-3.73,1.61c1.29-9.83,3.36-19.51,15.44-18.97c3.12,0.14-5.04-0.32-5.69-2.32
            C863.71,548.64,870.22,552.54,869.69,550.27z"
            data-ref="cabelo"
            :style="`fill: ${drawingColors.cabelo}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="carro_x5F_02">
        <path
          class="st1" d="M871,506.26H763.2c-88.96,0-160.96,72.04-160.96,161.01v3.2h429.73v-3.2
          C1031.97,578.3,959.92,506.26,871,506.26z M793.66,657.73H632.03v-3.2c0-71.69,58.38-129.93,130.02-129.93h31.62V657.73z
          M999.81,657.73H838.22V524.6h31.62c71.65,0,129.97,58.24,129.97,129.93V657.73z"
          data-ref="carro"
          :style="`fill: ${drawingColors.carro}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="carro_x5F_01">
        <path
          class="st1" d="M588.53,657.73h457.06c60.73,0,109.93,49.07,109.93,109.8v28.5h-91.05c-5.16-39.09-38.52-69.19-79.03-69.19
          c-40.39,0-73.69,30.09-78.99,69.19H701.93c-5.21-39.09-38.56-69.19-78.99-69.19c-40.47,0-73.83,30.09-79.03,69.19h-65.23v-28.5
          C478.68,706.8,527.83,657.73,588.53,657.73z"
          data-ref="carro"
          :style="`fill: ${drawingColors.carro}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="logo">
        <g>
          <path class="st19" d="M753.59,756.11L753.59,756.11c0,1.03-0.71,1.74-1.77,1.74c-0.91,0-1.74-0.71-1.74-1.74
            c0-0.91,0.83-1.74,1.74-1.74C752.88,754.38,753.59,755.2,753.59,756.11 M753.25,773.32L753.25,773.32h-2.85v-14.24h2.85V773.32z
            M761.98,758.85L761.98,758.85c-1.43,0-2.8,0.54-3.82,1.45v-1.22h-2.37v14.24h2.85v-8.43c0-1.77,1.43-3.19,3.22-3.19
            c1.74,0,3.2,1.43,3.2,3.19v8.43h2.85v-8.43C767.92,761.56,765.29,758.85,761.98,758.85z M775.31,756.06L775.31,756.06h2.28v-2.68
            h-2.28c-2.82,0-5.08,1.85-5.08,4.98v14.96h2.83v-11.74h4.54v-2.51h-4.54v-0.8C773.05,757.05,774.05,756.06,775.31,756.06z
            M787.12,759.08L787.12,759.08h-1.94c-1.37,0-2.6,0.43-3.37,1.2v-1.2h-2.34v14.24h2.85v-8.57c0-1.8,1.4-2.93,3.2-2.93h1.6V759.08z
            M802.31,759.08L802.31,759.08h-2.45v1.79c-1.26-1.25-2.97-2.02-4.88-2.02c-4.08,0-7.36,3.3-7.36,7.32c0,4.07,3.28,7.38,7.36,7.38
            c1.91,0,3.65-0.8,4.91-2.02v1.79h2.43V759.08z M795.01,770.67L795.01,770.67c-2.48,0-4.48-1.99-4.48-4.5c0-2.45,2-4.47,4.48-4.47
            c2.51,0,4.51,2.02,4.51,4.47C799.52,768.68,797.52,770.67,795.01,770.67z M811.72,773.55L811.72,773.55
            c1.71,0,3.31-0.65,4.48-1.71v7.18h2.85v-19.94h-2.43v1.79c-1.26-1.23-3.03-2.02-4.91-2.02c-4.08,0-7.36,3.3-7.36,7.35
            C804.36,770.27,807.64,773.55,811.72,773.55z M811.75,761.75L811.75,761.75c2.42,0,4.48,1.99,4.48,4.44
            c0,2.48-2.06,4.47-4.48,4.47c-2.48,0-4.48-1.99-4.48-4.47C807.27,763.75,809.26,761.75,811.75,761.75z M827.68,773.55
            L827.68,773.55c-3.34,0-6.05-2.71-6.05-6.04v-8.43h2.82v8.43c0,1.74,1.45,3.19,3.22,3.19c1.77,0,3.2-1.45,3.2-3.19v-8.43h2.85
            v8.43C833.73,770.84,831.02,773.55,827.68,773.55z M839.35,756.11L839.35,756.11c0,1.03-0.71,1.74-1.77,1.74
            c-0.91,0-1.74-0.71-1.74-1.74c0-0.91,0.83-1.74,1.74-1.74C838.64,754.38,839.35,755.2,839.35,756.11z M839.01,773.32
            L839.01,773.32h-2.85v-14.24h2.85V773.32z M847.74,758.85L847.74,758.85c-1.43,0-2.8,0.54-3.82,1.45v-1.22h-2.37v14.24h2.85v-8.43
            c0-1.77,1.43-3.19,3.22-3.19c1.74,0,3.2,1.43,3.2,3.19v8.43h2.85v-8.43C853.68,761.56,851.05,758.85,847.74,758.85z
            M863.32,770.44L863.32,770.44h-2.4c-1.26,0-2.23-0.97-2.23-2.19v-6.67h4.68v-2.51h-4.68v-4.53h-2.85v13.67
            c0,3.16,2.02,5.1,5.02,5.1h2.45V770.44z M879.22,759.08L879.22,759.08h-2.45v1.79c-1.26-1.25-2.97-2.02-4.88-2.02
            c-4.08,0-7.36,3.3-7.36,7.32c0,4.07,3.28,7.38,7.36,7.38c1.91,0,3.65-0.8,4.91-2.02v1.79h2.43V759.08z M871.91,770.67
            c-2.48,0-4.48-1.99-4.48-4.5c0-2.45,2-4.47,4.48-4.47c2.51,0,4.51,2.02,4.51,4.47C876.42,768.68,874.43,770.67,871.91,770.67z"/>
          <path class="st16" d="M790.18,680.51c4.33,0,7.84,3.51,7.84,7.83c0,4.32-3.51,7.83-7.84,7.83c-4.33,0-7.84-3.51-7.84-7.83
            C782.34,684.01,785.85,680.51,790.18,680.51"/>
          <path class="st16" d="M814.76,680.51c17.9,0,32.41,14.49,32.41,32.36c0,7.61-2.63,14.61-7.04,20.13h7.02v12.22h-32.15
            c-0.08,0-0.16,0-0.24,0c-17.9,0-32.41-14.49-32.41-32.36v-0.01v-7h12.25v7v0.01c0,11.11,9.02,20.13,20.16,20.13h0.02
            c11.13-0.01,20.16-9.02,20.16-20.13c0-11.12-9.02-20.13-20.16-20.13h-7.02v-12.23H814.76z"/>
        </g>
      </g>
      <g id="ficha_x5F_01">
        <g>
          <path
            class="st1" d="M501.92,691.47h-33.09c-31.97,0-57.95-26-57.95-57.95v-100c0-31.95,25.98-57.93,57.95-57.93h88.73v-7.97 h-88.73c-36.37,0-65.92,29.57-65.92,65.9v100c0,36.35,29.55,65.92,65.92,65.92h28.32C498.67,696.72,500.26,694.07,501.92,691.47z"
            data-ref="ficha_x5F_01"
            :style="`fill: ${drawingColors.ficha_x5F_01}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M626.33,443.41H611c-15.48,0-28,12.54-28,28.02c0,15.46,12.52,28,28,28h15.33V443.41z"
            data-ref="ficha_x5F_01"
            :style="`fill: ${drawingColors.ficha_x5F_01}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M557.56,477.7h26.16c-0.47-2.04-0.72-4.13-0.72-6.28c0-2.16,0.25-4.26,0.72-6.28h-26.16V477.7z"
            data-ref="ficha_x5F_01"
            :style="`fill: ${drawingColors.ficha_x5F_01}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M631.96,508.84c-3.14,0-5.63-2.52-5.63-5.65v-63.56c0-3.12,2.49-5.65,5.63-5.65l0,0 c3.14,0,5.66,2.52,5.66,5.65v63.56C637.62,506.32,635.1,508.84,631.96,508.84L631.96,508.84z"
            data-ref="ficha_x5F_01"
            :style="`fill: ${drawingColors.ficha_x5F_01}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="painel_x5F_02">
        <g>
          <path
            class="st1" d="M554.88,363.45v-33.44h-9.2v34.8C548.74,364.35,551.81,363.9,554.88,363.45z"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st18" d="M611.84,330.01c-41.03,0-82.07,0-123.12,0c9.21-16.1,18.44-32.19,27.66-48.3c22.59,0,45.19,0,67.79,0 C593.39,297.82,602.62,313.91,611.84,330.01z"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
          <rect
            x="488.72" y="330.01" class="st1" width="123.12" height="7.77"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="grelha_x5F_02">
        <g>
          <path
            class="st1" d="M519.36,284.4c-1.51,3.04-3.05,6.08-4.56,9.14c6.96,0,13.94,0,20.9,0c0.62-3.06,1.25-6.1,1.88-9.14 C531.5,284.4,525.43,284.4,519.36,284.4z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M513.71,295.68c-1.52,3.06-3.05,6.09-4.57,9.14c8.08,0,16.16,0,24.23,0c0.63-3.05,1.25-6.08,1.89-9.14 C528.08,295.68,520.89,295.68,513.71,295.68z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M508.07,306.97c-1.52,3.05-3.05,6.09-4.57,9.13c9.19,0,18.38,0,27.56,0c0.62-3.04,1.25-6.08,1.87-9.13 C524.65,306.97,516.36,306.97,508.07,306.97z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M502.42,318.26c-1.52,3.04-3.05,6.09-4.56,9.14c10.29,0,20.58,0,30.88,0c0.62-3.05,1.24-6.1,1.88-9.14 C521.23,318.26,511.83,318.26,502.42,318.26z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M541.19,284.4c-0.45,3.04-0.89,6.08-1.35,9.14c6.98,0,13.94,0,20.91,0c-0.45-3.06-0.9-6.1-1.35-9.14 C553.34,284.4,547.26,284.4,541.19,284.4z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M539.53,295.68c-0.46,3.06-0.89,6.09-1.35,9.14c8.09,0,16.16,0,24.24,0c-0.45-3.05-0.9-6.08-1.35-9.14 C553.9,295.68,546.71,295.68,539.53,295.68z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M537.87,306.97c-0.44,3.05-0.9,6.09-1.34,9.13c9.18,0,18.37,0,27.56,0c-0.45-3.04-0.9-6.08-1.35-9.13 C554.45,306.97,546.17,306.97,537.87,306.97z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M536.22,318.26c-0.46,3.04-0.9,6.09-1.35,9.14c10.3,0,20.58,0,30.88,0c-0.45-3.05-0.9-6.1-1.34-9.14 C555,318.26,545.61,318.26,536.22,318.26z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M563.02,284.4c0.63,3.04,1.27,6.08,1.89,9.14c6.97,0,13.93,0,20.9,0c-1.53-3.06-3.05-6.1-4.56-9.14 C575.16,284.4,569.09,284.4,563.02,284.4z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M565.35,295.68c0.63,3.06,1.25,6.09,1.88,9.14c8.08,0,16.15,0,24.22,0c-1.52-3.05-3.04-6.08-4.56-9.14 C579.71,295.68,572.53,295.68,565.35,295.68z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M567.68,306.97c0.63,3.05,1.26,6.09,1.88,9.13c9.19,0,18.38,0,27.56,0c-1.52-3.04-3.06-6.08-4.58-9.13 C584.25,306.97,575.96,306.97,567.68,306.97z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M570,318.26c0.63,3.04,1.26,6.09,1.88,9.14c10.3,0,20.59,0,30.89,0c-1.53-3.05-3.06-6.1-4.57-9.14 C588.79,318.26,579.4,318.26,570,318.26z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="painel">
        <g>
          <path
            class="st1" d="M474.23,393.33v-37.86h-10.42v39.41C467.27,394.34,470.75,393.84,474.23,393.33z"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st18" d="M538.72,355.47c-46.46,0-92.93,0-139.4,0c10.43-18.23,20.88-36.45,31.32-54.68c25.58,0,51.17,0,76.75,0 C517.84,319.02,528.29,337.24,538.72,355.47z"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
          <rect
            x="399.32" y="355.47" class="st1" width="139.4" height="8.8"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
        </g>
        <g>
          <path
            class="st1" d="M642.83,391.57v-37.86h-10.42v39.41C635.88,392.59,639.35,392.08,642.83,391.57z"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st18" d="M707.32,353.71c-46.46,0-92.93,0-139.4,0c10.43-18.23,20.88-36.45,31.32-54.68c25.58,0,51.17,0,76.75,0 C686.44,317.26,696.89,335.48,707.32,353.71z"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
          <rect
            x="567.93" y="353.71" class="st1" width="139.4" height="8.8"
            data-ref="painel"
            :style="`fill: ${drawingColors.painel}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="grelha">
        <g>
          <path
            class="st1" d="M434.01,303.83c-1.71,3.44-3.45,6.88-5.16,10.35c7.89,0,15.78,0,23.66,0c0.71-3.46,1.41-6.91,2.13-10.35 C447.76,303.83,440.89,303.83,434.01,303.83z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M427.62,316.59c-1.72,3.46-3.45,6.9-5.17,10.35c9.15,0,18.29,0,27.43,0c0.72-3.45,1.42-6.89,2.14-10.35 C443.88,316.59,435.75,316.59,427.62,316.59z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M421.23,329.38c-1.72,3.45-3.45,6.9-5.17,10.33c10.41,0,20.81,0,31.2,0c0.71-3.44,1.42-6.89,2.12-10.33 C440,329.38,430.62,329.38,421.23,329.38z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M414.84,342.16c-1.72,3.44-3.45,6.9-5.17,10.35c11.65,0,23.31,0,34.97,0c0.7-3.45,1.41-6.91,2.13-10.35 C436.13,342.16,425.48,342.16,414.84,342.16z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M458.73,303.83c-0.51,3.44-1.01,6.88-1.53,10.35c7.9,0,15.78,0,23.68,0c-0.51-3.46-1.02-6.91-1.53-10.35 C472.49,303.83,465.61,303.83,458.73,303.83z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M456.85,316.59c-0.52,3.46-1.01,6.9-1.53,10.35c9.16,0,18.29,0,27.44,0c-0.51-3.45-1.01-6.89-1.53-10.35 C473.12,316.59,464.98,316.59,456.85,316.59z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M454.97,329.38c-0.49,3.45-1.01,6.9-1.52,10.33c10.4,0,20.8,0,31.2,0c-0.51-3.44-1.01-6.89-1.53-10.33 C473.75,329.38,464.37,329.38,454.97,329.38z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M453.1,342.16c-0.52,3.44-1.02,6.9-1.53,10.35c11.66,0,23.31,0,34.96,0c-0.51-3.45-1.01-6.91-1.52-10.35 C474.37,342.16,463.74,342.16,453.1,342.16z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M483.44,303.83c0.72,3.44,1.43,6.88,2.14,10.35c7.89,0,15.77,0,23.67,0c-1.73-3.46-3.46-6.91-5.17-10.35 C497.19,303.83,490.32,303.83,483.44,303.83z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M486.09,316.59c0.71,3.46,1.42,6.9,2.13,10.35c9.15,0,18.28,0,27.42,0c-1.72-3.45-3.44-6.89-5.16-10.35 C502.34,316.59,494.21,316.59,486.09,316.59z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M488.72,329.38c0.71,3.45,1.42,6.9,2.13,10.33c10.4,0,20.81,0,31.21,0c-1.72-3.44-3.46-6.89-5.19-10.33 C507.48,329.38,498.1,329.38,488.72,329.38z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M491.35,342.16c0.71,3.44,1.42,6.9,2.13,10.35c11.66,0,23.31,0,34.97,0c-1.73-3.45-3.46-6.91-5.18-10.35 C512.63,342.16,501.99,342.16,491.35,342.16z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
        </g>
        <g>
          <path
            class="st1" d="M602.62,302.07c-1.71,3.44-3.45,6.88-5.16,10.35c7.89,0,15.78,0,23.66,0c0.71-3.46,1.41-6.91,2.13-10.35 C616.37,302.07,609.5,302.07,602.62,302.07z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M596.23,314.84c-1.72,3.46-3.45,6.9-5.17,10.35c9.15,0,18.29,0,27.43,0c0.72-3.45,1.42-6.89,2.14-10.35 C612.49,314.84,604.35,314.84,596.23,314.84z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M589.83,327.62c-1.72,3.45-3.45,6.9-5.17,10.33c10.41,0,20.81,0,31.2,0c0.71-3.44,1.42-6.89,2.12-10.33 C608.61,327.62,599.22,327.62,589.83,327.62z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M583.44,340.4c-1.72,3.44-3.45,6.9-5.17,10.35c11.65,0,23.31,0,34.97,0c0.7-3.45,1.41-6.91,2.13-10.35 C604.73,340.4,594.09,340.4,583.44,340.4z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M627.34,302.07c-0.51,3.44-1.01,6.88-1.53,10.35c7.9,0,15.78,0,23.68,0c-0.51-3.46-1.02-6.91-1.53-10.35 C641.1,302.07,634.21,302.07,627.34,302.07z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M625.46,314.84c-0.52,3.46-1.01,6.9-1.53,10.35c9.16,0,18.29,0,27.44,0c-0.51-3.45-1.01-6.89-1.53-10.35 C641.72,314.84,633.59,314.84,625.46,314.84z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M623.57,327.62c-0.49,3.45-1.01,6.9-1.52,10.33c10.4,0,20.8,0,31.2,0c-0.51-3.44-1.01-6.89-1.53-10.33 C642.35,327.62,632.97,327.62,623.57,327.62z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M621.71,340.4c-0.52,3.44-1.02,6.9-1.53,10.35c11.66,0,23.31,0,34.96,0c-0.51-3.45-1.01-6.91-1.52-10.35 C642.97,340.4,632.34,340.4,621.71,340.4z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M652.05,302.07c0.72,3.44,1.43,6.88,2.14,10.35c7.89,0,15.77,0,23.67,0c-1.73-3.46-3.46-6.91-5.17-10.35 C665.79,302.07,658.92,302.07,652.05,302.07z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M654.69,314.84c0.71,3.46,1.42,6.9,2.13,10.35c9.15,0,18.28,0,27.42,0c-1.72-3.45-3.44-6.89-5.16-10.35 C670.95,314.84,662.81,314.84,654.69,314.84z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M657.32,327.62c0.71,3.45,1.42,6.9,2.13,10.33c10.4,0,20.81,0,31.21,0c-1.72-3.44-3.46-6.89-5.19-10.33 C676.09,327.62,666.71,327.62,657.32,327.62z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
          <path
            class="st1" d="M659.96,340.4c0.71,3.44,1.42,6.9,2.13,10.35c11.66,0,23.31,0,34.97,0c-1.73-3.45-3.46-6.91-5.18-10.35 C681.23,340.4,670.59,340.4,659.96,340.4z"
            data-ref="grelha"
            :style="`fill: ${drawingColors.grelha}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="vento">
        <g>
          <path class="st1" d="M853.54,195.43v6.13c2.91,0,5.27,2.36,5.27,5.27c0,4.06-3.3,7.35-7.35,7.35c-5.49,0-9.96-4.47-9.96-9.96
            c0-6.84,6.68-12.83,14.38-13c13.27,0.19,30.38,0.28,47.54,0.28c17.65,0,35.31-0.1,48.77-0.29l-0.09-6.13
            c-26.42,0.39-69.02,0.39-95.91,0.02v-0.02c-12.41-0.33-21.31,8.95-20.83,19.14c0.41,8.86,7.22,16.09,16.09,16.09
            c7.43,0,13.48-6.05,13.48-13.49C864.94,200.54,859.83,195.43,853.54,195.43z"
            data-ref="vento"
            :style="`fill: ${drawingColors.vento}!important;`"
            @click="changeColor($event)"
          />
          <path class="st1" d="M890.72,193.79L890.72,193.79c-0.04-0.01-0.07,0-0.1,0c-0.06,0-0.12,0-0.18,0l0,0.01
            c-10.76,0.15-19.47,8.67-19.47,19.13c0,8.87,7.22,16.09,16.09,16.09c7.44,0,13.49-6.05,13.49-13.48c0-6.29-5.11-11.4-11.4-11.4
            v6.13c2.91,0,5.27,2.36,5.27,5.27c0,4.06-3.3,7.35-7.36,7.35c-5.49,0-9.96-4.47-9.96-9.96c0-7.14,6.04-12.94,13.49-13.01
            c7.54,0.19,19.07,0.29,30.61,0.29c11.69,0,23.38-0.1,30.86-0.3l-0.16-6.13C937.1,194.18,905.8,194.18,890.72,193.79z"
            data-ref="vento"
            :style="`fill: ${drawingColors.vento}!important;`"
            @click="changeColor($event)"
          />
          <path class="st1" d="M892.65,182.49l0,0.02c0.22,0.01,0.49,0.01,0.72,0.02c0.02,0,0.03,0,0.05,0v0
            c7.12,0.18,18.04,0.28,28.98,0.28c11.33,0,22.65-0.1,29.74-0.29l-0.17-6.13c-13.93,0.39-44.36,0.39-58.67,0.01
            c-11.87-0.07-22.26-10.35-22.26-22.06c0-9.2,7.48-16.68,16.68-16.68c7.02,0,12.73,5.71,12.73,12.73c0,5.28-4.29,9.57-9.57,9.57
            v6.13c8.66,0,15.7-7.04,15.7-15.7c0-10.4-8.46-18.86-18.86-18.86c-12.58,0-22.81,10.23-22.81,22.81
            C864.91,169.36,877.54,182.06,892.65,182.49z"
            data-ref="vento"
            :style="`fill: ${drawingColors.vento}!important;`"
            @click="changeColor($event)"
          />
          <path class="st1" d="M851.99,182h1.9h16.08v-6.13h-15.22l-1.82,0.01c-4.46,0-8.61-4.13-8.13-10.57c0.24-3.26,2.66-5.94,5.94-5.94
            c2.28,0,4.14,1.86,4.14,4.14c0,1.49-1.21,2.7-2.7,2.7v6.13c4.87,0,8.83-3.96,8.83-8.83c0-5.66-4.61-10.27-10.27-10.27
            c-6.65,0-11.72,5.42-12.07,12.07C838.2,174.46,844.45,181.42,851.99,182z"
            data-ref="vento"
            :style="`fill: ${drawingColors.vento}!important;`"
            @click="changeColor($event)"
          />
        </g>
      </g>
      <g id="nuvem_x5F_02_00000020394214680656811850000010059461504700854196_">
        <path class="st1" d="M1145.55,133.9h-0.63c0.55-1.61,0.84-8.45,0.84-10.68c0-13.8-11.19-27.11-24.99-27.11
          c-2.61,0-5.14-0.65-7.51,0.09c-5.12-20.15-23.38-35.58-45.12-35.58c-21.81,0-40.11,14.74-45.16,34.98
          c-2.54-0.87-5.25-1.48-8.09-1.48c-8.45,0-15.92,4.14-20.44,10.56c-5.15-7.78-13.98-12.95-24.01-12.95c-15.89,0-28.78,17.1-28.78,33
          c0,1.69,0.15,7.56,0.43,9.17h-7.85c-8.47,0-15.35,7.14-15.35,15.28c0,8.14,6.87,15.28,15.35,15.28h211.3
          c8.48,0,15.35-7.14,15.35-15.28C1160.9,141.04,1154.03,133.9,1145.55,133.9z"
          data-ref="nuvem"
          :style="`fill: ${drawingColors.nuvem}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="nuvem_x5F_01">
        <path class="st1" d="M1204.99,177.32h-0.63c0.55-1.61,0.84-9.15,0.84-11.38c0-13.8-11.19-27.46-24.99-27.46
          c-2.61,0-5.13-0.83-7.51-0.09c-5.12-20.15-23.38-35.67-45.12-35.67c-21.81,0-40.11,14.69-45.17,34.94c-2.53-0.87-5.25-1.5-8.08-1.5
          c-8.45,0-15.92,4.13-20.45,10.56c-5.15-7.78-13.98-12.96-24.01-12.96c-15.9,0-28.78,17.8-28.78,33.69c0,1.69,0.15,8.26,0.43,9.87
          h-7.85c-8.48,0-15.35,6.34-15.35,14.47c0,8.14,6.87,14.47,15.35,14.47h211.3c8.48,0,15.35-6.34,15.35-14.47
          C1220.34,183.66,1213.47,177.32,1204.99,177.32z"
          data-ref="nuvem"
          :style="`fill: ${drawingColors.nuvem}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="eolico">
        <path class="st1" d="M1047.32,314.85c-5.52-6.11-11.24-10.9-15.32-13.99c0.73-1.08,1.15-2.38,1.15-3.77c0-2.22-1.07-4.18-2.72-5.4
          c3.07-4.1,7.33-10.35,11-17.87c6.67-13.6,8.92-26.17,8.92-26.17s-8.56,9.48-15.22,23.08c-3.71,7.56-6.04,14.8-7.39,19.74
          c-0.44-0.09-0.88-0.14-1.33-0.14c-3.03,0-5.58,1.99-6.44,4.72c-4.97-1.22-12.44-2.7-20.85-3.13c-15.13-0.78-27.58,2.1-27.58,2.1
          s12.09,4.14,27.21,4.92c8.36,0.43,15.9-0.26,20.98-0.96c0.35,2.67,2.27,4.84,4.81,5.57l-1.37,190.27h5.5l-0.4-190.27
          c0.53-0.15,1.03-0.36,1.47-0.62c2.67,4.36,6.84,10.52,12.34,16.62c10.15,11.24,20.98,18,20.98,18S1057.46,326.09,1047.32,314.85z"
          data-ref="eolico"
          :style="`fill: ${drawingColors.eolico}!important;`"
          @click="changeColor($event)"
        />
        <path class="st1" d="M1142.72,315.38c-5.52-6.11-11.24-10.9-15.32-13.99c0.73-1.08,1.15-2.38,1.15-3.77c0-2.22-1.07-4.18-2.72-5.4
          c3.07-4.1,7.33-10.35,11-17.87c6.67-13.6,8.92-26.17,8.92-26.17s-8.56,9.48-15.22,23.08c-3.71,7.56-6.04,14.8-7.39,19.74
          c-0.44-0.09-0.88-0.14-1.33-0.14c-3.03,0-5.58,1.99-6.44,4.72c-4.97-1.22-12.44-2.7-20.85-3.13c-15.13-0.78-27.58,2.1-27.58,2.1
          s12.09,4.14,27.21,4.92c8.36,0.43,15.9-0.26,20.98-0.96c0.35,2.67,2.27,4.84,4.81,5.57l-1.37,190.27h5.5l-0.4-190.27
          c0.53-0.15,1.03-0.36,1.47-0.62c2.67,4.36,6.84,10.52,12.34,16.62c10.15,11.24,20.98,18,20.98,18S1152.86,326.62,1142.72,315.38z"
          data-ref="eolico"
          :style="`fill: ${drawingColors.eolico}!important;`"
          @click="changeColor($event)"
        />
        <path class="st1" d="M1232.32,314.32c-5.52-6.11-11.24-10.9-15.32-13.99c0.73-1.08,1.15-2.38,1.15-3.77c0-2.22-1.07-4.18-2.72-5.4
          c3.07-4.1,7.33-10.35,11-17.87c6.67-13.6,8.92-26.17,8.92-26.17s-8.56,9.48-15.22,23.08c-3.71,7.56-6.04,14.8-7.39,19.74
          c-0.44-0.09-0.88-0.14-1.33-0.14c-3.03,0-5.58,1.99-6.44,4.72c-4.97-1.22-12.44-2.7-20.85-3.13c-15.13-0.78-27.58,2.1-27.58,2.1
          s12.09,4.14,27.21,4.92c8.36,0.43,15.9-0.26,20.98-0.96c0.35,2.67,2.27,4.84,4.81,5.57l-1.37,190.27h5.5l-0.4-190.27
          c0.53-0.15,1.03-0.36,1.47-0.62c2.67,4.36,6.84,10.52,12.34,16.62c10.15,11.24,20.98,18,20.98,18S1242.47,325.57,1232.32,314.32z"
          data-ref="eolico"
          :style="`fill: ${drawingColors.eolico}!important;`"
          @click="changeColor($event)"
        />
      </g>
      <g id="arbusto">
        <path
          class="st1" d="M1007.76,495.11h65.14c0,0,5.25-17.11-10.98-19.17c0,0-6.04-0.08-10.73,3.66c0,0,0.87-11.74-7.48-13.44 c-9.39-1.91-16.39,3.26-15.27,17.42c0,0-4.23-2.63-11.23-0.72C1010.23,484.76,1007.76,495.11,1007.76,495.11z"
          data-ref="arbusto"
          :style="`fill: ${drawingColors.arbusto}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1090.62,495.11h65.14c0,0,5.24-17.1-10.98-19.17c0,0-6.04-0.08-10.73,3.66c0,0,0.87-11.74-7.48-13.44 c-9.39-1.91-16.39,3.26-15.27,17.42c0,0-4.23-2.62-11.23-0.71C1093.09,484.77,1090.62,495.11,1090.62,495.11z"
          data-ref="arbusto"
          :style="`fill: ${drawingColors.arbusto}!important;`"
          @click="changeColor($event)"
        />
        <path
          class="st1" d="M1181.29,494.14h65.14c0,0,5.24-17.1-10.98-19.17c0,0-6.04-0.08-10.73,3.66c0,0,0.87-11.74-7.48-13.44 c-9.39-1.91-16.39,3.26-15.27,17.42c0,0-4.23-2.62-11.23-0.71C1183.75,483.8,1181.29,494.14,1181.29,494.14z"
          data-ref="arbusto"
          :style="`fill: ${drawingColors.arbusto}!important;`"
          @click="changeColor($event)"
        />
      </g>
    </svg>
  </div>
  <div class="o-Drawing__colors">
    <div class="o-Colors">
      <a
        v-for="(color, index) in COLORS"
        :key="index"
        href="#"
        class="o-Color"
        :style="`background-color:${color};`"
        @click.prevent="selectColor(color)"
      />
    </div>
    <button
      class="o-Drawing__action"
      @click.prevent="saveDrawing"
    >
      Guardar
    </button>
    <button
      class="o-Drawing__action"
      @click.prevent="clearDrawing"
    >
      Limpar
    </button>
  </div>
</div>
</template>

<script>
import COLORS from '@/enums';

const { saveSvgAsPng } = require('save-svg-as-png');

export default {
  name: 'Energy',
  data: () => ({
    COLORS,
    selectedColor: COLORS.WHITE,
    drawingColors: {},
  }),
  mounted() {
    this.drawingColors = JSON.parse(this.$store.state.aquaDrawing);
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
    },
    changeColor(e) {
      this.$set(this.drawingColors, e.target.getAttribute('data-ref'), this.selectedColor);
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify(this.drawingColors),
      });
    },
    saveDrawing() {
      try {
        saveSvgAsPng(document.getElementById('drawing'), 'drawing.png');
      } catch (err) {
        console.log('Error trying to download image');
      }
    },
    clearDrawing() {
      Object.keys(this.drawingColors).forEach((key) => {
        this.$set(this.drawingColors, key, COLORS.WHITE);
      });
      this.$store.dispatch('saveDrawing', {
        mutation: 'saveAqua',
        data: JSON.stringify({}),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#FFFFFF;stroke:#221F1F;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st2{opacity:0.3;}
.st3{fill:#221F1F;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#221F1F;}
.st5{fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.5;stroke-miterlimit:10;}
.st6{fill:none;stroke:#1D1C1A;stroke-width:0.25;stroke-miterlimit:10;stroke-dasharray:1;}
.st7{fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.25;stroke-miterlimit:10;stroke-dasharray:1;}
.st8{fill:#FFFFFF;stroke:#2D2D2B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st9{fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st10{fill:none;stroke:#1D1C1A;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st11{fill:#FFFFFF;stroke:#1D1C1A;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st12{opacity:0.5;fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.25;stroke-miterlimit:10;stroke-dasharray:1;}
.st13{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#1D1C1A;stroke-width:0.5;stroke-miterlimit:10;}
.st14{fill:#C6C5C4;}
.st15{fill-rule:evenodd;clip-rule:evenodd;fill:#C6C5C4;}
.st16{fill-rule:evenodd;clip-rule:evenodd;fill:#BCBDBF;}
.st17{fill:#BCBDBF;stroke:#BCBDBF;stroke-width:0.5;stroke-miterlimit:10;}
.st18{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#221F1F;stroke-width:0.5;stroke-miterlimit:10;}
.st19{fill:#BCBDBF;}
</style>
